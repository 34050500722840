import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

function NotFound(): ReactElement {
  return (
    <div className="text-center">
      <br />
      <Typography variant="h1">NOT FOUND</Typography>
      <Typography variant="h2">404</Typography>
      <Typography variant="body1">The resource requested could not be found on this server!</Typography>
    </div>
  );
}

export default NotFound;
