import { Badge, createStyles, makeStyles, Popover, Theme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NavMenu from "components/NavMenu";
import SearchAppBar from "components/SearchAppBar";
import UserCard from "components/UserCard";
import React from "react";
import { NavLink } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { IsEngineer2User } from "system/Helper";
import { useQtyState } from "system/QuantityContext";
import { useUserState } from "system/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "10px",
      fontWeight: "bold",
      fontSize: "20px",
    },
    navProfile: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  })
);

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

function Header({ open, setOpen }: HeaderProps) {
  const classes = useStyles();
  const user = useUserState();
  const qty = useQtyState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const show = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          {/* 메뉴 아이콘 */}
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerOpen}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          {/* 로고 - 홈버튼 */}
          <NavLink to="/">
            <img src="/images/main_logo.png" width="100px" alt="title"></img>
          </NavLink>
          {/* 사이트 이름 */}
          <Typography variant="h6" className={classes.title}>
            Global Management
          </Typography>
          <div>
            <SearchAppBar></SearchAppBar>
          </div>
          {/* 사용자 프로필 */}
          <div>
            <IconButton aria-describedby={"userCard"} onClick={handleClick}>
              <AccountCircle color={"action"}></AccountCircle>
            </IconButton>
            <Popover
              id={"userCard"}
              open={show}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <UserCard onClose={handleClose} />
            </Popover>
          </div>
          {!IsEngineer2User(user.role) && (
            <div>
              <NavLink to={MyRoutes.cartMine}>
                <Badge badgeContent={qty.qty} color="error">
                  <ShoppingCartIcon color={"action"}></ShoppingCartIcon>
                </Badge>
              </NavLink>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {/* nav */}
      <div>
        <NavMenu open={open}></NavMenu>
      </div>
    </div>
  );
}

export default Header;
