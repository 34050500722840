import React, { ReactElement, ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, SelectProps, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 130,
    },
  })
);

export interface SimpleChangeEvent {
  name?: string | undefined;
  value: unknown;
}
interface Props extends SelectProps {
  empty?: boolean;
  size?: "medium" | "small";
  label?: string;
  children: ReactNode;
}

function SimpleSelect({ empty, size, label, fullWidth, variant, className, children, ...others }: Props): ReactElement {
  const classes = useStyles();
  const formControlClass = size === "small" ? undefined : classes.formControl;

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={className ? className : formControlClass}
      size={size}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...others} label={label}>
        {empty && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {children}
      </Select>
    </FormControl>
  );
}

export default SimpleSelect;
