import { createContext, Dispatch, useContext, useReducer } from "react";

type Severity = "success" | "info" | "warning" | "error";

interface ToastState {
  message: string;
  severity?: Severity;
  open: boolean;
}

type Action = { type: Severity; message: string } | { type: "close" };

type ToastDispatch = Dispatch<Action>;

const ToastStateContext = createContext<ToastState | undefined>(undefined);
const ToastDispatchContext = createContext<ToastDispatch | undefined>(undefined);

function Reducer(state: ToastState, action: Action): ToastState {
  switch (action.type) {
    case "close": {
      return {
        ...state,
        open: false,
      };
    }
    default: {
      return {
        open: true,
        message: action.message,
        severity: action.type,
      };
    }
  }
}

export function ToastContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(Reducer, { message: "", open: false });
  return (
    <ToastDispatchContext.Provider value={dispatch}>
      <ToastStateContext.Provider value={state}>{children}</ToastStateContext.Provider>
    </ToastDispatchContext.Provider>
  );
}

//custom hook
export function useToastState() {
  const state = useContext(ToastStateContext);
  if (!state) throw new Error("ToastStateProvider not found");
  return state;
}

export function useToastDispatch() {
  const dispatch = useContext(ToastDispatchContext);
  if (!dispatch) throw new Error("ToastDispatchProvider not found");
  return dispatch;
}
