import { Button, Divider, Typography } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { UserApi } from "system/ApiService";
import { IsEngineer2User, IsManagerUser } from "system/Helper";
import { useUserDispatch, useUserState } from "system/UserContext";

interface UserCardProps {
  onClose: () => void;
}

function UserCard({ onClose }: UserCardProps) {
  const history = useHistory();
  const user = useUserState();
  const userDispatch = useUserDispatch();

  // go to profile
  const goProfile = () => {
    onClose();
    history.push({
      pathname: MyRoutes.userProfile,
      state: {
        userId: user.id,
      },
    });
  };

  // go to order list
  const goOrderList = () => {
    if (IsManagerUser(user.role)) {
      onClose();
      history.push({
        pathname: `${MyRoutes.cartOrder}/received`,
      });
    } else {
      onClose();
      history.push({
        pathname: `${MyRoutes.cartOrder}/details`,
      });
    }
  };

  // go to account management
  const goAccount = () => {
    onClose();
    history.push(MyRoutes.account);
  };

  //user logout
  const Logout = () => {
    userDispatch({ type: "LOGOUT" });
    UserApi.Logout().then((x) => {
      onClose();
      history.replace({
        pathname: MyRoutes.login,
        state: undefined,
      });
    });
  };

  return (
    <div id="user-profile" style={{ textAlign: "center" }}>
      {IsManagerUser(user.role) ? (
        <Dropdown.Item disabled={true}>
          <Typography variant="h6" style={{ color: "black" }}>
            {user.id} {"(" + user.role + ")"}
          </Typography>
        </Dropdown.Item>
      ) : (
        <Dropdown.Item disabled={true}>
          <Typography variant="h6" style={{ color: "black" }}>
            {user.contactName}
          </Typography>
          <Typography variant="subtitle1" style={{ color: "#616161" }}>
            {user.contactEmail}
          </Typography>
        </Dropdown.Item>
      )}
      <Divider />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button name="btn-profile" onClick={goProfile}>
          My Profile
        </Button>
        {!IsEngineer2User(user.role) && (
          <Button name="btn-profile" onClick={goOrderList}>
            Previous Orders
          </Button>
        )}
        {IsManagerUser(user.role) ? (
          <Button name="btn-profile" onClick={goAccount}>
            Account Management
          </Button>
        ) : null}
        <Button name="btn-profile" onClick={Logout}>
          Logout
        </Button>
      </div>
    </div>
  );
}

export default UserCard;
