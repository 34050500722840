import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  StandardProps,
  FormControlLabelClassKey,
} from "@material-ui/core";
import React, { ReactElement } from "react";

interface SimpleRadioGroupProps extends RadioGroupProps {
  label?: string;
}

export function SimpleRadioGroup(props: SimpleRadioGroupProps): ReactElement {
  return (
    <FormControl component="fieldset">
      {props.label && <FormLabel component="legend">{props.label}</FormLabel>}

      <RadioGroup {...(props.label && { "aria-label": props.label })} {...props}>
        {props.children}
      </RadioGroup>
    </FormControl>
  );
}

interface SimpleRadioProps
  extends StandardProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    FormControlLabelClassKey,
    "children" | "onChange"
  > {
  checked?: boolean;
  disabled?: boolean;
  label: React.ReactNode;
  inputRef?: React.Ref<any>;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  name?: string;
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  value?: unknown;
}

export function SimpleRadio(props: SimpleRadioProps): ReactElement {
  return <FormControlLabel {...props} control={<Radio />}></FormControlLabel>;
}
