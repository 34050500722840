import React, { ReactElement } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IconButton } from "@material-ui/core";
interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ButtonExpandIcon = ({ open, setOpen }: Props): ReactElement => {
  const onClick = () => setOpen(!open);
  return (
    <IconButton aria-label={open ? "close" : "expand"} color="inherit" size="small" onClick={onClick}>
      {open ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}
    </IconButton>
  );
};

export default React.memo(ButtonExpandIcon);
