import ListLayer from "components/ListLayer";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { MyRoutes } from "routes/Routes";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { Category } from "system/types";
import CSVideoListPage from "./CSVideoListPage";
import CSVideoEditPage from "./CSVideoEditPage";
import CSVideoDetailPage from "./CSVideoDetailPage";

function CSVideoMainPage(): ReactElement {
  const [list, setList] = useState<Category[]>([]);

  useEffect(() => {
    CategoryApi.GetAllCategories("video")
      .then((x) => {
        setList(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("Error! Cannot get cs category." + msg);
      });
  }, []);

  const makePathName = useCallback((data: Category, depth?: number) => {
    return {
      pathname: `${MyRoutes.csVideo}/list/${data.id}`,
    };
  }, []);

  return (
    <ListLayer makePathName={makePathName} list={list} route={MyRoutes.csVideo} kind={"video"}>
      <Switch>
        <Route path={`${MyRoutes.csVideo}/list/:categoryId`} exact>
          <CSVideoListPage route={MyRoutes.csVideo}></CSVideoListPage>
        </Route>
        <Route path={`${MyRoutes.csVideo}/edit/:categoryId?/:id?`}>
          <CSVideoEditPage></CSVideoEditPage>
        </Route>
        <Route path={`${MyRoutes.csVideo}/:videoId`} exact>
          <CSVideoDetailPage></CSVideoDetailPage>
        </Route>
      </Switch>
    </ListLayer>
  );
}

export default CSVideoMainPage;
