import { Box } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Pagination } from "@material-ui/lab";
import { ActionButton } from "components/Button";
import AdminComponent from "components/Common/AdminComponent";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, NoticeApi } from "system/ApiService";
import { getDateUsingNumber } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { useToastDispatch } from "system/ToastContext";
import { GetInitSearchResult, NoticeItem, SearchResult } from "system/types";

interface LocationState {
  delete?: boolean;
}

interface NoticeListProps {
  announcement: boolean;
}

function NoticeList({ announcement }: NoticeListProps): ReactElement {
  const [notices, setNotices] = React.useState<SearchResult<NoticeItem>>(GetInitSearchResult<NoticeItem>([]));
  const { state } = useLocation<LocationState>();
  const ToastDispatch = useToastDispatch();
  const LoadingDispatch = useLoadingDispatch();

  if (state && state.delete) {
    ToastDispatch({ type: "success", message: "Success to delete" });
  }

  const tableHeader = (
    <tr>
      <th className="no">No</th>
      <th className="title">Title</th>
      <th className="writer">Writer</th>
      <th className="date">Date</th>
      <th className="views">Views</th>
    </tr>
  );

  const tableHeaderMobile = (
    <tr>
      <th className="no">No</th>
      <th className="title">Title</th>
    </tr>
  );

  const LoadData = React.useCallback(() => {
    LoadingDispatch({ type: "LOADING" });

    let pageSize = 10;
    if (announcement) pageSize = 5;

    NoticeApi.GetNoticeList(pageSize, notices.page)
      .then((res) => {
        setNotices(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get notice list." + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  }, [LoadingDispatch, announcement, notices.page]);

  React.useEffect(() => {
    LoadData();
  }, [LoadData]);

  const history = useHistory();

  const _ToEdit = () => {
    history.push({
      pathname: `${MyRoutes.notice}/edit`,
    });
  };

  const _ViewItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }
    let str = event.target.dataset["id"];
    if (str) {
      history.push({
        pathname: `${MyRoutes.notice}/view/${str}`,
      });
    }
  };

  const tableBody = (item: NoticeItem, i: number) => (
    <tr className={item.pinned ? "list-pinned" : undefined} key={`tr-${i}-${item.id}`}>
      {/* (row*page)+i+1 */}
      <td>{item.pinned ? <NotificationsIcon color="secondary" /> : item.id}</td>
      <td className="link" onClick={_ViewItem} data-id={item.id}>
        {item.title}
      </td>
      <td>{item.writer}</td>
      <td>{getDateUsingNumber(item.created)}</td>
      <td>{item.views}</td>
    </tr>
  );

  const tableBodyMobile = (item: NoticeItem, i: number) => (
    <tr className={item.pinned ? "list-pinned" : undefined} key={`tr-${i}-${item.id}`}>
      <td>{item.pinned ? <NotificationsIcon color="secondary" /> : item.id}</td>
      <td className="link" onClick={_ViewItem} data-id={item.id}>
        {item.title}
      </td>
    </tr>
  );

  const _onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setNotices({
      ...notices,
      page: value,
    });
  };

  return (
    <>
      {/* 검색라인 */}
      {!announcement && (
        <AdminComponent>
          <div className="text-right mb-1">
            <ActionButton onClick={_ToEdit}>Write</ActionButton>
          </div>
        </AdminComponent>
      )}

      <Box display={{ xs: "none", sm: "block" }}>
        <Table className="notice">
          <thead>{tableHeader}</thead>
          <tbody>{notices.list.map((x, i) => tableBody(x, i))}</tbody>
        </Table>
      </Box>

      <Box display={{ xs: "block", sm: "none" }}>
        <Table className="notice">
          <thead>{tableHeaderMobile}</thead>
          <tbody>{notices.list.map((x, i) => tableBodyMobile(x, i))}</tbody>
        </Table>
      </Box>

      {/* pagination */}
      <div className="d-flex justify-content-center">
        <Pagination
          count={notices.totalPage}
          page={notices.page}
          onChange={_onPageChange}
          color="primary"
          boundaryCount={5}
        />
      </div>
    </>
  );
}

export default NoticeList;
