import { AxiosError } from "axios";
import HttpClient from "system/http-client";
import {
  CSEducation,
  CSEducationItem,
  CSEtc,
  CSEtcItem,
  CSManual,
  CSManualItem,
  CSVideo,
  CSVideoItem,
  Cart,
  CartItem,
  Category,
  CheckUnitPrice,
  Comment,
  DCategory,
  Download,
  DownloadItem,
  Item,
  ItemDetail,
  ItemView,
  LetterNotice,
  LetterNoticeItem,
  Notice,
  NoticeItem,
  Order,
  OrderItem,
  OrderSign,
  OrderSuccess,
  OrderedList,
  SearchModel,
  SearchResult,
  User,
  UserAddress,
  UserDetail,
  Warranty,
} from "system/types";

class _ErpApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  CallCust() {
    return client.get(`erp/cust`);
  }
  CallOrder() {
    return client.get(`erp/order`);
  }
  CallItem() {
    return client.get(`erp/item`);
  }
  CallClaim() {
    return client.get(`erp/claim`);
  }
}

interface TempUser extends User {
  password?: string;
}
class _UserApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }
  SetToken(token: string) {
    this.token = token;
    client.setToken(token);
  }
  GetToken() {
    return this.token;
  }
  // 로그인
  Login(userId: string, userPassword: string) {
    return client.post<{
      user: User;
      userDetail: UserDetail;
      token: string;
    }>("user/login", { id: userId, password: userPassword });
  }
  // 토큰 새로고침
  Refresh() {
    return client.post<{
      user: User;
      token: string;
    }>("user/refresh");
  }
  // 로그아웃
  Logout() {
    return client.post("user/logout").then((x) => {
      this.token = "";
    });
  }
  // 유저 전체 리스트 출력
  GetUserList() {
    return this.client.get<User[]>(`user/list`);
  }
  // 유저 디테일 가져오기
  GetProfile(userId: string) {
    return this.client.get(`user/profile/${userId}`);
  }
  // 영업관리자 유저 정보 초기 업데이트 (role, password)
  UpdateUser(user: TempUser) {
    return this.client.put(`user/${user.contactPersonId}`, user);
  }
  // 관리자 추가
  InsertAdminUser(user: TempUser) {
    return this.client.post(`user/admin`, user);
  }
  // 관리자 삭제 및 계정 비활성화(ERP 연동 데이터는 남아있고, GM 내 User DB 로그인 정보만 삭제됨)
  DeleteUser(id: string) {
    return this.client.delete(`user/${id}`)
  }
  // 비밀번호 변경
  ChangePassword(userId: string, currentPassword: string, newPassword: string, confirmPassword: string) {
    return this.client.put(`user/change-password/${userId}`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    });
  }
  // 비밀번호 찾기
  ResetPassword(data: FormData) {
    return client.post(`user/reset-password`, data);
  }
}

class _WarrantyApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 유저에 해당하는 무상출고 리스트 불러오기
  GetWarrantyList() {
    return client.get<Warranty[]>(`warranty/list`)
  }
  // 카트에 담기 (카트에 담았는지 체크)
  AddWarrantyCart(items: Warranty[]) {
    return client.post(`warranty/addcart`, items)
  }
}

class _ItemApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 최근 업데이트 및 생성 된 아이템 목록(mainpage)
  GetRecentlyItems(kind: string) {
    return client.get<ItemDetail[]>(`items/recent/${kind}`);
  }

  // 사용자가 자주 주문하는 아이템 목록(shoppage)
  GetFrequentItems(userId: string, type: string) {
    return client.get(`items/frequent/${userId}/${type}`)
  }
  
  // 관리자 페이지에서 사용할 아이템 전체리스트
  GetItemList(categoryId: number, kind: string) {
    return client.get<Item[]>(`items/list/${categoryId}/${kind}`);
  }

  // quick 아이템 정보 불러오기
  GetQuickItemView(searchData: { erpcode: string; name: string}, role: string ) {
    return client.post<ItemView[]>(`items/quick/search/${role}`, {
      ProductNumber: searchData.erpcode,
      ProductName: searchData.name
    });
  }
  
  // erpcode로 제품 및 자재 detail 조회
  GetItemByErpcode(erpcode: string, kind: string) {
    return client.get<ItemDetail>(`items/${erpcode}/${kind}`);
  }
  
  // 아이템 정보 업데이트
  Upsert(erpcode: string, formData: FormData) {
    return client.post<ItemDetail>(`items/${erpcode}`, formData);
  }
  
  // spare
  // 해당 카테고리에 해당하는 상품 리스트 출력(role에 따라 isshow 여부 다름)
  GetByCategoryId(categoryId: number, keyword: string, row: number, page: number, role: boolean) {
    if (isNaN(categoryId)) {
      throw new Error("categoryId can not be NaN");
    } else {
      return client.post<SearchResult<Item>>(`items/parts/${role}/${categoryId}`, {
        keyword: keyword,
        row: row,
        page: page,
      });
    }
  }
  // 
  GetSearchSpareParts(categoryId: number, keyword: string, row: number, page: number) {
    return client.get<SearchResult<Item>>(`items/parts/${categoryId}/?keyword=${keyword}&row=${row}&page=${page}`);

  }

  // product
  // role에 따라 전체 제품 리스트 or 판매중인 제품 리스트
  GetProductList() {
    return client.get<Item[]>(`items/product`)
  }
  // 이전 제품 주문 내역 불러오기
  GetPreviousOrder(userId: string) {
    return client.get<OrderItem[]>(`items/previous/${userId}/order`);
  }
}

class _CategoryApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 제품, 자재
  GetAllCategoriesERP(kind: string) {
    return client.get<Category[]>(`categories/erp/${kind}`);
  }
  // cseducation, download
  GetAllCategories(kind: string) {
    return client.get<Category[]>(`categories/${kind}`);
  }
  // 제품 해당하는 카테고리 목록(select2에 사용)
  GetCategoriesList(kind: string) {
    return client.get<DCategory[]>(`categories/list/${kind}`);
  }
  // cseducation, download crud
  UpdateCategory(category: DCategory) {
    return client.post<Category>(`categories`, category);
  }
  InsertCategory(category: DCategory, kind: string) {
    return client.post<Category>(`categories/${kind}`, category);
  }
  DeleteCategory(id: number | string) {
    return client.post(`categories/delete`, { id: id });
  }
  GetCategorory(kind: string, id: number) {
    return client.get<DCategory>(`categories/${kind}/${id}`);
  }
}

class _ShopApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 장바구니 조회
  GetMyCart() {
    return client.get<Cart>(`shoppingcart/my`);
  }
  // 장바구니 아이템 삭제
  DeleteMyCart(delItems: number[]) {
    return client.post<Cart>(`shoppingcart/my/delete`, {
      lists: delItems,
    });
  }
  // 장바구니 추가
  AddCart(ItemNumber: string, kind: string, qty: number, price: number, remark: string) {
    return client.post(`shoppingcart/item`, { productNumber: ItemNumber, kind: kind, qty: qty, unitPrice: price, remark: remark });
  }
  // 장바구니 아이템 상태 업데이트
  UpdateCartItem(x: CartItem) {
    return client.put(`shoppingcart/${x.id}`, x);
  }
  // ERP 연동 실시간 가격 정보 보기
  CheckUnitPrice(prices: CheckUnitPrice) {
    return client.post(`shoppingcart/unitprice`, prices)
  }
  // Quick 장바구니 담기
  UpdateCart(items: ItemView[]) {
    return client.post(`shoppingcart/cart`, items);
  }  
}

class _OrderApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  
  // po 유저 init 조회
  GetOrderInit(userId: string) {
    return client.get<Order>(`order/init/${userId}`);
  }
  // 유저 주소 조회
  GetAddress(custAccount: string) {
    return client.get<UserAddress[]>(`order/init/address/${custAccount}`)
  }
  // shipping 정보 조회
  GetShipping() {
    return client.get(`order/init`)
  }
  // order
  MakeOrder(userId: string, order: Order, cart: Cart) {
    return client.post(`order/order/${userId}`, {
      order: {
        ...order,
        ordered: new Date(order.ordered).toISOString(),
      },
      cart: cart,
    });
  }
  // 주문 list
  GetOrderedList(companyName: string, orderer: string, row: number, page: number, status: boolean) {
    return client.post<SearchResult<OrderedList>>(`order/list?row=${row}&page=${page}`, {
      companyName: companyName,
      userId: orderer,
      status: status,
    });
  }
  // 주문 detail
  GetOrderDetail(orderId: number) {
    return client.get<{
      order: Order;
      orderItem: OrderItem[];
    }>(`order/${orderId}`);
  }
  
  // 재주문
  Reorder(orderId: number) {
    return client.post(`order/reorder/${orderId}`)
  }

  // 주문 취소
  Cancel(id: number) {
    return client.put<Order>(`order/cancel/${id}`);
  }

  // 관리자 주문 sign 조회
  GetOrderSign(orderId: number) {
    return client.get<OrderSign>(`order/sign/${orderId}`)
  }

  // 주문 승인
  Approve(check: boolean, id: number, bs: boolean, bn: string, ps: boolean|null, pn: string) {
    return client.post<OrderSuccess>(`order/approve/${check}`, {orderId: id, bmStatus: bs, bmName: bn, pmStatus: ps, pmName: pn})
  }

  // 주문 반려
  Reject(id: number, bs: boolean, bn: string, ps: boolean|null, pn: string) {
    return client.post(`order/reject`, {orderId: id, bmStatus: bs, bmName: bn, pmStatus: ps, pmName: pn})
  }
  
  // shipping date 변경
  ChangeDataByAdmin(id: number, data: Order) {
    return client.put(`order/shippingdate/${id}`, data);
  }

  // comment list 조회
  GetCommentList(orderId: number) {
    return client.get(`order/comment/list/${orderId}`)
  }

  // comment 생성
  InsertComment(orderId: number, data: Comment) {
    return client.post(`order/comment/${orderId}`, data)
  }

  // comment 삭제
  DeleteComment(orderId: number, id: number) {
    return client.post(`order/comment/delete/${orderId}/${id}`)
  }

  // 주문상태(from ERP)
  OrderStatus(orderId: number) {
    return client.post(`order/status/${orderId}`)
  }

  // po 엑셀로 다운로드
  DownloadExcel(id: number) {
    return client.get(`order/downloadPO/${id}`, {
      responseType: "blob",
    })
  }
}

class _NoticeApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetNotice(id: number | string) {
    return client.get<Notice>(`notice/${id}`);
  }

  DeleteNotice(id: number | string) {
    return client.post(`notice/delete`, { id: id });
  }

  UpsertNotice(notice: FormData) {
    return client.post<Notice>(`notice`, notice);
  }

  GetNoticeList(row: number = 10, page: number = 1) {
    return client.get<SearchResult<NoticeItem>>(`notice?row=${row}&page=${page}`);
  }
}

class _CSEducationApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetCSEducation(id: number | string) {
    return client.get<CSEducation>(`cseducation/view/${id}`);
  }

  GetCSEducationListByCategoryId(id: number | string, row: number = 10, page: number = 1, lang: string) {
    return client.get<SearchResult<CSEducationItem>>(`cseducation/${id}?row=${row}&page=${page}&lang=${lang}`);
  }

  UpsertCSEducation(cseducation: FormData) {
    return client.post<CSEducation>(`cseducation`, cseducation);
  }

  DeleteCSEducation(id: number | string) {
    return client.post(`cseducation/delete`, { id: id });
  }

  GetRecentlyCSEducation() {
    return client.get<CSEducation[]>(`cseducation/recent`);
  }
}
class _CSManualApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetCSManual(id: number | string) {
    return client.get<CSManual>(`csmanual/view/${id}`);
  }

  GetCSManualListByCategoryId(id: number, row: number, page: number, lang: string, keyword: string) {
    return client.post<SearchResult<CSManualItem>>(`csmanual/${id}/${lang}`, {
      keyword: keyword, 
      row: row,
      page: page,
    });
  }

  UpsertCSManual(csManual: FormData) {
    return client.post<CSManual>(`csmanual`, csManual);
  }

  DeleteCSManual(id: number | string) {
    return client.post(`csmanual/delete`, { id: id });
  }
  
  GetRecentlyCSManual() {
    return client.get<CSManual[]>(`csmanual/recent`);
  }
}
class _CSVideoApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetCSVideo(id: number | string) {
    return client.get<CSVideo>(`csvideo/view/${id}`);
  }

  GetCSVideoListByCategoryId(id: number, row: number, page: number, keyword: string) {
    return client.post<SearchResult<CSVideoItem>>(`csvideo/${id}`, {
      keyword: keyword, 
      row: row,
      page: page
    });
  }

  UpsertCSVideo(csVideo: FormData) {
    return client.post<CSVideo>(`csvideo`, csVideo);
  }

  DeleteCSVideo(id: number | string) {
    return client.post(`csvideo/delete`, { id: id });
  }
  
  GetRecentlyCSVideo() {
    return client.get<CSVideo[]>(`csvideo/recent`);
  }

}
class _LetterNoticeApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetLetterNotice(id: number | string) {
    return client.get<LetterNotice>(`letternotice/view/${id}`);
  }

  GetLetterNoticeListByCategoryId(id: number, row: number, page: number, lang: string, keyword: string) {
    return client.post<SearchResult<LetterNoticeItem>>(`letternotice/${id}/${lang}`, {
      keyword: keyword,
      row: row,
      page: page
    });
  }

  UpsertLetterNotice(letterNotice: FormData) {
    return client.post<LetterNotice>(`letternotice`, letterNotice);
  }

  DeleteLetterNotice(id: number | string) {
    return client.post(`letternotice/delete`, { id: id });
  }
}
class _CSEtcApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetCSEtc(id: number | string) {
    return client.get<CSEtc>(`csetc/view/${id}`);
  }

  GetCSEtcListByCategoryId(id: number | string, row: number = 10, page: number = 1) {
    return client.get<SearchResult<CSEtcItem>>(`csetc/${id}?row=${row}&page=${page}`);
  }

  UpsertCSEtc(csEtc: FormData) {
    return client.post<CSEtc>(`csetc`, csEtc);
  }

  DeleteCSEtc(id: number | string) {
    return client.delete(`csetc/${id}`);
  }
}

class _DownloadApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  GetDownload(id: number | string) {
    return client.get<Download>(`download/view/${id}`);
  }

  GetDownloadListByCategoryId(id: number | string, row: number = 10, page: number = 1, lang: string) {
    return client.get<SearchResult<DownloadItem>>(`download/${id}?row=${row}&page=${page}&lang=${lang}`);
  }

  UpsertDownload(download: FormData) {
    return client.post<Download>(`download`, download);
  }

  DeleteDownload(id: number | string) {
    return client.post(`download/delete`, { id: id });
  }
}

class _SearchApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  GetSearchList(keyword: string, row: number = 10, page: number = 1) {
    return client.post<SearchResult<SearchModel>>(`search`, {
      keyword: keyword,
      row: row,
      page: page,
    });
  }
  GetSearchListInKind(kind: string, keyword: string, row: number = 10, page: number = 1) {
    return client.post<SearchResult<SearchModel>>(`search/${kind}`, {
      kind: kind,
      keyword: keyword,
      row: row,
      page: page,
    });
  }
}

class _FileApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  UploadFile(formData: FormData) {
    return client.post<string>(`file`, formData);
  }

  DeleteFile(filename: string) {
    return client.post<string>(`file/delete`, { name: filename });
  }

  DownloadFile(filename: string, contentType: string) {
    return client.get(`file/download-file/${filename}`, {
      responseType: "blob",
      headers: {
        "Content-Type": contentType,
      },
    });
  }

  // 자재 업로드 형식 받기(admin)
  GetMaterialUploadFormat() {
    return client.get(`file/material/form`, {
      responseType: "blob",
    });
  }

  UploadMaterialImage(formData: FormData) {
    return client.post('file/material/upload/image', formData)
  }

}

const client = new HttpClient();

export const ErpApi = new _ErpApi(client);
export const UserApi = new _UserApi(client);
export const CategoryApi = new _CategoryApi(client);
export const WarrantyApi = new _WarrantyApi(client);
export const ItemApi = new _ItemApi(client);
export const ShopApi = new _ShopApi(client);
export const NoticeApi = new _NoticeApi(client);
export const OrderApi = new _OrderApi(client);
export const CSEducationApi = new _CSEducationApi(client);
export const CSManualApi = new _CSManualApi(client);
export const CSVideoApi = new _CSVideoApi(client);
export const LetterNoticeApi = new _LetterNoticeApi(client);
export const DownloadApi = new _DownloadApi(client);
export const CSEtcApi = new _CSEtcApi(client);
export const FileApi = new _FileApi(client);
export const SearchApi = new _SearchApi(client);

export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    msg += error.response.data;
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "Session Error";
          window.location.replace("/");
          break;
        case 400: //Bad Request
          msg = "Wrong Data Has Been Entered";
          break;
        case 403: //Forbidden
          msg = "Cannot Access";
          break;
        case 404: //NotFound
          msg = "No Data to Show";
          break;
        case 405: //method not allowd
          msg = "405; Cannot Access";
          break;
        case 500:
          msg = "Server Error";
          break;
        default:
          msg = "Unknown Error. Please Contact the Administrator";
          break;
      }
    }
  } else if (error.request) {
    msg += "No Response from the Server";
  }
  return msg;
}
