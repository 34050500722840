import React, { ReactElement } from "react";
import { useUserState } from "system/UserContext";

interface Props {
  children: React.ReactNode;
}

function UserComponent({ children }: Props): ReactElement {
  const user = useUserState();
  if (user.role === "USER" || user.role === "ENGINEER" || user.role === "AGENCY") {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

export default UserComponent;
