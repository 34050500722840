import ListLayer from "components/ListLayer";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { MyRoutes } from "routes/Routes";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { Category } from "system/types";
import CSEtcDetailPage from "./CSEtcDetailPage";
import CSEtcEditPage from "./CSEtcEditPage";
import CSEtcListPage from "./CSEtcListPage";

function CSEtcMainPage(): ReactElement {
  const [list, setList] = useState<Category[]>([]);

  useEffect(() => {
    CategoryApi.GetAllCategories("csetc")
      .then((x) => {
        setList(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const makePathName = useCallback((data: Category, depth?: number) => {
    return {
      pathname: `${MyRoutes.csEtc}/list/${data.id}`,
    };
  }, []);

  return (
    <ListLayer makePathName={makePathName} list={list} route={MyRoutes.csEtc} kind={"csetc"}>
      <Switch>
        <Route path={`${MyRoutes.csEtc}/list/:categoryId`} exact>
          <CSEtcListPage route={MyRoutes.csEtc}></CSEtcListPage>
        </Route>
        <Route path={`${MyRoutes.csEtc}/edit/:categoryId?/:id?`}>
          <CSEtcEditPage></CSEtcEditPage>
        </Route>
        <Route path={`${MyRoutes.csEtc}/:etcId`} exact>
          <CSEtcDetailPage></CSEtcDetailPage>
        </Route>
      </Switch>
    </ListLayer>
  );
}

export default CSEtcMainPage;
