import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";

interface StringInputProps {
  type: "edit" | "view";
  textFieldProps: TextFieldProps;
}

const StringInput = (props: StringInputProps) => {
  if (props.type === "edit") {
    return <TextField {...props.textFieldProps}></TextField>;
  } else {
    return <>{props.textFieldProps.value ?? <i>NO-DATA</i>}</>;
  }
};

export default StringInput;
