import React, { Component } from "react";

export interface ImageProps {
  src: string;
  alt?: string;
  width?: number;
  height: number;
  style?: React.CSSProperties;
  fallbackSrc: string;
  paddingHeight?: number;
  errorPaddingHeight?: number;
  noErrorMsg?: boolean;
  onClick?: () => void;
}
interface State {
  src: string;
  alt?: string;
  errored: boolean;
  style?: React.CSSProperties;
}

export default class Image extends Component<ImageProps, State> {
  state = {
    src: this.props.src,
    errored: false,
    style: (this.props.style
      ? { ...this.props.style, objectFit: "contain" }
      : { objectFit: "contain" }) as React.CSSProperties,
  };

  onError = () => {
    if (!this.state.errored) {
      this.setState((prev) => ({
        src: this.props.fallbackSrc,
        style: { ...prev.style, objectFit: "contain" },
        errored: true,
      }));
    }
  };

  /**
   * props의 src가 변경되었을 때, 이미지도 다시 재로딩 할 수 있도록 변경.
   */
  componentDidUpdate(prevProps: ImageProps) {
    if (this.props.src !== prevProps.src) {
      this.setState((prevState) => ({
        ...prevState,
        src: this.props.src,
      }));
    }
  }

  render() {
    const { src } = this.state;
    const { src: _1, fallbackSrc: _2, paddingHeight, errorPaddingHeight, noErrorMsg, ...props } = this.props;
    const _paddingHeight = paddingHeight === undefined ? 50 : paddingHeight;
    const _errorPaddingHeight = errorPaddingHeight === undefined ? 21 : errorPaddingHeight;
    const height = this.state.errored ? props.height + _errorPaddingHeight : props.height + _paddingHeight;

    return (
      <div style={{ textAlign: "center", width: "100%" }} onClick={props.onClick}>
        <div
          style={{
            width: props.width,
            height: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{ ...this.state.style } as React.CSSProperties}
            alt={this.props.alt}
            src={src}
            onError={this.onError}
            {...props}
          />
        </div>
      </div>
    );
  }
}
