import {
  User,
  Notice,
  ItemView,
  CSEducation,
  CSEducationItem,
  Category,
  SearchResult,
  DCategory,
  SearchModel,
  CheckUnitPrice,
  CSEtc,
  CSEtcItem,
  CSManual,
  CSManualItem,
  CSVideo,
  CSVideoItem,
  LetterNotice,
  LetterNoticeItem,
} from "system/types/types";
import { CartItem, Download, DownloadItem, ItemDetail } from ".";


export const INIT_USER: User = {
  // 여기부터 사용
  id: "",
  customerId: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  contactFor: "",
  contactPersonId: "",
  role: "USER",
  currencyCode: "",
  salesName: "",
  workerSalesTakerName: "",
  custAccount: "",
  priceGroup: "",
  blocked: false,
  showName: "",
};

export const INIT_NOTICE: Notice = {
  id: 0,
  title: "",
  writer: "", 
  writerId: "",
  created: new Date().getTime(),
  views: 0,
  content: "",
};

export const INIT_ITEM_INFO: ItemDetail = {
  productNumber: "",
  productName: "",
  isShow: true,
  stopped: false,
  image: "",
  price: 0,
  categoryName: "",
  caution: "",
  compatibleProduct: "",
  kind: "",
  optionName: "",
};

export const INIT_CHECK_PRICE: CheckUnitPrice = {
  DataareaId: "IHQ",
  ItemNumber: "",
  Customer: "",
  PriceDate: "",
  Quantity: "1",
  Currency: "",
  Unit: "EA",
}

export const INIT_CARTITEM: CartItem = {
  kind: "",
  id: 0,
  productNumber: "",
  productName: "",
  quantity: 1,
  image: "",
  subTotal: 0,
  unitPrice: 0,
  checked: true,
  stopped: false,
  remark: "", // option
  itemRemark: "", // 추가 입력사항
  csCase: "",
  csCaseNum: 0,
  caseType: "",
}

export const INIT_ITEMVIEW: ItemView = {
  productName: "",
  productNumber: "",
  stopped: null,
  isShow: null,
  kind: "",
  price: 0,
  currency: "",
  remark: "",
};

export const INIT_CSEDUCATION: CSEducation = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  file: "",
  fileSize: 0,
  fileName: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  pdf: "",
  pdfSize: 0,
  pdfName: "",
  formFile: new FormData(),
  formPDF: new FormData(),
  lang: "En"
};

export const INIT_CSEDUCATION_ITEM: CSEducationItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  content: "",
};
export const INIT_CSETC: CSEtc = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  file: "",
  fileSize: 0,
  fileName: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  pdf: "",
  pdfSize: 0,
  pdfName: "",
  formFile: new FormData(),
  formPDF: new FormData(),
};

export const INIT_CSETC_ITEM: CSEtcItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  content: "",
};
export const INIT_CSMANUAL: CSManual = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  file: "",
  fileSize: 0,
  fileName: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  pdf: "",
  pdfSize: 0,
  pdfName: "",
  formFile: new FormData(),
  formPDF: new FormData(),
  lang: "En"
};

export const INIT_CSMANUAL_ITEM: CSManualItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  content: "",
};
export const INIT_CSVIDEO: CSVideo = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  file: "",
  fileSize: 0,
  fileName: "",
  thumbnail: "",
  thumbnailSize: 0,
  thumbnailName: "",
  video: "",
  videoSize: 0,
  videoName: "",
  formFile: new FormData(),
  formThumbnail: new FormData(),
  formVideo: new FormData(),
};

export const INIT_CSVIDEO_ITEM: CSVideoItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  thumbnail: "",
  thumbnailSize: 0,
  thumbnailName: "",
  content: "",
};
export const INIT_LETTERNOTICE: LetterNotice = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  file: "",
  fileSize: 0,
  fileName: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  pdf: "",
  pdfSize: 0,
  pdfName: "",
  formFile: new FormData(),
  formPDF: new FormData(),
  lang: "En"
};

export const INIT_LETTERNOTICE_ITEM: LetterNoticeItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  content: "",
};

export const INIT_CATEGORY_ITEM: Category = {
  id: "",
  name: "",
  order: 0,
  isShow: true,
  children: [],
};

export const INIT_DATA_CATEGORY: DCategory = {
  id: 0,
  name: "",
  order: 0,
  isShow: true,
  depth: 0,
  kind: "",
};

export const INIT_DOWNLOAD: Download = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  file: "",
  fileSize: 0,
  fileName: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  content: "",
  views: 0,
  pdf: "",
  pdfSize: 0,
  pdfName: "",
  formFile: new FormData(),
  formPDF: new FormData(),
  lang: "En"
};

export const INIT_DOWNLOAD_ITEM: DownloadItem = {
  id: 0,
  categoryId: 0,
  categoryStepId: "",
  categoryStep: "",
  title: "",
  updated: new Date().getTime(),
  created: new Date().getTime(),
  writer: "", 
  writerId: "",
  kind: "",
  views: 0,
  content: "",
};

export const INIT_SEARCHMODEL: SearchModel = {
  table: "",
  id: 0,
  category: "",
  name: "",
  content: "",
  file: "",
};

export function GetInitSearchResult<T>(ary: T[]): SearchResult<T> {
  return {
    list: ary,
    page: 1,
    count: 0,
    totalPage: 1,
  };
}
