import React, { ReactElement } from "react";
import "style/EditorToolbar.css";
import { Route, Switch } from "react-router";
import NoticeView from "page/Notice/View";
import NoticeEdit from "page/Notice/Edit";
import NoticeList from "page/Notice/List";
import { MyRoutes } from "routes/Routes";

function NoticePage(): ReactElement {
  return (
    <Switch>
      <Route path={`${MyRoutes.notice}/edit/:id?`}>
        <NoticeEdit></NoticeEdit>
      </Route>
      <Route path={`${MyRoutes.notice}/view/:id`}>
        <NoticeView></NoticeView>
      </Route>
      <Route path={MyRoutes.notice} exact>
        <NoticeList announcement={false}></NoticeList>
      </Route>
    </Switch>
  );
}

export default NoticePage;
