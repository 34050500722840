import { Box } from "@material-ui/core";
import MyCartPage from "page/Cart/CartPage";
import OrderedListPage from "page/Cart/Order/OrderedListPage";
import PurchaseOrder from "page/Cart/Order/PurchaseOrder";
import RequestOrderWrapper from "page/Cart/Order/RequestOrder";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import { MyRoutes } from "routes/Routes";

function CartIndexPage(): ReactElement {

  return (
    <>
      <Route path={MyRoutes.cartMine}>
        <MyCartPage></MyCartPage>
      </Route>
      <Route path={"/cart/order/received"}>
        {/* :id is orderId */}
        {/* detail 페이지 */}
        <Route path={"/cart/order/received/:id"}>
          {/* 모바일 뷰 */}
          <Box display={{ xs: "block", sm: "none" }}>
            <PurchaseOrder mobile={true}></PurchaseOrder>
          </Box>
          {/* PC, 태블릿 뷰 */}
          <Box display={{ xs: "none", sm: "block" }}>
            <PurchaseOrder mobile={false}></PurchaseOrder>
          </Box>
        </Route>
        {/* 지난 주문 내역 */}
        <Route path={"/cart/order/received"} exact>
          {/* 모바일 뷰 */}
          <Box display={{ xs: "block", sm: "none" }}>
            <OrderedListPage now={true} mobile={true}></OrderedListPage>
          </Box>
          {/* PC, 태블릿 뷰 */}
          <Box display={{ xs: "none", sm: "block" }}>
            <OrderedListPage now={true} mobile={false}></OrderedListPage>
          </Box>
        </Route>
      </Route>
      <Route path={MyRoutes.cartOrdered}>
        {/* :id is orderId */}
        {/* detail 페이지 */}
        <Route path={"/cart/order/details/:id"}>
          {/* 모바일 뷰 */}
          <Box display={{ xs: "block", sm: "none" }}>
            <PurchaseOrder mobile={true}></PurchaseOrder>
          </Box>
          {/* PC, 태블릿 뷰 */}
          <Box display={{ xs: "none", sm: "block" }}>
            <PurchaseOrder mobile={false}></PurchaseOrder>
          </Box>
        </Route>
        {/* 승인 필요 주문 내역 */}
        <Route path={"/cart/order/details"} exact>
          {/* 모바일 뷰 */}
          <Box display={{ xs: "block", sm: "none" }}>
            <OrderedListPage now={false} mobile={true}></OrderedListPage>
          </Box>
          {/* PC, 태블릿 뷰 */}
          <Box display={{ xs: "none", sm: "block" }}>
            <OrderedListPage now={false} mobile={false}></OrderedListPage>
          </Box>
        </Route>
      </Route>
      
      {/* 아래부터는 PO페이지라서 tab에 포함하지 않음 */}
      <Route path={MyRoutes.cartOrder} exact>
        <RequestOrderWrapper></RequestOrderWrapper>
      </Route>
    </>
  );
}

export default CartIndexPage;
