import { Grid, MenuItem } from "@material-ui/core";
import { ActionButton } from "components/Button";
import CategoryTreeView from "components/CategoryTreeView";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import SimpleSelect from "components/custom/SimpleSelect";
import CategoryEditField from "page/Category/CategoryEditField";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { MyStyles } from "style/theme";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { IsShopCategory } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { Category, DCategory } from "system/types";

function CategoryEditPage(): ReactElement {
  const location = useLocation();
  const _kind = new URLSearchParams(location.search).get("kind");
  const classes = MyStyles();
  const LoadingDispatch = useLoadingDispatch();
  const [list, setList] = useState<Category[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const [kind, setKind] = useState<string>(_kind || "LEARN");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const getAllCategories = useCallback(() => {
    let promise: Promise<any>;
    if (IsShopCategory(kind)) {
      promise = CategoryApi.GetAllCategoriesERP(kind);
    } else {
      promise = CategoryApi.GetAllCategories(kind);
    }
    promise
      .then((res) => {
        setList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [kind]);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  const onChangeSelect = (event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) => {
    setKind(event.target.value as string);
    setSelected(0);
    setIsEdit(false);
  };

  const onClickCategoryItem = (value: Category) => {
    const _id = parseInt(value.id);
    setSelected(isNaN(_id) ? 0 : _id);
    setIsEdit(true);
  };

  const addCategory = () => {
    setSelected(0);
    setIsEdit(false);
  };

  const saveCategory = (value: DCategory) => {
    if (isEdit) {
      LoadingDispatch({ type: "LOADING" });
      // 카테고리 수정
      CategoryApi.UpdateCategory(value)
        .then(() => {
          setSelected(value.id);
          getAllCategories();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    } else {
      LoadingDispatch({ type: "LOADING" });
      // 카테고리 추가
      CategoryApi.InsertCategory(value, kind)
        .then(() => {
          getAllCategories();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    }
  };

  const deleteCategory = (id: number) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const onCloseDialog = () => setDeleteOpen(false);

  const onClickConfirmDialog = () => {
    CategoryApi.DeleteCategory(deleteId)
      .then(() => {
        getAllCategories();
        setSelected(0);
        setIsEdit(false);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
        alert("Cannot be deleted. There are posts in this category.");
      })
      .finally(() => {
        setDeleteOpen(false);
      });
  };

  const message = "Are you sure delete the item?";

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12} className={classes.categoryListWrapper}>
          {/* left */}
          <Grid item container alignItems="center" spacing={2}>
            <Grid item xs={8}>
              <SimpleSelect fullWidth variant="outlined" value={kind} label="Main Category" onChange={onChangeSelect}>
                <MenuItem value={"PRODUCT"}>Products</MenuItem>
                <MenuItem value={"MATERIAL"}>Spare Parts</MenuItem>
                <MenuItem value={"LEARN"}>CS Training</MenuItem>
                <MenuItem value={"MANUAL"}>CS Manual</MenuItem>
                <MenuItem value={"VIDEO"}>CS Video</MenuItem>
                <MenuItem value={"LETTERNOTICE"}>Letter Notice</MenuItem>
                <MenuItem value={"FIRMWARE"}>Download - Firmware</MenuItem>
                <MenuItem value={"LOOKINBODY"}>Download - LookInBody</MenuItem>
                <MenuItem value={"ETC"}>Download - Etc</MenuItem>
                <MenuItem value={"CSETC"}>Etc</MenuItem>
              </SimpleSelect>
            </Grid>
            {IsShopCategory(kind) ? null : (
              <Grid item xs={4}>
                <ActionButton onClick={addCategory}>New</ActionButton>
              </Grid>
            )}
          </Grid>
          <Grid item xs={8}>
            <CategoryTreeView data={list} onClickCategoryItem={onClickCategoryItem}></CategoryTreeView>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <CategoryEditField
            kind={kind}
            isEdit={isEdit}
            categoryId={selected}
            saveCategory={saveCategory}
            deleteCategory={deleteCategory}
          ></CategoryEditField>
        </Grid>
      </Grid>
      <ConfirmDialog
        state={deleteOpen}
        title={"Remove Category"}
        message={message}
        onClose={onCloseDialog}
        onClickConfirm={onClickConfirmDialog}
      ></ConfirmDialog>
    </>
  );
}
export default CategoryEditPage;
