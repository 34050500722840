import React, { useState } from "react";
import {
  IconButton,
  DialogProps,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { FileApi } from "system/ApiService";
import ResponsiveDialog from "components/Dialog/ResponsiveDialog";
import DialogTitleWithExit from "components/Dialog/DialogTitleWithExit";
import { ActionButton, SaveButton } from "components/Button";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

function UploadFile(): React.ReactElement {
  // 이미지 업로드 모달
  const [itemModal, setItemModal] = useState(false);
  const closeItemModal = () => setItemModal(false);
  const openItemModal = () => setItemModal(true);

  const onUploadImage = (upload: File[]) => {
    if (upload.length !== 0) {
      for (let i = 0; i < upload.length; i++) {
        const formData = new FormData();
        formData.append("FormFile", upload[i]);
        FileApi.UploadMaterialImage(formData)
        .then(() => {})
        .catch((err) => {
          console.log("Error! Cannot upload image.")
        })
      }
      alert('Success to Upload');
      closeItemModal();
    } else {
      alert('There is no Image.');
    }
  }

  return (
    <div id="upload-material-file">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <ActionButton onClick={openItemModal} style={{ marginTop: "12px"}}>Upload Image</ActionButton>
          <ImageUploadModal
            open={itemModal}
            onClose={closeItemModal}
            onUploadImage={onUploadImage}
            ></ImageUploadModal>
          </div>
      </div>
    </div>
  );
}

export default UploadFile;

interface ImageUploadModalProp extends DialogProps {
  onUploadImage: (upload: File[]) => void;
}
export function ImageUploadModal ({onUploadImage, ...other} : ImageUploadModalProp) {
  const [ img, setImg ] = useState<string[]>([]);
  const [ upload, setUpload ] = useState<File[]>([]);

  const saveImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const selectedImg : string[] = [];
      const targetImg = event.target.files;
      const targetImgObj = [...targetImg];
      setUpload([...targetImg])
      
      targetImgObj.map((file) => {
        return selectedImg.push(file.name)
        // return selectedImg.push(URL.createObjectURL(file))
      })
      setImg(selectedImg)
    }
  };

  const onUpload = () => {
    onUploadImage(upload);
    setImg([]);
  }

  const deleteImg = (e:any) => {
    const imgArr = img.filter((x, idx) => idx !== e)
    const uploadArr = upload.filter((x, idx) => idx !== e)
    setImg([...imgArr])
    setUpload([...uploadArr])
  }

  return (
    <ResponsiveDialog {...other}  maxWidth="xs">
      <DialogTitleWithExit id="dialog-title" onClose={other.onClose}>
        Upload Spare Parts Image
      </DialogTitleWithExit>
      <DialogContent>
        <Alert severity="info">
          <div>The Image name must be a ERP Code.</div>
          <div>The Image extension name should be .jpg.</div>
          <div>Only Spare Parts should be uploaded here.</div>
        </Alert>
        <br/>
        <input 
          id="file" 
          type="file" 
          onChange={saveImage}
          multiple
          accept=".jpg"
        ></input>
        <br/>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
          {img && img.map((e, idx) => (
            <div>
              {/* <img src={e} alt="upload-img" style={{width : "120px", height : "120px"}}/> */}
              {e}
              <IconButton onClick={() => deleteImg(idx)}><CloseIcon /></IconButton>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <SaveButton onClick={onUpload}>Save</SaveButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}