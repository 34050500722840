import React, { useState, useEffect, ReactElement, useCallback } from "react";
import { Route, Switch } from "react-router";
import { MyRoutes } from "routes/Routes";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { Category } from "system/types";
import CSEducationListPage from "page/CSEducation/ListPage";
import CSEducationDetailPage from "page/CSEducation/DetailPage";
import CSEducationEditPage from "page/CSEducation/EditPage";
import ListLayer from "components/ListLayer";

function CSEducationPage(): ReactElement {
  const [list, setList] = useState<Category[]>([]);

  useEffect(() => {
    CategoryApi.GetAllCategories("learn")
      .then((x) => {
        setList(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const makePathName = useCallback((data: Category, depth?: number) => {
    return {
      pathname: `${MyRoutes.csEducation}/list/${data.id}`,
    };
  }, []);

  return (
    <ListLayer makePathName={makePathName} list={list} route={MyRoutes.csEducation} kind={"learn"}>
      <Switch>
        <Route path={`${MyRoutes.csEducation}/list/:categoryId`} exact>
          <CSEducationListPage route={MyRoutes.csEducation} list={list}></CSEducationListPage>
        </Route>
        <Route path={`${MyRoutes.csEducation}/edit/:categoryId?/:id?`}>
          <CSEducationEditPage route={MyRoutes.csEducation} list={list}></CSEducationEditPage>
        </Route>
        <Route path={`${MyRoutes.csEducation}/:learnId`} exact>
          <CSEducationDetailPage></CSEducationDetailPage>
        </Route>
      </Switch>
    </ListLayer>
  );
}

export default CSEducationPage;
