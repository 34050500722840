import { Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { ButtonExpandIcon } from "components/Button";
import React, { ReactElement } from "react";

interface Props {
  title?: string;
  children?: React.ReactNode;
}
function HelperAlert(props: Props): ReactElement {
  const [open, setOpen] = React.useState(false);

  return (
    <Alert severity="info" action={<ButtonExpandIcon open={open} setOpen={setOpen}></ButtonExpandIcon>}>
      <AlertTitle>{props.title ? props.title : "Help"}</AlertTitle>
      <Collapse in={open}>{props.children}</Collapse>
    </Alert>
  );
}

export default HelperAlert;
