import { TextField, InputAdornment, TextFieldProps, withStyles, createStyles } from "@material-ui/core";
import React, { Component } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const styles = () =>
  createStyles({
    root: {
      cursor: "pointer",
    },
  });

interface PasswordInputState {
  passwordIsMasked: boolean;
}

const PasswordInput = withStyles(styles)(
  class extends Component<TextFieldProps, PasswordInputState> {
    state = { passwordIsMasked: true };
    togglePasswordMask = () => {
      this.setState((prevState) => ({
        passwordIsMasked: !prevState.passwordIsMasked,
      }));
    };

    render() {
      const { classes } = this.props;
      const { passwordIsMasked } = this.state;

      return (
        <TextField
          type={passwordIsMasked ? "password" : "text"}
          {...this.props}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordIsMasked ? (
                  <Visibility classes={classes} onClick={this.togglePasswordMask} />
                ) : (
                  <VisibilityOff classes={classes} onClick={this.togglePasswordMask} />
                )}
              </InputAdornment>
            ),
          }}
        />
      );
    }
  }
);

export default PasswordInput;
