import { Button, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { ErrorHandler, UserApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
interface Props {
  onChange: (type: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

function ResetPassword(props: Props): ReactElement {
  const classes = useStyles();

  const loading = useLoadingDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    if (!formData.get("userId")) {
      alert("ID is empty.");
      return;
    }
    formData.set("userId", (formData.get("userId") as string).trim());

    loading({ type: "LOADING" });
    UserApi.ResetPassword(formData)
      .then((res) => {
        if (res) {
          alert(`Success to send reset email. ${res}`);
        } else {
          alert("There is no email information for this ID.");
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
        alert("Error to Send Request email." + msg);
      })
      .finally(() => loading({ type: "COMPLETE" }));
  };

  return (
    <div>
      <Typography variant="h5">Reset Password</Typography>
      <div className="text-right">
        <Typography variant="body2" role="button" onClick={() => props.onChange("signin")}>
          Back to sign-in
        </Typography>
      </div>

      <form style={{ paddingTop: "10px" }} onSubmit={onSubmit}>
        <Typography variant="body1" className="text-left">
          Enter your ID, and we’ll send you instructions on how to reset your password via a registered email.
        </Typography>

        <div style={{ padding: "10px 20px" }}>
          <TextField
            id="userId"
            label="ID"
            name="userId"
            fullWidth
            type="text"
            variant="outlined"
            inputProps={{
              title: "id",
            }}
          ></TextField>
          <div style={{ padding: "5px" }}></div>
          <Button
            type="submit"
            name="button"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Send me reset Password
          </Button>
        </div>
      </form>
      <span className="help-link">
        <Typography role="button" onClick={() => props.onChange("forgot-email")}>
          Forgot ID?
        </Typography>
      </span>
    </div>
  );
}

export default ResetPassword;
