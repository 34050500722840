import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import {
  Collapse,
  createStyles,
  CssBaseline,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { CategoryApi } from "system/ApiService";
import { useUserState } from "system/UserContext";
import { IsAgencyUser, IsEngineer2User, IsEngineerUser } from "system/Helper";
import { PRIMARY } from "style/theme";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      fontSize: "20px",
      textAlign: "center",
      backgroundColor: PRIMARY,
      color: "white",
      "& a:hover": {
        color: "white",
        backgroundColor: PRIMARY,
        filter: "brightness(1.2)",
      },
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      marginTop: "80px",
    },
    presentation: {
      height: "100%",
      position: "absolute",
      zIndex: 10,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    activeNav: {
      backgroundColor: "#811426",
    },
  })
);

interface NavMenuProps {
  open: boolean;
}

function NavMenu({ open }: NavMenuProps): ReactElement {
  const classes = useStyles();
  const [shop, setShop] = useState<boolean>(true);
  const [download, setDownload] = useState<boolean>(true);
  const [InBody270, setInBody270] = useState<string>();
  const user = useUserState();

  const handleClickShop = () => {
    setShop(!shop);
  };

  const handleClickDownload = () => {
    setDownload(!download);
  };

  const findInBody270 = useCallback(() => {
    CategoryApi.GetAllCategoriesERP("MATERIAL")
      .then((x) => {
        setInBody270(
          x.filter((item) => item.name === "InBody")[0].children.filter((x) => x.name === "IBD_InBody270")[0].id
        );
      })
      .catch(() => {
        alert("Cannot find InBody270 Url.");
      });
  }, []);

  useEffect(() => {
    findInBody270();
  }, [findInBody270]);

  return (
    <>
      <CssBaseline />
      <div className={classes.presentation} role="presentation">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container style={{ height: "90vh" }}>
              <Grid item xs={12}>
                <List>
                  <ListItem
                    button
                    key={"notice"}
                    component={NavLink}
                    to={MyRoutes.notice}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary={"Notice"} />
                  </ListItem>
                  <ListItem onClick={handleClickShop} button key={"WEB SHOP"} component={Link} to={MyRoutes.shop}>
                    <ListItemText primary={"WEB SHOP"} />
                    {shop ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={shop} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {IsEngineerUser(user.role) ? null : (
                        <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.shopProduct}
                          activeClassName={classes.activeNav}
                        >
                          <ListItemText primary="Products" />
                        </ListItem>
                      )}
                      <ListItem
                        button
                        className={classes.nested}
                        component={NavLink}
                        to={`${MyRoutes.shopMaterial}/${InBody270 ? InBody270 : ""}`}
                        activeClassName={classes.activeNav}
                      >
                        <ListItemText primary="Spare Parts" />
                      </ListItem>
                    </List>
                  </Collapse>
                  {!IsEngineer2User(user.role) && (
                    <ListItem
                      button
                      key={"WARRANTY CLAIM"}
                      component={NavLink}
                      to={MyRoutes.warranty}
                      activeClassName={classes.activeNav}
                    >
                      <ListItemText primary={"WARRANTY CLAIM"} />
                    </ListItem>
                  )}
                  {IsAgencyUser(user.role) ? null : (
                    <>
                      <ListItem
                        button
                        key={"CS TRAINING"}
                        component={NavLink}
                        to={MyRoutes.csEducation}
                        activeClassName={classes.activeNav}
                      >
                        <ListItemText primary={"CS TRAINING"} />
                      </ListItem>
                      <ListItem
                        button
                        key={"CS MANUAL"}
                        component={NavLink}
                        to={MyRoutes.csManual}
                        activeClassName={classes.activeNav}
                      >
                        <ListItemText primary={"CS MANUAL"} />
                      </ListItem>
                      <ListItem
                        button
                        key={"CS VIDEO"}
                        component={NavLink}
                        to={MyRoutes.csVideo}
                        activeClassName={classes.activeNav}
                      >
                        <ListItemText primary={"CS VIDEO"} />
                      </ListItem>
                      <ListItem
                        button
                        key={"Letter Notice"}
                        component={NavLink}
                        to={MyRoutes.letterNotice}
                        activeClassName={classes.activeNav}
                      >
                        <ListItemText primary={"Letter Notice"} />
                      </ListItem>
                      <ListItem
                        onClick={handleClickDownload}
                        button
                        key={"Download"}
                        component={Link}
                        to={MyRoutes.downloadFirmware}
                      >
                        <ListItemText primary={"Download"} />
                        {download ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={download} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={MyRoutes.downloadFirmware}
                            activeClassName={classes.activeNav}
                          >
                            <ListItemText primary="Firmware" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={MyRoutes.downloadLookinbody}
                            activeClassName={classes.activeNav}
                          >
                            <ListItemText primary="LookinBody" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={MyRoutes.downloadLibrary}
                            activeClassName={classes.activeNav}
                          >
                            <ListItemText primary="Etc" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </>
                  )}
                  {IsAgencyUser(user.role) ? null : (
                    <ListItem
                      button
                      key={"Etc."}
                      component={NavLink}
                      to={MyRoutes.csEtc}
                      activeClassName={classes.activeNav}
                    >
                      <ListItemText primary={"Etc."} />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default NavMenu;
