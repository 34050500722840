import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { CancelButton, SaveButton } from "components/Button";
import PasswordInput from "components/Common/PasswordInput";
import React, { useState } from "react";
import { ErrorHandler, UserApi } from "system/ApiService";
import { isValidPassword } from "system/Helper";
import { INIT_USER, User } from "system/types";
import GuideRoleTable from "./GuideRoleTable";
import { Table } from "react-bootstrap";

interface TempUser extends User {
  password?: string;
}

interface AdminDialogProps {
  open: boolean;
  saveCallback: () => void;
  onClose: () => void;
}

function AdminDialog({ open, saveCallback, onClose }: AdminDialogProps) {
  const [user, setUser] = useState<TempUser>({ ...INIT_USER, password: "" });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popOver = Boolean(anchorEl);
  const popOverId = "role-popover";

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChangeRole(event.target.value as string);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeRole = (role: string) => {
    setUser({
      ...user,
      role: role,
    });
  };

  const updateUser = () => {
    if (!user.role) {
      alert("Please fill out role fields.");
    } else {
      if (!user.id && !(user.password && isValidPassword(user.password))) {
        alert("Please fill in all fields.");
      } else {
        UserApi.InsertAdminUser(user)
          .then(() => {
            alert("Success to Create");
            onClose();
            saveCallback();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert("중복된 ID가 존재합니다. 다른 ID를 입력해주세요." + msg);
          });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth style={{ minWidth: "600px" }}>
      <DialogTitle>Insert Admin Info</DialogTitle>
      <DialogContent>
        <Table>
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <TextField name="id" fullWidth={true} value={user.id} onChange={onChange}></TextField>
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <td>
                <TextField name="showName" fullWidth={true} value={user.showName} onChange={onChange}></TextField>
              </td>
            </tr>
            <tr>
              <th>
                Role
                <IconButton
                  size="small"
                  aria-describedby={popOverId}
                  onClick={handleClick}
                  style={{ padding: "0px 5px" }}
                >
                  <HelpOutlineIcon></HelpOutlineIcon>
                </IconButton>
                <Popover
                  id={popOverId}
                  open={popOver}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <GuideRoleTable></GuideRoleTable>
                </Popover>
              </th>
              <td>
                <FormControl style={{ minWidth: 100 }}>
                  <Select style={{ width: "200px" }} value={user.role} name="role" onChange={onChangeSelect}>
                    <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                    <MenuItem value={"MANAGER"}>MANAGER</MenuItem>
                  </Select>
                </FormControl>
              </td>
            </tr>
            <tr>
              <th>Password</th>
              <td>
                <PasswordInput
                  value={user.password}
                  name="password"
                  onChange={onChange}
                  fullWidth={true}
                  helperText="If empty, It will not change. At least 8 characters and contain letters,numbers,symbols"
                ></PasswordInput>
              </td>
            </tr>
          </tbody>
        </Table>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose}>CANCEL</CancelButton>
        <SaveButton onClick={updateUser}>CREATE</SaveButton>
      </DialogActions>
    </Dialog>
  );
}

export default AdminDialog;
