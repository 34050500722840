import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { ActionButton, CancelButton, SaveButton } from "components/Button";
import ProfileDetailUser from "page/User/Account/ProfileDetailUser";
import React, { useEffect, useState } from "react";
import { ErrorHandler, UserApi } from "system/ApiService";
import { IsDefaultAdmin, IsManagerUser, isValidPassword } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { INIT_USER, User } from "system/types";

interface TempUser extends User {
  password?: string;
}

interface UserDialogProps {
  open: boolean;
  id: string;
  saveCallback: () => void;
  onClose: () => void;
}

function UserDialog({ open, id, saveCallback, onClose }: UserDialogProps) {
  const [state, setState] = useState<TempUser>({ ...INIT_USER, password: "" });
  const Loading = useLoadingDispatch();

  useEffect(() => {
    if (id) {
      Loading({ type: "LOADING" });
      UserApi.GetProfile(id)
        .then((res) => {
          setState(res.userInit);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot get user profile." + msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  }, [id, Loading]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeRole = (role: string) => {
    setState({
      ...state,
      role: role,
    });
  };

  const updateUser = () => {
    // 권한, 비밀번호는 필수 입력사항
    // 비밀번호가 있어 로그인 가능한 계정의 경우 비밀번호 업데이트 안됨
    if (!state.role) {
      alert("Please fill out role fields.");
    } else {
      if (!state.id && !(state.password && isValidPassword(state.password))) {
        alert("Please enter vaild password.");
      } else {
        UserApi.UpdateUser(state)
          .then(() => {
            alert("Success to Update");
            onClose();
            saveCallback();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log("Error! Cannot update user info." + msg);
          });
      }
    }
  };

  const onDeleteUser = () => {
    if (state.role === "ADMIN" || state.role === "MANAGER") {
      if (
        window.confirm(
          `${state.id}(${state.role}) 계정을 삭제하시겠습니까? 삭제할 경우 해당 ID가 작성한 글을 수정, 삭제할 수 없습니다.`
        )
      ) {
        UserApi.DeleteUser(state.id)
          .then((res) => {
            onClose();
            if (res === -1) {
              alert("Error!");
            } else {
              alert("Success to Delete");
              saveCallback();
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log("Error! Cannot Delete" + msg);
          });
      }
    } else {
      if (
        window.confirm(
          `${state.id}(${state.role}) 계정을 삭제하시겠습니까? WAIT 상태 주문이 있는 경우 삭제가 불가능하며, 삭제 후에도 Account Manager 페이지에서 Role, Password 설정 시 언제든지 계정을 오픈할 수 있습니다.`
        )
      ) {
        UserApi.DeleteUser(state.id)
          .then((res) => {
            onClose();
            if (res === -1) {
              alert("Error! Please Check Order.");
            } else {
              alert("Success to Delete");
              saveCallback();
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert("Error! Cannot Delete." + msg);
          });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth style={{ minWidth: "600px" }}>
      <DialogTitle>Update User Info</DialogTitle>
      <DialogContent>
        <ProfileDetailUser
          add={state.id ? false : true}
          data={state}
          onChange={onChange}
          onChangeRole={onChangeRole}
        ></ProfileDetailUser>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <div>{!IsDefaultAdmin(state.id) && state.id && <ActionButton onClick={onDeleteUser}>Delete</ActionButton>}</div>
        <div>
          <CancelButton style={{ marginRight: "5px" }} onClick={onClose}>
            CLOSE
          </CancelButton>
          {state.id && IsManagerUser(state.role) ? null : <SaveButton onClick={updateUser}>UPDATE</SaveButton>}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;
