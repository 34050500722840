import { Grid, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import BreadcrumbsCategory from "components/BreadcrumbsCategory";
import { ActionButton, ImportantButton, SaveButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import HtmlContent from "components/RichTextBox/HtmlContent";
import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { CSVideoApi, ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";
import { CSVideo, INIT_CSVIDEO } from "system/types";

interface Params {
  videoId: string;
}

function CSVideoDetailPage(): ReactElement {
  let { videoId } = useParams<Params>();
  const csVideoId = parseInt(videoId);
  const LoadingDispatch = useLoadingDispatch();
  const history = useHistory();
  const [data, setData] = useState<CSVideo>(INIT_CSVIDEO);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const classes = MyStyles();
  const user = useUserState();
  const route = MyRoutes.csVideo;
  const message = "Are you sure you want to delete this post?\r\n※ Recovery is not possible after deletion.";

  useEffect(() => {
    LoadingDispatch({ type: "LOADING" });
    CSVideoApi.GetCSVideo(csVideoId)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("Error! Cannot get etc..");
        console.log(msg);
      })
      .finally(() => LoadingDispatch({ type: "COMPLETE" }));
  }, [LoadingDispatch, csVideoId]);

  const onDelete = () => {
    setDeleteOpen(true);
  };

  const closeDialog = () => {
    setDeleteOpen(false);
  };

  const onDownload = () => {
    fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const blobObjectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobObjectUrl;
        link.download = data.fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobObjectUrl);
      })
      .catch((error) => {
        console.error("Error downloading the blob:", error);
      });
  };

  const confirmDialog = () => {
    CSVideoApi.DeleteCSVideo(data.id)
      .then(() => {
        history.replace({
          pathname: `${route}/list/${data.categoryId}`,
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  };

  const onEdit = () => {
    history.push({
      pathname: `${route}/edit/${data.categoryId}/${data.id}`,
    });
  };

  if (isNaN(csVideoId)) {
    history.replace({
      pathname: "/",
      state: {},
    });
    return <></>;
  } else {
    const categoryStep = (data.categoryStep && data.categoryStep.split("|")) || [];
    const categoryStepId = (data.categoryStepId && data.categoryStepId.split("|")) || [];
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.locationSection}>
          <BreadcrumbsCategory
            route={route}
            kind={"video"}
            step={categoryStep}
            stepId={categoryStepId}
          ></BreadcrumbsCategory>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{data.title}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.fileLink} style={{ textAlign: "right" }}>
          {data.file && (
            <ImportantButton variant="outlined" onClick={onDownload} startIcon={<GetAppIcon />}>
              <FileSize name={data.fileName} size={data.fileSize}></FileSize>
            </ImportantButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <div style={{ position: "relative", paddingBottom: "56.25%", overflow: "hidden" }}>
            <video
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={data.video}
              controls={true}
            ></video>
          </div>
        </Grid>
        <Grid item xs={12}>
          <HtmlContent className={classes.csContent} data={data.content}></HtmlContent>
        </Grid>
        {user.id === data.writerId && (
          <Grid
            item
            xs={12}
            className={classes.marginTop10}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <SaveButton onClick={onDelete}>DELETE</SaveButton>
            <ActionButton variant="contained" onClick={onEdit}>
              EDIT
            </ActionButton>
          </Grid>
        )}
        <ConfirmDialog
          state={deleteOpen}
          title={"Delete Post"}
          message={message}
          onClose={closeDialog}
          onClickConfirm={confirmDialog}
        ></ConfirmDialog>
      </Grid>
    );
  }
}

export default CSVideoDetailPage;
