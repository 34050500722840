import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { Order, Shipping, UserAddress } from "system/types";
import { Table } from "react-bootstrap";
import { CancelButton, SaveButton } from "components/Button";
import SimpleSelect, { SimpleChangeEvent } from "components/custom/SimpleSelect";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

interface OrderModalProps extends DialogProps {
  data: Order;
  address?: UserAddress[];
  shipping?: Shipping;
  type?: "view";
  onUpdate: (data: Order) => void;
}

function OrderInfoModal({ data, shipping, type, onUpdate: _onUpdate, ...dialogProps }: OrderModalProps) {
  const [state, setState] = useState(data);

  const onClickCloseModal = () => dialogProps.onClose!({}, "escapeKeyDown");
  const onUpdate = () => {
    onClickCloseModal();
    _onUpdate(state);
  };

  const onChangeSelect = (event: React.ChangeEvent<SimpleChangeEvent>) => {
    if (event.target.name) {
      const value = event.target.value as string;
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
  };

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Change Purchase Order Information</DialogTitle>
      <DialogContent>
        {type === "view" ? (
          // order detail(date 변경은 관리자만 가능함)
          <Table>
            <tbody>
              <tr>
                <th>Date of Order: </th>
                <td>{state.ordered.split("T")[0]}</td>
              </tr>
              <tr>
                <th>Shipping Terms (Incoterms): </th>
                <td>
                  <SimpleSelect name="divTerm" value={state.divTerm} fullWidth onChange={onChangeSelect}>
                    {shipping?.divTerm.map((x, idx) => (
                      <MenuItem key={idx} value={x.termsCode}>
                        {x.termsCode}
                      </MenuItem>
                    ))}
                  </SimpleSelect>
                </td>
              </tr>
              <tr>
                <th>Shipping Method (Carrier): </th>
                <td>
                  <SimpleSelect name="divMode" value={state.divMode} fullWidth onChange={onChangeSelect}>
                    {shipping?.divMode.map((x, idx) => (
                      <MenuItem key={idx} value={x.modeCode}>
                        {x.modeCode}
                      </MenuItem>
                    ))}
                  </SimpleSelect>
                </td>
              </tr>
              <tr>
                <th>Payment Terms: </th>
                <td>{state.payTerm}</td>
              </tr>
              <tr>
                <th style={{ color: "#a24642" }}>Estimated Shipping Date: </th>
                <td>
                  <DatePicker
                    label="Estimated Shipping Date"
                    minDate={state.ordered}
                    format={"YYYY-MM-DD"}
                    showTodayButton
                    value={state.shippingDate}
                    onChange={(date: MaterialUiPickersDate) => {
                      if (date) {
                        setState({
                          ...state,
                          shippingDate: moment(date).format("YYYY-MM-DD"),
                        });
                      }
                    }}
                  ></DatePicker>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          // po
          <Table>
            <tbody>
              <tr>
                <th>Date of Order: </th>
                <td>{state.ordered.split("T")[0]}</td>
              </tr>
              <tr>
                <th style={{ color: "#a24642" }}>Shipping Terms (Incoterms): </th>
                <td>
                  <SimpleSelect name="divTerm" value={state.divTerm} fullWidth onChange={onChangeSelect}>
                    {shipping?.divTerm.map((x, idx) => (
                      <MenuItem key={idx} value={x.termsCode}>
                        {x.termsCode}
                      </MenuItem>
                    ))}
                  </SimpleSelect>
                </td>
              </tr>
              <tr>
                <th style={{ color: "#a24642" }}>Shipping Method (Carrier): </th>
                <td>
                  <SimpleSelect name="divMode" value={state.divMode} fullWidth onChange={onChangeSelect}>
                    {shipping?.divMode.map((x, idx) => (
                      <MenuItem key={idx} value={x.modeCode}>
                        {x.modeCode}
                      </MenuItem>
                    ))}
                  </SimpleSelect>
                </td>
              </tr>
              <tr>
                <th>Payment Terms: </th>
                <td>{state.payTerm}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {/* <input  type="date" name="depositDueDate"></input> */}
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClickCloseModal}>Cancel</CancelButton>
        <SaveButton onClick={onUpdate}>Update</SaveButton>
      </DialogActions>
    </Dialog>
  );
}

export default OrderInfoModal;
