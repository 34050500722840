import React, { ReactElement } from "react";

interface FileSizeProps {
  name: string;
  size: number;
}

function CalculateFileSize(size: number): string {
  return size > 1048576 ? `(${(size / 1048576).toFixed(1)}MB)` : `(${(size / 1024).toFixed(1)}KB)`;
}

function FileSize({ name, size }: FileSizeProps): ReactElement {
  return (
    <>
      {/* 파일 크기 표시 */}
      {name && `${name} ${CalculateFileSize(size)}`}
    </>
  );
}

export default FileSize;
