import React, { ReactElement } from "react";

function PrivacyPolicy(): ReactElement {
  return (
    <div>
      <h2>Who we are</h2>
      <p>
        This website is operated by InBody Co., Ltd. InBody Bldg., 625, Eonju-Ro, Gangnam-gu, Seoul, 06106 Korea
        (&lsquo;we&rsquo; or &lsquo;us&rsquo;).
      </p>
      <p>
        We take your privacy very seriously and process your data under the General Data Protection Regulation (GDPR).
        We ask that you read this privacy policy carefully as it contains important information on:
      </p>
      <ul>
        <li>the personal data we collect about you</li>
        <li>the reasons and legal basis for processing personal data</li>
        <li>security</li>
        <li>with whom your personal data information might be shared with</li>
        <li>what rights you have regarding the processing of your data</li>
      </ul>
      <p>
        We are a &lsquo;data controller&rsquo;, (i.e. we are responsible for, and control the processing of, your
        personal information) and we can be contacted as follows:
      </p>
      <p>
        By post: Data Processing Manager Mr. Dawson Kim, InBody Bldg., 625, Eonju-Ro, Gangnam-gu, Seoul, 06106 Korea
      </p>
      <p>By e-mail: hqcs@inbody.com</p>
      <p>By phone: +82-2-300-2276</p>
      <h2>What personal data information do we collect?</h2>
      <p>
        We collect your data concerning you visiting our website and contacting us, product support, clinical support,
        C/S support, and registration for our newsletter: The following (categories of) data are processed.
      </p>
      <ul>
        <li>
          Product Support
          <br /> Name, Email, Address, Phone Number, Company Name, Address, Zipcode
        </li>
      </ul>
      <ul>
        <li>
          Clinical Support
          <br /> Name, Email Address, Phone Number, Company Name, Address, Zipcode
        </li>
      </ul>
      <ul>
        <li>
          C/S Support
          <br /> Device Serial Number, Company Name, Name, Phone Number, Email Address, Address
        </li>
      </ul>
      <ul>
        <li>
          Contact Us / Communication
          <br /> Full Name, Email Address, Business Name, Job Title, Phone Number, Address
        </li>
      </ul>
      <ul>
        <li>
          Registration for our newsletter
          <br /> Email address
        </li>
      </ul>
      <ul>
        <li>
          Online shop of the website
          <br /> Name, Email Address, Phone Number, Company Name, Address, Zip code, VAT Number
        </li>
      </ul>
      <p>
        We also collect personal data when you contact us, send us feedback, post material to our website, complete
        customer surveys, or participate in competitions.
      </p>
      <p>
        If you are under the age of 16, your registration and your data protection consent must be approved by the
        person with parental responsibility for you. You have to provide us with the mobile phone number of such
        responsible person which we will use to obtain such approval. If approval has not been granted within 10 days,
        we will delete your data.
      </p>
      <h4>Personal data about other individuals</h4>
      <p>
        If you give us personal data on behalf of someone else (such as a child, see above), you confirm that the other
        person has appointed you to act on his/her behalf and has agreed that you can:
      </p>
      <ul>
        <li>give consent on his/her behalf to the processing of his/her data;</li>
        <li>receive on his/her behalf any data protection notices</li>
      </ul>
      <h2>Reasons and legal basis for processing</h2>
      <p>We process personal data about you for the following reasons:</p>
      <ul>
        <li>communication with you about us and/or our products which may be of interest to you</li>
        <li>
          monitoring and recording communications (such as telephone conversations and e-mail) to improve the quality of
          our services and/or prevention of fraud
        </li>
        <li>to send you our newsletters when you have subscribed for our newsletter</li>
        <li>regulatory and corporate governance obligations and good practice</li>
        <li>
          gathering information as part of investigations by regulatory bodies or in connection with legal proceedings
          or requests
        </li>
        <li>operational reasons, such as recording transactions, training, and quality control</li>
        <li>ensuring the confidentiality of commercially sensitive information</li>
        <li>investigating complaints and allegations of criminal offenses</li>
        <li>statistical analysis</li>
        <li>analyzing preferences and improving services</li>
        <li>providing customer services</li>
        <li>marketing purposes</li>
      </ul>
      <p>We process your data on the following legal basis</p>
      <p>
        Concerning communication, operational reasons, investigating complaints and allegations product support,
        clinical support, C/S support, contact us, statistical analysis, analyzing preferences and improving services,
        providing customer services and marketing purposes:
      </p>
      <ul>
        <li>For the performance of a contract and/or</li>
        <li>
          To pursue a legitimate interest by us, namely to promote and sell our products, to conduct our business, to
          monitor and improve the quality of our services, and to prevent fraud
        </li>
        <li>To comply with legal obligations, such as tax regulations, health care regulations, etc.</li>
      </ul>
      <p>About the newsletter:</p>
      <ul>
        <li>Consent and/or</li>
        <li>Performance of a contract and/or</li>
        <li>
          To pursue our legitimate interests namely to inform you about us and our products and for marketing purposes.
        </li>
      </ul>
      <p>
        You always have the option to unsubscribe from our newsletter.
        <br /> You are not obliged to provide us with your data. However, if you do not provide this information, we
        might not be able to make our website available to you, answer your questions, and/or enter into contact with
        you.
        <br /> We do not process your data except for the reasons stated above. No more personal data are processed than
        strictly necessary for the purposes mentioned in this policy.
      </p>
      <h2>Security</h2>
      <p>
        We take appropriate organizational and technical measures to protect your data and to prevent misuse, loss, or
        alteration of your data. Your data is encrypted when it is stored on our server. In addition personal data is
        transferred between our servers and you via a secure encrypted internet connection.
      </p>
      <p>
        While we will use all reasonable efforts to safeguard your data, you acknowledge that the use of the internet is
        not entirely secure and for this reason, we cannot guarantee the security or integrity of any personal data that
        are transferred from you or to you via the internet.
      </p>
      <h4>Your right to complain</h4>
      <p>
        If you have a complaint about our use of your information, we would prefer you to contact us directly in the
        first instance so that we can address your complaint. However, you can also contact the governing authority on
        the personal data processing of your country.
      </p>
      <h2>Systems used to process personal data</h2>
      <p>
        We gather the personal data directly from you and also via our websites, App, and other technical systems. These
        may include, for example, our:
      </p>
      <ul>
        <li>Computer networks and connections</li>
        <li>Communications systems</li>
        <li>And other hardware and software owned, used, or provided by or on behalf of us.</li>
      </ul>
      <h2>Use of cookies</h2>
      <p>
        A cookie is a small text file that is placed onto your computer (or another electronic device) when you use our
        website or App. We use the following cookies:
      </p>
      <ul>
        <li>
          Functional cookies: these cookies ensure that the website functions properly. This may include cookies that
          are necessary to create an account
        </li>
        <li>
          Analytical cookies ensure that insight can be gained into how website visitors use our website so that we can
          improve the website and the website is geared to what website visitors find interesting and important. We do
          not use the data obtained with these cookies to study the use of the website at an individual level, but only
          at an aggregated level.
        </li>
      </ul>
      <p>
        We have concluded a processor agreement with Google Inc. (&ldquo;Google&rdquo;) and use Google Analytics, an
        analytics web service provided by Google Inc., to ensure that our Website functions properly. We use Google
        Analytics cookies to improve the quality and effectiveness of our website and have configured the application in
        a privacy-friendly manner following the instructions of the Dutch Data Protection Authority.
      </p>
      <p>
        Cookie data about your use of our website is transferred to and stored by Google. Google processes the IP
        addresses of all website visitors for our company. In addition, our company uses an anonymized Google Analytics
        application that removes the last octet of the IP addresses of your website visitors. The IP anonymization takes
        place as soon as data is received by the Analytics Collection Network, and before any storage or processing
        takes place.
      </p>
      <p>
        We do not allow Google to use or share our analytics data for any purpose besides providing us with analytics
        information.
      </p>
      <p>
        Most browsers are set to accept cookies by default. You may opt-out of the collection and storage of data by
        Google Inc. and set the browser to disable cookies or indicate when a cookie is being sent. However, some
        functions and services of our website may not function properly if cookies are disabled.
      </p>
      <p>If required by law, the website visitor gives prior consent to the use of cookies.</p>
      <h2>Transfer of personal data</h2>
      <h4>Who your data might be shared with</h4>
      <p>We may disclose your data to:</p>
      <ul>
        <li>Other companies within our group;</li>
        <li>Our agents and service providers;</li>
        <li>Law enforcement agencies in connection with any investigation to help prevent unlawful activity</li>
        <li>
          A third party who acquires InBody or acquires substantially all of our assets, in which case the personal data
          shall be one of the acquired assets;
        </li>
        <li>Other software providers you may request to give you access to your InBody device data.</li>
      </ul>
      <h2>Transfers of your data out of the EEA</h2>
      <p>
        Your data is stored on servers located in Europe. Your data will not be transferred outside the EEA without your
        consent.
      </p>
      <h2>Retention periods</h2>
      <p>
        Your data will be held under our retention policy. In general, we store your data until you (request to) delete
        your data.
        <br /> Sometimes a longer retention period is applicable, for instance in the case of a legal retention term,
        claims, etc.
      </p>
      <h2>What rights do you have?</h2>
      <p>You have several important rights, which can be exercised free of charge. You have the right to:</p>
      <ul>
        <li>Request access to your personal</li>
        <li>Require us to correct any mistakes in your data which we hold</li>
        <li>Require the erasure of personal data concerning you in certain situations</li>
        <li>
          Receive the personal data concerning you which you have provided to us, in a structured, commonly used, and
          machine-readable format and have the right to transmit those data to a third party in certain situations
        </li>
        <li>Object at any time to processing of personal data concerning you for direct marketing</li>
        <li>Object in certain other situations to our continued processing of your data</li>
        <li>Otherwise, restrict our processing of your data in certain circumstances.</li>
      </ul>
      <p>
        Where you have given consent to any personal data processing, you have the right to withdraw that consent at any
        time.
      </p>
      <p>
        If you would like to exercise any of these rights, please contact our Data Protection Manager below providing
        enough information to identify you and let us know which information to which your request relates.
      </p>
      <p>
        If you have a complaint about our handling of your data you can complain at [hqcs@inbody.com], you also have the
        right to make a complaint to the Information Commissioners Office, which is the supervising authority about data
        processing.
      </p>
      <p>
        We may change this privacy notice from time to time, when we do we will inform you by posting it on our website.
      </p>
      <h2>How to contact us</h2>
      <p>
        Please contact us if you have any questions about this privacy policy or the personal data we hold about you.
      </p>
      <p>
        If you wish to contact us, please send an email to [hqcs@inbody.com], or write to us at [InBody Bldg., 625,
        Eonju-Ro, Gangnam-gu, Seoul, 06106 Korea] or call us on [+82-2-300-2276]
      </p>
      <h2>Changes to the privacy policy</h2>
      <p>
        We may change this privacy policy from time to time. You should check this policy occasionally to ensure you are
        aware of the most recent version that will apply each time you access this website.
      </p>
      <p>This Privacy Policy was last updated: [June 2021].</p>
    </div>
  );
}

export default PrivacyPolicy;
