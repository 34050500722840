import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLoadingDispatch } from "system/LoadingContext";
import { ShopApi } from "system/ApiService";
import { Cart, CartItem } from "system/types";
import EmptyCart from "page/Cart/EmptyCart";
import CartView from "page/Cart/CartView";
import AddCartItemModal from "components/Cart/AddCartItemModal";
import { SaveButton } from "components/Button";
import { useQtyDispatch } from "system/QuantityContext";

function MyCartPage(): ReactElement {
  const LoadingDispatch = useLoadingDispatch();
  const qtyDispatch = useQtyDispatch();
  const [cart, setCart] = useState<Cart>();
  //for modal
  const [itemDetailModal, setItemDetailModal] = useState(false);
  const openItemDetailModal = () => setItemDetailModal(true);
  const closeItemDetailModal = () => setItemDetailModal(false);

  const setStateCallback = useCallback((state: Cart | undefined) => {
    setCart(state);
  }, []);

  const onLoad = useCallback(() => {
    //get user Cart
    LoadingDispatch({ type: "LOADING" });
    ShopApi.GetMyCart()
      .then((response) => {
        qtyDispatch({ qty: response.items.length });
        setCart({
          ...response,
          items: response.items.map((x) => ({
            ...x,
            checked: true,
          })),
        });
      })
      .catch((error) => {
        alert("Error! Cannot get cart items.");
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  }, [LoadingDispatch, qtyDispatch]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onUpdate = (e: CartItem[]) => {
    if (e) {
      ShopApi.UpdateCart(e)
        .then(() => {
          onLoad();
        })
        .catch(() => {
          alert("Error! Cannot update item(s).");
        });
    }
  };

  const onDeleteSelectedCartItem = () => {
    if (cart) {
      if (window.confirm("Are you sure to delete?")) {
        const delLists = cart.items.filter((x) => x.checked).map((x) => x.id);
        LoadingDispatch({ type: "LOADING" });

        ShopApi.DeleteMyCart(delLists)
          .then((res) => {
            qtyDispatch({ qty: res.items.length });
            setCart({
              ...cart,
              items: res.items,
            });
          })
          .catch((error) => {
            alert("Error! Cannot delete cart item(s).");
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      }
    } else {
      window.alert("Your Cart is Empty");
    }
  };

  if (cart && cart.items.length >= 1) {
    return (
      <>
        <div className="text-right mb-2">
          <SaveButton onClick={openItemDetailModal} aria-label="add product">
            Quick Add Cart
          </SaveButton>
        </div>
        <AddCartItemModal
          data={cart.items}
          onUpdate={(e) => onUpdate(e)}
          open={itemDetailModal}
          onClose={closeItemDetailModal}
          scroll={"paper"}
          maxWidth={"lg"}
        ></AddCartItemModal>
        <CartView data={cart} setData={setStateCallback} onDeleteSelectedCartItem={onDeleteSelectedCartItem}></CartView>
      </>
    );
  } else {
    return (
      <>
        <EmptyCart></EmptyCart>
        <div className="text-center mt-3">
          <SaveButton onClick={openItemDetailModal} aria-label="add product">
            Quick Add Cart
          </SaveButton>
        </div>
        <AddCartItemModal
          data={[]}
          onUpdate={(e) => onUpdate(e)}
          open={itemDetailModal}
          onClose={closeItemDetailModal}
          scroll={"paper"}
          maxWidth={"lg"}
        ></AddCartItemModal>
      </>
    );
  }
}

export default MyCartPage;
