import { Divider, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { MyStyles } from "style/theme";
import { ErrorHandler, SearchApi } from "system/ApiService";
import { IsAgencyUser, IsEngineerUser } from "system/Helper";
import { GetInitSearchResult, LocationDataState, SearchModel, SearchResult } from "system/types";
import useLocationStateMatch from "system/useLocationStateMatch";
import { useUserState } from "system/UserContext";
import SearchListBox from "./SearchListBox";
import SearchMaterialList from "./SearchMaterialList";

interface Params {
  kind: string;
}

interface LocationState<T> extends LocationDataState<T> {
  keyword: string;
}

function SearchListPage(): ReactElement {
  const classes = MyStyles();
  const [items, setItems] = useState<SearchModel[]>([]);
  const [materials, setMaterials] = useState<SearchModel[]>([]);
  const [products, setProducts] = useState<SearchModel[]>([]);
  let { kind } = useParams<Params>();
  const history = useHistory();
  const location = useLocation<LocationState<{ keyword: "" }>>();
  const user = useUserState();
  let keyword = "";
  if (location.state !== undefined) keyword = location.state.keyword;
  const searchData = useLocationStateMatch("searchData") || { keyword: keyword, row: 5, page: 1 };
  const [data] = React.useState<SearchResult<SearchModel>>(GetInitSearchResult<SearchModel>([]));

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
      state: {
        ...(location.state || {}),
        searchData: {
          ...searchData,
          page: page,
        },
      },
    });
  };

  useEffect(() => {
    if (searchData.keyword !== "") {
      // 검색 API
      if (kind === "all") {
        // 전체 검색
        SearchApi.GetSearchList(searchData.keyword, searchData.row, searchData.page)
          .then((res) => {
            // CS 교육, Download  Data 바인딩
            setItems(res.list.filter((x) => x.table !== "Material" && x.table !== "Product"));
            // 자재 Data 바인딩
            setMaterials(res.list.filter((x) => x.table === "Material"));
            // 제품 Data 바인딩
            setProducts(res.list.filter((x) => x.table === "Product"));
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log("Error! Cannot get search item." + msg);
          });
      } else {
        // 분류별 검색
        SearchApi.GetSearchListInKind(kind, searchData.keyword, searchData.row, searchData.page)
          .then((res) => {
            if (kind === "material") {
              // 자재 Data 바인딩
              setItems([]);
              setProducts([]);
              setMaterials(res.list);
            } else if (kind === "product") {
              // 제품 data 바인딩
              setItems([]);
              setMaterials([]);
              setProducts(res.list);
            } else {
              // CS 교육, Download  Data 바인딩
              setItems(res.list);
              setProducts([]);
              setMaterials([]);
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log("Error! Cannot get search item." + msg);
          });
      }
    }
  }, [kind, searchData.row, searchData.page, searchData.keyword]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.divKeyword}>
        {searchData.keyword === "" ? (
          <Typography variant="h3">Please enter a search keyword</Typography>
        ) : (
          <Typography variant="h3">{`Result for "${searchData.keyword}"`}</Typography>
        )}
      </Grid>
      {!IsAgencyUser(user.role) && items.length !== 0 && (
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.divSearchList}>
            {items.map((x, index) => (
              <SearchListBox item={x} key={`${x.id}-${index}`}></SearchListBox>
            ))}
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-center">
            <Pagination
              count={data.totalPage}
              defaultValue={1}
              page={data.page}
              showFirstButton
              showLastButton
              color="primary"
              onChange={onPageChange}
            ></Pagination>
          </Grid>
          <br></br>
          <Divider></Divider>
        </Grid>
      )}
      {materials.length !== 0 && (
        <Grid item xs={12} className={classes.divSearchList}>
          <Typography variant="h5" style={{ marginBottom: "10px" }}>
            Spare Parts
          </Typography>
          <SearchMaterialList materials={materials} rows={materials.length}></SearchMaterialList>
        </Grid>
      )}
      {!IsEngineerUser(user.role) && products.length !== 0 && (
        <Grid item xs={12} className={classes.divSearchList}>
          <Typography variant="h5" style={{ marginBottom: "10px" }}>
            Products
          </Typography>
          <SearchMaterialList materials={products} rows={products.length}></SearchMaterialList>
        </Grid>
      )}
    </Grid>
  );
}

export default SearchListPage;
