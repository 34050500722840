import { FormControl, IconButton, MenuItem, Popover, Select } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PasswordInput from "components/Common/PasswordInput";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { Table } from "react-bootstrap";
import { IsManagerUser } from "system/Helper";
import { User } from "system/types";
import GuideRoleTable from "./GuideRoleTable";

interface TempUser extends User {
  password?: string;
}
interface ProfileDetailUserProps {
  add?: boolean;
  data: TempUser;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (role: string) => void;
}

function ProfileDetailUser({ add, data: user, onChange, onChangeRole }: ProfileDetailUserProps): ReactElement {
  const isAdd = add === undefined ? false : add;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popOver = Boolean(anchorEl);
  const popOverId = "role-popover";

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    onChangeRole(event.target.value as string);
  };

  return (
    <Table>
      <tbody>
        <tr>
          <th>ID</th>
          <td>{user.contactPersonId ? user.contactPersonId : user.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{user.contactName ? user.contactName : user.showName}</td>
        </tr>
        {!IsManagerUser(user.role) && (
          <>
            <tr>
              <th>Email</th>
              <td>{user.contactEmail}</td>
            </tr>
            <tr>
              <th>Tel</th>
              <td>{user.contactPhone}</td>
            </tr>
            <tr>
              <th>Company</th>
              <td>{user.contactFor}</td>
            </tr>
            <tr>
              <th style={{ width: "200px" }}>해외 영업관리자</th>
              <td>{user.salesName}</td>
            </tr>
            <tr>
              <th style={{ width: "200px" }}>영업관리 담당자</th>
              <td>{user.workerSalesTakerName}</td>
            </tr>
            <tr>
              <th>Currency</th>
              <td>{user.currencyCode}</td>
            </tr>
          </>
        )}
        <tr>
          <th>
            Role
            <IconButton size="small" aria-describedby={popOverId} onClick={handleClick} style={{ padding: "0px 5px" }}>
              <HelpOutlineIcon></HelpOutlineIcon>
            </IconButton>
            <Popover
              id={popOverId}
              open={popOver}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <GuideRoleTable></GuideRoleTable>
            </Popover>
          </th>
          <td>
            {IsManagerUser(user.role) ? (
              <>{user.role}</>
            ) : (
              <FormControl style={{ minWidth: 100 }}>
                <Select value={user.role || ""} name="role" onChange={onChangeSelect}>
                  <MenuItem value={"USER"}>USER</MenuItem>
                  <MenuItem value={"ENGINEER"}>ENGINEER</MenuItem>
                  <MenuItem value={"ENGINEER2"}>ENGINEER2</MenuItem>
                  <MenuItem value={"AGENCY"}>가망대리점</MenuItem>
                </Select>
              </FormControl>
            )}
          </td>
        </tr>
        {isAdd ? (
          <tr>
            <th>Password</th>
            <td>
              <PasswordInput
                value={user?.password || ""}
                name="password"
                onChange={onChange}
                fullWidth={true}
                helperText="If empty, It will not change. At least 8 characters and contain letters,numbers,symbols"
              ></PasswordInput>
            </td>
          </tr>
        ) : (
          <tr>
            <th>Password</th>
            <td>This account is already open.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ProfileDetailUser;
