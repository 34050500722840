import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";
import { Order, Shipping, UserAddress } from "system/types";
import { Table } from "react-bootstrap";
import { CancelButton, SaveButton } from "components/Button";
import SimpleSelect, { SimpleChangeEvent } from "components/custom/SimpleSelect";

interface OrderModalProps extends DialogProps {
  data: Order;
  address?: UserAddress[];
  shipping?: Shipping;
  onUpdate: (data: Order) => void;
}

function CompanyModal({ data, address, onUpdate: _onUpdate, ...dialogProps }: OrderModalProps) {
  const [state, setState] = useState(data);

  const onChangeSelect = (event: React.ChangeEvent<SimpleChangeEvent>) => {
    if (event.target.name) {
      const value = event.target.value as string;
      const addressShow = address?.filter(x => x.addressId === value)[0].address
      setState({
        ...state,
        address: addressShow || "",
        [event.target.name]: value,
      });
    }
  };

  const onClickCloseModal = () => dialogProps.onClose!({}, "escapeKeyDown");
  const onClickUpdate = () => {
    onClickCloseModal();
    _onUpdate(state);
  };

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Change Company Information</DialogTitle>
      <DialogContent>
        <Table>
          <tbody>
            <tr>
              <th>Company Name: </th>
              <td>{state.companyName.split('(')[1].split(')')}</td>
            </tr>
            <tr>
              <th style={{ color: "#a24642" }}>Address: </th>
              <td>
                <SimpleSelect
                  name="addressId"
                  value={state.addressId}
                  fullWidth
                  onChange={onChangeSelect}
                >
                  {address?.map((x) => (
                    <MenuItem value={x.addressId}>{x.address}</MenuItem>
                  ))}
                </SimpleSelect>
              </td>
            </tr>
            <tr>
              <th>Attention: </th>
              <td>{state.userName}</td>
            </tr>
            <tr>
              <th>E-mail: </th>
              <td>{state.userEmail}</td>
            </tr>
            <tr>
              <th>Tel: </th>
              <td>{state.userTel}</td>
            </tr>
          </tbody>
        </Table>
        <br />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClickCloseModal}>Cancel</CancelButton>
        <SaveButton onClick={onClickUpdate}>Update</SaveButton>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyModal;