import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@material-ui/core";

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  backgroundColor: "#e2e3e4e0",
  textAlign: "center",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  textAlign: "center",
}));

function GuideRoleTable() {
  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: "650px" }}>
        <TableHead>
          <TableRow>
            <StyledTableHead></StyledTableHead>
            <StyledTableHead>Admin</StyledTableHead>
            <StyledTableHead>Manager</StyledTableHead>
            <StyledTableHead>User</StyledTableHead>
            <StyledTableHead>Engineer</StyledTableHead>
            <StyledTableHead>Engineer2</StyledTableHead>
            <StyledTableHead>가망대리점</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableCell>공지사항 작성</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>카테고리 편집</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>Web shop 및 CS 페이지 관리</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>계정 및 오더 관리</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>Web Shop - 제품</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>Web Shop - 자재</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>Web Shop - 자재(구매 X, 가격 정보 X)</StyledTableCell>
          <StyledTableCell>-</StyledTableCell>
          <StyledTableCell>-</StyledTableCell>
          <StyledTableCell>-</StyledTableCell>
          <StyledTableCell>-</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>-</StyledTableCell>
        </TableBody>
        <TableBody>
          <StyledTableCell>CS 자료 열람</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>O</StyledTableCell>
          <StyledTableCell>X</StyledTableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GuideRoleTable;
