import { Grid, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { getDateUsingNumber } from "system/Helper";

interface CSSummaryCardProps {
  item: any;
  route: MyRoutes;
  kind: string;
}

function CSSummaryCard({ item, route, kind }: CSSummaryCardProps): ReactElement {
  const classes = MyStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.csItem}>
      <Grid item xs={8}>
        {kind === "firmware" || kind === "lookinbody" || kind === "etc" ? (
          <Typography
            onClick={() => history.push({ pathname: `${route}/${kind}/${item.id}` })}
            variant="h5"
            color="secondary"
            className={classes.contentTitle}
          >
            {item.title}
          </Typography>
        ) : (
          <Typography
            onClick={() => history.push({ pathname: `${route}/${item.id}` })}
            variant="h5"
            color="secondary"
            className={classes.contentTitle}
          >
            {item.title}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1" align="center">
          {getDateUsingNumber(item.created)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1" align="center">
          {item.views}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CSSummaryCard;
