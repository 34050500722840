import ScrollToTop from "components/Button/ScrollToTop";
import Toast from "components/custom/Toast";
import CSEducationPage from "page/CSEducation/MainPage";
import CSEtcMainPage from "page/CSEtc/CSEtcMainPage";
import CSManualMainPage from "page/CSManual/CSManualMainPage";
import CSVideoMainPage from "page/CSVideo/CSVideoMainPage";
import CartIndexPage from "page/Cart/CartIndexPage";
import CategoryEditPage from "page/Category/CategoryEditPage";
import DownloadPage from "page/Download/DownloadMainPage";
import Content from "page/Layout/Content";
import Footer from "page/Layout/Footer";
import Header from "page/Layout/Header";
import LetterMainPage from "page/LetterNotice/LetterMainPage";
import * as Login from "page/Login/MainPage";
import MainPage from "page/MainPage";
import ManageList from "page/Material/Manage/ManageList";
import MaterialPage from "page/Material/MaterialPage";
import NotFound from "page/NotFound";
import NoticePage from "page/Notice/MainPage";
import PrivacyPolicy from "page/PrivacyPolicy";
import ManageProduct from "page/Product/Manage/ManageProduct";
import ProductPage from "page/Product/ProductPage";
import SearchListPage from "page/Search/SearchListPage";
import ShopPage from "page/ShopPage";
import UserList from "page/User/Account/UserList";
import UserPage from "page/User/UserPage";
import WarrantyPage from "page/WarrantyClaim/WarrantyPage";
import { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import UserRoute from "routes/UserRoute";
import { MyStyles } from "style/theme";
import { IsAgencyUser, IsEngineer2User, IsEngineerUser, IsManagerUser } from "system/Helper";
import { useUserState } from "system/UserContext";

export enum MyRoutes {
  root = "/",
  login = "/login",
  logout = "/logout",
  shop = "/shop",
  notice = "/notice",
  manage = "/manage",
  manageItems = "/manage/items",
  shopProduct = "/shop/products",
  manageProduct = "/list/products",
  shopMaterial = "/shop/materials",
  user = "/user",
  userProfile = "/user/profile",
  userChangePassword = "/user/change-password",
  privacyPolicy = "/privacy-policy",
  account = "/account",
  cart = "/cart", //cart/{사용자} or cart/my
  cartMine = "/cart/my", //장바구니
  cartOrder = "/cart/order",
  //나의 주문내역 or user의 주문 내역
  cartOrdered = "/cart/order/details/:id?",
  //전체 주문 내역
  cartOrderedList = "/cart/order/all",
  category = "/category",
  // CS 교육
  csEducation = "/cseducation",
  csManual = "/csmanual",
  csVideo = "/csvideo",
  letterNotice = "/letternotice",
  // Download : Firmware, Lookinbody, Etc
  download = "/download",
  downloadFirmware = "/download/firmware",
  downloadLookinbody = "/download/lookinbody",
  downloadLibrary = "/download/etc",
  //etc
  csEtc = "/csetc",
  // 무상출고 페이지
  warranty = "/warranty",
  // Search : 전체 검색
  search = "/search",
  notFound = "/notfound",
}

function Routes(): ReactElement {
  return (
    <>
      <Route path={MyRoutes.login}>
        <Login.MainPage></Login.MainPage>
      </Route>
      <UserRoute Component={ContentRoute} path={MyRoutes.root}></UserRoute>
    </>
  );
}

export default Routes;

function ContentRoute(): ReactElement {
  const classes = MyStyles();
  const user = useUserState();
  const [open, setOpen] = useState<boolean>(true);
  return (
    <>
      <div className={classes.bodyWrap}>
        <Header open={open} setOpen={setOpen}></Header>
        {/* Router */}
        <Content open={open}>
          <Switch>
            <Route path={MyRoutes.root} component={MainPage} exact></Route>
            <Route path={MyRoutes.notice} component={NoticePage}></Route>
            <Route path={MyRoutes.user} component={UserPage}></Route>
            <Route path={MyRoutes.shopMaterial} component={MaterialPage}></Route>
            <Route path={`${MyRoutes.search}/:kind`} component={SearchListPage} exact></Route>
            <Route path={MyRoutes.privacyPolicy} component={PrivacyPolicy} exact></Route>
            <Route path={MyRoutes.shop} component={ShopPage} exact></Route>
            {!IsEngineer2User(user.role) && <Route path={MyRoutes.cart} component={CartIndexPage}></Route>}
            {!IsEngineer2User(user.role) && <Route path={MyRoutes.warranty} component={WarrantyPage}></Route>}
            {!IsEngineerUser(user.role) && <Route path={MyRoutes.shopProduct} component={ProductPage} exact></Route>}
            {IsManagerUser(user.role) && <Route path={MyRoutes.category} component={CategoryEditPage}></Route>}
            {IsManagerUser(user.role) && <Route path={MyRoutes.account} component={UserList}></Route>}
            {IsManagerUser(user.role) && <Route path={MyRoutes.manageProduct} component={ManageProduct}></Route>}
            {IsManagerUser(user.role) && <Route path={MyRoutes.manage} component={ManageList}></Route>}
            {!IsAgencyUser(user.role) && (
              <>
                <Route path={MyRoutes.csEducation} component={CSEducationPage}></Route>
                <Route path={MyRoutes.csManual} component={CSManualMainPage}></Route>
                <Route path={MyRoutes.csVideo} component={CSVideoMainPage}></Route>
                <Route path={MyRoutes.letterNotice} component={LetterMainPage}></Route>
                <Route path={`${MyRoutes.download}/:kind?`} component={DownloadPage}></Route>
                <Route path={MyRoutes.csEtc} component={CSEtcMainPage}></Route>
              </>
            )}

            <Route path={"*"} component={NotFound}></Route>
          </Switch>
        </Content>
        <br />
        <ScrollToTop />
        <Toast></Toast>
      </div>
      <Footer></Footer>
    </>
  );
}
