import { Grid, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import BreadcrumbsCategory from "components/BreadcrumbsCategory";
import { ActionButton, ImportantButton, SaveButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import PDFView from "components/PDFView";
import HtmlContent from "components/RichTextBox/HtmlContent";
import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { LetterNoticeApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";
import { INIT_LETTERNOTICE, LetterNotice } from "system/types";

interface Params {
  letterId: string;
}

function LetterDetailPage(): ReactElement {
  let { letterId } = useParams<Params>();
  const letterNoticeId = parseInt(letterId);
  const LoadingDispatch = useLoadingDispatch();
  const history = useHistory();
  const [data, setData] = useState<LetterNotice>(INIT_LETTERNOTICE);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const classes = MyStyles();
  const user = useUserState();
  const route = MyRoutes.letterNotice;
  const message = "Are you sure you want to delete this post?\r\n※ Recovery is not possible after deletion.";

  useEffect(() => {
    LoadingDispatch({ type: "LOADING" });
    LetterNoticeApi.GetLetterNotice(letterNoticeId)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        console.log("Error! Cannot get letternotice.");
      })
      .finally(() => LoadingDispatch({ type: "COMPLETE" }));
  }, [LoadingDispatch, letterNoticeId]);

  const onDelete = () => {
    setDeleteOpen(true);
  };

  const closeDialog = () => {
    setDeleteOpen(false);
  };

  const onDownload = () => {
    LoadingDispatch({ type: "LOADING" });
    fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const blobObjectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobObjectUrl;
        link.download = data.fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobObjectUrl);
      })
      .catch((error) => {
        console.error("Error downloading the blob:", error);
      })
      .finally(() => LoadingDispatch({ type: "COMPLETE" }));
  };
  const confirmDialog = () => {
    LetterNoticeApi.DeleteLetterNotice(data.id)
      .then(() => {
        history.replace({
          pathname: `${route}/list/${data.categoryId}`,
          state: {
            delete: true,
            isParent: false,
          },
        });
      })
      .catch(() => {
        console.log("Error! Cannot delete cs.");
      });
  };

  const onEdit = () => {
    history.push({
      pathname: `${route}/edit/${data.categoryId}/${data.id}`,
    });
  };

  if (isNaN(letterNoticeId)) {
    history.replace({
      pathname: "/",
      state: {},
    });
    return <></>;
  } else {
    const categoryStep = (data.categoryStep && data.categoryStep.split("|")) || [];
    const categoryStepId = (data.categoryStepId && data.categoryStepId.split("|")) || [];
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.locationSection}>
          <BreadcrumbsCategory
            route={route}
            kind={"learn"}
            step={categoryStep}
            stepId={categoryStepId}
          ></BreadcrumbsCategory>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{data.title}</Typography>
        </Grid>
        {data.file && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <ImportantButton variant="outlined" onClick={onDownload} startIcon={<GetAppIcon />}>
              <FileSize name={data.fileName} size={data.fileSize}></FileSize>
            </ImportantButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <HtmlContent style={{ padding: "15px", backgroundColor: "white" }} data={data.content}></HtmlContent>
        </Grid>
        <Grid item xs={12}>
          {data.pdf && <PDFView pdf={data.pdf} name={data.pdfName}></PDFView>}
        </Grid>
        {user.id === data.writerId && (
          <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between" }}>
            <SaveButton onClick={onDelete}>DELETE</SaveButton>
            <ConfirmDialog
              state={deleteOpen}
              title={"Delete Post"}
              message={message}
              onClose={closeDialog}
              onClickConfirm={confirmDialog}
            ></ConfirmDialog>
            <ActionButton variant="contained" onClick={onEdit}>
              EDIT
            </ActionButton>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default LetterDetailPage;
