import React, { ReactElement } from "react";
import Page from "components/Page";
import ChangePassword from "page/User/Profile/ChangePassword";
import Profile from "page/User/Profile/Profile";
import UserTab from "page/User/UserTab";
import { Route, Switch } from "react-router-dom";
import { MyRoutes } from "routes/Routes";

export function MainPage(): ReactElement {
  return (
    <Page>
      {/* Tab */}
      <UserTab></UserTab>
      <br />
      {/* contents */}
      <Switch>
        {/* user/profile */}
        <Route path={MyRoutes.userProfile}>
          <Route path={MyRoutes.userProfile} exact>
            <Profile></Profile>
          </Route>
        </Route>
        <Route path={MyRoutes.userChangePassword}>
          <ChangePassword></ChangePassword>
        </Route>
      </Switch>
    </Page>
  );
}

export default MainPage;
