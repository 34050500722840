import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Image from "components/Image";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { CSVideo } from "system/types";

interface SearchVideoBoxProps {
  item: CSVideo[];
  rows: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      justifyContent: "space-around",
      backgroundColor: theme.palette.background.paper,
      height: 300,
    },
    gridList: {
      height: "inherit",
      overflow: "auto",
      alignItems: "center",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  })
);

export default function SearchVideoBox({ item, rows }: SearchVideoBoxProps): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={rows === 1 ? 1 : rows} style={{ width: `${250 * rows}px` }}>
        {item.map((x, i) => {
          return (
            <div key={i} style={{ height: "250px" }}>
              <GridListTile style={{ height: "240px" }}>
                <Link to={{ pathname: `csvideo/${x.id}` }}>
                  <Image height={180} src={x.thumbnail} fallbackSrc="/images/NO_Image.png" noErrorMsg></Image>
                </Link>
                <GridListTileBar
                  title={
                    <>
                      <span>{x.title}</span>
                    </>
                  }
                  subtitle={<span>{x.categoryStep.replaceAll("|", ">")}</span>}
                />
              </GridListTile>
            </div>
          );
        })}
      </GridList>
    </div>
  );
}
