import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Image from "components/Image";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { IsEngineerUser, RemoveHtmlTag } from "system/Helper";
import { useUserState } from "system/UserContext";
import { SearchModel } from "system/types";

interface SearchMaterialListProps {
  materials: SearchModel[];
  rows: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      justifyContent: "space-around",
      backgroundColor: theme.palette.background.paper,
      height: 300,
    },
    gridList: {
      height: "inherit",
      overflow: "auto",
      alignItems: "center",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  })
);

export default function SearchMaterialList({ materials, rows }: SearchMaterialListProps): ReactElement {
  const classes = useStyles();
  const user = useUserState();

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={rows === 1 ? 1 : rows} style={{ width: `${250 * rows}px` }}>
        {materials.map((x, i) => {
          const erpcode = RemoveHtmlTag(x.content.split(" ")[0]);
          const categoryNav = (x.category || "").split("|").join(" > ");

          return (
            <div key={i} style={{ height: "250px" }}>
              <GridListTile style={{ height: "240px" }}>
                {x.table === "Product" && !IsEngineerUser(user.role) ? (
                  <Link to={{ pathname: "/shop/products/" }}>
                    <Image
                      height={180}
                      src={`/images/product/${x.file ? x.file : erpcode + ".jpg"}`}
                      fallbackSrc="/images/NO_Image.png"
                      noErrorMsg
                    ></Image>
                  </Link>
                ) : null}
                {x.table === "Material" && (
                  <Link to={{ pathname: `/shop/materials/view/${x.id}` }}>
                    <Image
                      height={180}
                      src={`/images/material/items/${x.file ? x.file : erpcode + ".jpg"}`}
                      fallbackSrc="/images/NO_Image.png"
                      noErrorMsg
                    ></Image>
                  </Link>
                )}
                <GridListTileBar
                  title={
                    <>
                      <span>{x.name}</span>
                      <br></br>
                      <span>{`(ERP : ${erpcode})`}</span>
                    </>
                  }
                  subtitle={<span>{categoryNav}</span>}
                />
              </GridListTile>
            </div>
          );
        })}
      </GridList>
    </div>
  );
}
