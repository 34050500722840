import React, { ReactElement } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Typography } from "@material-ui/core";
import { MyStyles } from "style/theme";

function EmptyCart(): ReactElement {
  const classes = MyStyles();

  return (
    <div className={classes.emptyCart}>
      <ShoppingCartIcon titleAccess="shopping cart image"></ShoppingCartIcon>
      <Typography variant="h5">Your Cart is Empty</Typography>
      <Typography variant="body1">
        Once you have added items to your shopping cart, you can check out from here.
      </Typography>
    </div>
  );
}

export default EmptyCart;
