import { Card, CardHeader, CardContent, TextField, Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { ActionButton } from "components/Button";
import TabsMenu from "components/custom/TabsMenu";
import React, { ReactElement, useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { IsManagerUser } from "system/Helper";
import { useLoadingState } from "system/LoadingContext";
import { GetInitSearchResult, OrderedList, SearchResult } from "system/types";
import useLocationSearch from "system/useLocationSearch";
import { useUserState } from "system/UserContext";

interface OrderedListSearchProps {
  companyName: string;
  orderer: string;
  onSearch: (companyName: string, orderer: string) => void;
}

interface OrderedListPageProps {
  mobile: boolean;
  now: boolean;
}

function OrderedListSearch({ onSearch, ...initState }: OrderedListSearchProps) {
  const [state, setState] = useState<{
    companyName: string;
    orderer: string;
  }>(initState);

  function onClick() {
    onSearch(state.companyName, state.orderer);
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div>
      <Card variant="outlined">
        <CardHeader title="Search"></CardHeader>
        <CardContent>
          <TextField onChange={onChange} name="companyName" label="Company Name" value={state.companyName}></TextField>
          &nbsp;
          <TextField onChange={onChange} name="orderer" label="Orderer ID" value={state.orderer}></TextField>
          <ActionButton onClick={onClick}>Search</ActionButton>
        </CardContent>
      </Card>
    </div>
  );
}

function OrderedListPage({ mobile, now }: OrderedListPageProps): ReactElement {
  const location = useLocation();
  const search = useLocationSearch();
  const user = useUserState();
  const { loading } = useLoadingState();

  const companyName = search?.get("companyName") ?? "";
  const orderer = search?.get("orderer") ?? "";
  const history = useHistory();
  const [data, setData] = useState<SearchResult<OrderedList>>(GetInitSearchResult<OrderedList>([]));

  const onLoad = useCallback(() => {
    if (IsManagerUser(user.role)) {
      OrderApi.GetOrderedList(companyName, orderer, 10, data.page, now)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Cannot get order list.");
        });
    } else {
      if (user.contactFor === "" || user.contactPersonId === "") {
        alert("Your session has expired or the orderer information is missing. Please refresh the page and try again.");
        return;
      }
      OrderApi.GetOrderedList(user.contactFor, user.contactPersonId, 10, data.page, false)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Cannot get order list.");
        });
    }
  }, [companyName, data.page, orderer, user, now]);

  const onStatus = useCallback(() => {
    if (data.list[0]) {
      OrderApi.OrderStatus(data.list[0].id)
        .then(() => {})
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error! Cannot get Order Status.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLoad();
    onStatus();
  }, [onLoad, onStatus]);

  function onSearch(companyName: string, orderer: string) {
    let nextSearch: string[] = [];

    if (companyName.trim() > "") {
      nextSearch.push("companyName=" + companyName);
    }
    if (orderer.trim() > "") {
      nextSearch.push("orderer=" + orderer);
    }

    history.push({
      ...location,
      search: `?${nextSearch.join("&")}`,
    });
  }

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      page: page,
    });
  };

  return (
    <React.Fragment>
      {IsManagerUser(user.role) ? (
        <div>
          <TabsMenu
            matchPath={`${MyRoutes.cart}/:match/:subMatch?`}
            urlHeader={["order/received", "order/details"]}
            onChangeArr={[
              {
                state: {
                  userId: user.id,
                },
              },
              null,
            ]}
            titleHeader={["Received Orders", "Previous Orders"]}
          ></TabsMenu>
          <br />
          <OrderedListSearch companyName={companyName} orderer={orderer} onSearch={onSearch}></OrderedListSearch>
        </div>
      ) : null}
      <br />
      {/* 실 주문 내용의 영역*/}
      <div className="d-flex align-items-center flex-column">
        <div className="text-end w-100">Total : {data.count}</div>
        <Table striped style={{ fontSize: mobile === true ? "12px" : "16px" }}>
          <thead>
            <tr>
              <th>PO No.</th>
              <th>Date of Order</th>
              <th>Company Name</th>
              <th>Orderer(ID)</th>
              <th style={{ paddingLeft: "40px" }}>Total Price</th>
              <th>PO Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* if loading */}
            {loading && (
              <tr>
                <td colSpan={6}>There is No data.</td>
              </tr>
            )}
            {/* if not loading */}
            {!loading && data.list.length === 0 ? (
              <tr>
                <td colSpan={6}>There is No data.</td>
              </tr>
            ) : (
              data.list.map((x) => (
                <tr key={x.id}>
                  <td>{x.id}</td>
                  <td>{x.orderedDate.split("T")[0]}</td>
                  <td>{x.companyName.split("(")[1].split(")")}</td>
                  <td>
                    {x.userName} {"(" + x.userId + ")"}
                  </td>
                  <td style={{ paddingLeft: "40px" }}>
                    {x.currencyCode}{" "}
                    {x.totalPrice
                      .toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>{x.status}</td>
                  <td className="text-center">
                    <Button
                      variant="outlined"
                      component={Link}
                      to={{
                        pathname: `/cart/order/details/${x.id}`,
                      }}
                      style={{ textTransform: "none" }}
                    >
                      Show Detail
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <br />
        <Pagination
          color="primary"
          count={data.totalPage}
          defaultValue={1}
          page={data.page}
          showFirstButton
          showLastButton
          onChange={onPageChange}
        ></Pagination>
      </div>
    </React.Fragment>
  );
}

export default OrderedListPage;
