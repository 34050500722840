import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { GreyButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import Editor from "components/RichTextBox/Editor";
import Select2Category from "components/custom/Select2Category";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { CSVideoApi, ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useToastDispatch } from "system/ToastContext";
import { CSVideo, INIT_CSVIDEO } from "system/types";

function CSVideoEditPage(): ReactElement {
  const history = useHistory();
  const ref = React.useRef<Editor>(null);
  const ToastDispatch = useToastDispatch();
  const { id, categoryId } = useParams<{ id: string; categoryId: string }>();
  const classes = MyStyles();
  const Loading = useLoadingDispatch();

  const [file, setFile] = useState<File>();
  const [thumbnail, setThumbnail] = useState<File>();
  const [video, setVideo] = useState<File>();
  const [ThumbnailDeleteOpen, setThumbnailDeleteOpen] = useState<boolean>(false);
  const [fileDeleteOpen, setFileDeleteOpen] = useState<boolean>(false);
  const [videoDeleteOpen, setVideoDeleteOpen] = useState<boolean>(false);

  const [csVideo, setCsVideo] = useState<CSVideo>(INIT_CSVIDEO);
  const csvideoId = isNaN(parseInt(id)) ? 0 : parseInt(id);
  const isEdit = csvideoId !== 0 ? true : false;

  useEffect(() => {
    if (isEdit) {
      Loading({ type: "LOADING" });
      CSVideoApi.GetCSVideo(csvideoId)
        .then((res) => {
          setCsVideo(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot get video." + msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  }, [csvideoId, isEdit, Loading]);

  function confirmError(title: string, content: string | null | undefined) {
    let errorMsg = undefined;
    if (csVideo.categoryId === 0) {
      errorMsg = "Please select full Category";
    } else if (!title) {
      errorMsg = "There is no title";
    } else if (!content) {
      errorMsg = "There is no contents to save";
    }
    return errorMsg;
  }

  const onChange = (event: any) => {
    setCsVideo({
      ...csVideo,
      [event.target.name]: event.target.value,
    });
  };

  function setFormData() {
    const formData = new FormData();

    if (file !== undefined && csVideo.file !== undefined) {
      formData.append("formFile", file);
    }
    if (video !== undefined && csVideo.video !== undefined) {
      formData.append("formVideo", video);
    }
    if (thumbnail !== undefined && csVideo.thumbnail !== undefined) {
      formData.append("formThumbnail", thumbnail);
    }
    formData.append("title", csVideo.title);
    formData.append("id", csVideo.id + "");
    formData.append("kind", "csvideo");
    formData.append("categoryId", csVideo.categoryId + "");
    formData.append("file", csVideo.file || "");
    formData.append("fileName", csVideo.fileName || "");
    formData.append("video", csVideo.video || "");
    formData.append("videoName", csVideo.videoName || "");
    formData.append("thumbnail", csVideo.thumbnail || "");
    formData.append("thumbnailName", csVideo.thumbnailName || "");
    return formData;
  }

  const onSave = () => {
    let errorMsg: string | undefined = undefined;
    const title = csVideo.title;
    const value: string | null | undefined = ref.current?.getContent();

    if (ref.current) {
      errorMsg = confirmError(title, value);
    } else {
      errorMsg = "Somthing wrong. Please Reload this page.";
    }

    if (errorMsg) {
      ToastDispatch({ type: "error", message: errorMsg });
    } else if (value) {
      // 파일 업로드
      const formData = setFormData();
      formData.append("content", value);

      Loading({ type: "LOADING" });
      CSVideoApi.UpsertCSVideo(formData)
        .then((res) => {
          history.replace({
            pathname: `${MyRoutes.csVideo}/${res.id}`,
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert("Error! Cannot save video." + msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const saveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setCsVideo({
        ...csVideo,
        file: event.target.files[0].name,
      });
    }
  };

  const saveThumbnail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setThumbnail(event.target.files[0]);
      setCsVideo({
        ...csVideo,
        thumbnail: event.target.files[0].name,
      });
    }
  };

  const saveVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setVideo(event.target.files[0]);
      setCsVideo({
        ...csVideo,
        video: event.target.files[0].name,
      });
    }
  };

  const onThumbnailDelete = () => {
    setThumbnailDeleteOpen(true);
  };

  const onFileDelete = () => {
    setFileDeleteOpen(true);
  };

  const onVideoDelete = () => {
    setVideoDeleteOpen(true);
  };

  const closeDialog = () => {
    setThumbnailDeleteOpen(false);
    setFileDeleteOpen(false);
    setVideoDeleteOpen(false);
  };

  const confirmDialogFile = () => {
    setCsVideo({ ...csVideo, fileName: "", file: "", fileSize: 0 });
  };

  const confirmDialogThumbnail = () => {
    setCsVideo({ ...csVideo, thumbnailName: "", thumbnail: "", thumbnailSize: 0 });
  };

  const confirmDialogVideo = () => {
    setCsVideo({ ...csVideo, videoName: "", video: "", videoSize: 0 });
  };

  const onView = () => {
    if (isEdit) {
      history.push({
        pathname: `${MyRoutes.csVideo}/${csVideo.id}`,
      });
    } else {
      history.push({
        pathname: `${MyRoutes.csVideo}/list/${categoryId}`,
      });
    }
  };

  const onChangeCategory = (id: number, index?: number) => {
    if (index === 2) {
      setCsVideo({
        ...csVideo,
        categoryId: id,
      });
    } else {
      setCsVideo({
        ...csVideo,
        categoryId: 0,
      });
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6">Category</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10} style={{ paddingBottom: "20px" }}>
        <Select2Category onChange={onChangeCategory} kind={"video"} value={csVideo.categoryId}></Select2Category>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6" className="text-left">
          Title
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <TextField value={csVideo.title} name="title" onChange={onChange} fullWidth></TextField>
      </Grid>
      <Grid item xs={12} className={classes.marginTop10}>
        <Editor content={csVideo.content} ref={ref}></Editor>
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          Thumbnail
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {csVideo.thumbnail && csVideo.thumbnailName ? (
          <>
            <FileSize name={csVideo.thumbnailName} size={csVideo.thumbnailSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onThumbnailDelete} size="small">
              Thumbnail DELETE
            </GreyButton>
            <ConfirmDialog
              state={ThumbnailDeleteOpen}
              title={"DELETE Thumbnail FILE"}
              message={"Click the save button to save the changes."}
              onClose={closeDialog}
              onClickConfirm={confirmDialogThumbnail}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={saveThumbnail} className={classes.fileInput} accept="image/*"></input>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          Video
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {csVideo.videoName && csVideo.video ? (
          <>
            <FileSize name={csVideo.videoName} size={csVideo.videoSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onVideoDelete} size="small">
              Video DELETE
            </GreyButton>
            <ConfirmDialog
              state={videoDeleteOpen}
              title={"DELETE Video FILE"}
              message={"Click the save button to save the changes."}
              onClose={closeDialog}
              onClickConfirm={confirmDialogVideo}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={saveVideo} className={classes.fileInput} accept="video/*"></input>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          File
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {csVideo.file && csVideo.fileName ? (
          <>
            <FileSize name={csVideo.fileName} size={csVideo.fileSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onFileDelete} size="small">
              FILE DELETE
            </GreyButton>
            <ConfirmDialog
              state={fileDeleteOpen}
              title={"DELETE FILE"}
              message={"Click the save button to save the changes."}
              onClose={closeDialog}
              onClickConfirm={confirmDialogFile}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={saveFile} className={classes.fileInput}></input>
        )}
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onView}>
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onSave} variant="contained" color="secondary">
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CSVideoEditPage;
