import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListIcon from "@material-ui/icons/List";
import { ImportantButton } from "components/Button";
import HtmlContent from "components/RichTextBox/HtmlContent";
import fileDownload from "js-file-download";
import React, { ReactElement, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, FileApi, NoticeApi } from "system/ApiService";
import { getDateUsingNumber } from "system/Helper";
import { useToastDispatch } from "system/ToastContext";
import { useUserState } from "system/UserContext";
import { INIT_NOTICE, Notice, ObjType } from "system/types";

interface Params {
  id: string;
}

function NoticeView(): ReactElement {
  const { id } = useParams<Params>();
  const noticeId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<Notice>(INIT_NOTICE);
  const user = useUserState();

  const ToastDispatch = useToastDispatch();

  React.useEffect(() => {
    NoticeApi.GetNotice(noticeId)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get notice." + msg);
      });
  }, [noticeId]);

  function _ToEdit() {
    history.push({
      pathname: `${MyRoutes.notice}/edit/${noticeId}`,
    });
  }

  const onPinned = () => {
    if (data !== INIT_NOTICE) {
      let nextPin = data.pinned ? false : true;

      const form = new FormData();
      const obj: ObjType = { ...data, pinned: nextPin };
      const keys = Object.keys(obj);
      for (let key of keys) {
        form.append(key, obj[key]);
      }

      NoticeApi.UpsertNotice(form)
        .then((data) => {
          ToastDispatch({ type: "success", message: "This Notice successfully changed" });
          setData({ ...data, pinned: nextPin });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot update notice." + msg);
        });
    } else {
      ToastDispatch({ type: "error", message: "Wrong Approach" });
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to DELETE this notice?")) {
      NoticeApi.DeleteNotice(noticeId)
        .then((res) => {
          history.replace({
            pathname: "/notice",
            state: {
              delete: true,
            },
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot delete notice." + msg);
        });
    }
  };

  const onDownload = () => {
    if (data.fileName) {
      let contentType: string = "application/octet-stream";
      FileApi.DownloadFile(data.fileName, contentType)
        .then((res) => {
          fileDownload(res, data.fileName!);
        })
        .catch((error) => {
          console.log("Error! Cannot download notice file.");
        });
    } else {
      alert("INVALID REQUEST");
    }
  };

  if (isNaN(noticeId)) {
    history.replace({
      pathname: "/",
      state: {},
    });

    return <></>;
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h5">
            <IconButton aria-label="list" component={RouterLink} to={MyRoutes.notice} className="printNone">
              <ListIcon />
            </IconButton>
            {data.title}
          </Typography>
          <Typography variant="body2">
            {data.writer} {getDateUsingNumber(data.created)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1} style={{ padding: "15px", marginBottom: "10px", minHeight: "200px" }} variant="outlined">
            {data.fileName && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <ImportantButton variant="outlined" onClick={onDownload} startIcon={<GetAppIcon />}>
                  {data.fileName}
                </ImportantButton>
              </Grid>
            )}
            <HtmlContent data={data.content}></HtmlContent>
          </Paper>
        </Grid>
        {data.writerId === user.id && (
          <Grid item xs={12}>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Button variant="contained" color="secondary" onClick={onDelete}>
                DELETE
              </Button>
            </div>
            <div style={{ width: "50%", display: "inline-block" }}>
              <div style={{ textAlign: "right" }}>
                <Button variant="contained" onClick={onPinned}>
                  {data.pinned ? "Unpinned" : "Pinned"}
                </Button>
                &nbsp;
                <Button variant="contained" color="primary" onClick={_ToEdit}>
                  EDIT
                </Button>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default NoticeView;
