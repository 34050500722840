import { Breadcrumbs, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { MyRoutes } from "routes/Routes";

interface BreadcrumbsCategoryProps {
  kind: string;
  route: MyRoutes;
  step: string[];
  stepId: string[];
}

function BreadcrumbsCategory({ route, kind, step, stepId }: BreadcrumbsCategoryProps): ReactElement {
  const materialPathname =  `${route}/`;
  const learnPathname = `${route}/list/`;
  const downloadPathname = `${route}/${kind}/list/`;
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {step.map((x, index) => {
          return kind === "material" ? (
            <Link
              key={`Link-${x}-${index}`}
              color="inherit"
              to={{ pathname: `${materialPathname}${stepId[index]}`, state: { isParent: true, depth: index + 1 } }}
            >
              <Typography display="inline" variant="body2" className="title">
                {x}
              </Typography>
            </Link>
          ) : kind === "learn" ? (
            <Link
              key={`Link-${x}-${index}`}
              color="inherit"
              to={{ pathname: `${learnPathname}${stepId[index]}`, state: { isParent: true, depth: index + 1 } }}
            >
              <Typography display="inline" variant="body2" color="textPrimary">
                {x}
              </Typography>
            </Link>
          ) : (
            <Link
              key={`Link-${x}-${index}`}
              color="inherit"
              to={{ pathname: `${downloadPathname}${stepId[index]}`, state: { isParent: true, depth: index + 1 } }}
            >
              <Typography display="inline" variant="body2" color="textPrimary">
                {x}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
}

export default BreadcrumbsCategory;
