import { Button, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CSSummaryCard from "components/Common/CSSummaryCard";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { CSEtcApi, ErrorHandler } from "system/ApiService";
import { IsAdminUser } from "system/Helper";
import { useUserState } from "system/UserContext";
import { CSEtcItem, GetInitSearchResult, SearchResult } from "system/types";
interface Params {
  categoryId: string;
}

interface CSEtcListPageParams {
  route: MyRoutes;
}

function CSEtcListPage({ route }: CSEtcListPageParams): ReactElement {
  const classes = MyStyles();
  let { categoryId } = useParams<Params>();
  const id = isNaN(parseInt(categoryId)) ? 0 : parseInt(categoryId);
  const user = useUserState();
  const isAdmin = IsAdminUser(user.role);
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<SearchResult<CSEtcItem>>(GetInitSearchResult<CSEtcItem>([]));
  const [page, setPage] = useState<number>(data.page);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  };

  useEffect(() => {
    CSEtcApi.GetCSEtcListByCategoryId(id, 10, page)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [id, page]);

  const onEdit = () => {
    history.push({
      pathname: `${route}/edit/${categoryId}`,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="text-right">
        {isAdmin && (
          <Button onClick={onEdit} variant="contained" color="secondary">
            Write
          </Button>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.csHeader}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h6" align="center">
              Title
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="center">
              Date
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="center">
              Views
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {data.list.length === 0 && (
        <Grid item xs={12} className="text-center mt-3">
          <Typography variant="subtitle1">No Posts found</Typography>
        </Grid>
      )}
      {data.list.map((x, index) => (
        <CSSummaryCard key={`cses${x.id}-${index}`} item={x} route={route} kind={"csetc"}></CSSummaryCard>
      ))}
      <Grid item xs={12} className="d-flex justify-content-center">
        <Pagination
          color="primary"
          count={data.totalPage}
          defaultValue={1}
          page={page}
          showFirstButton
          showLastButton
          onChange={onPageChange}
        ></Pagination>
      </Grid>
    </Grid>
  );
}

export default CSEtcListPage;
