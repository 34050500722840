import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Route, useParams, Switch } from "react-router";
import { MyRoutes } from "routes/Routes";
import { CategoryApi } from "system/ApiService";
import { Category } from "system/types";
import DownloadListPage from "page/Download/DownloadListPage";
import DownloadDetailPage from "page/Download/DownloadDetailPage";
import DownloadEditPage from "page/Download/DownloadEditPage";
import ListLayer from "components/ListLayer";
import NotFound from "page/NotFound";

export interface Params {
  kind: string;
}

function MainPage(): ReactElement {
  const [list, setList] = useState<Category[]>([]);
  let { kind } = useParams<Params>();

  useEffect(() => {
    // 다운로드 API
    CategoryApi.GetAllCategories(kind)
      .then((res) => {
        setList(res);
      })
      .catch((error) => {
        console.log("Error! Cannot get category.");
      });
  }, [kind]);

  const makePathName = useCallback(
    (data: Category, depth?: number) => {
      return {
        pathname: `${MyRoutes.download}/${kind}/list/${data.id}`,
      };
    },
    [kind]
  );

  return (
    <>
      {kind ? (
        <ListLayer
          makePathName={makePathName}
          title={kind.toUpperCase()}
          list={list}
          route={MyRoutes.download}
          kind={kind}
        >
          <Switch>
            <Route path={`${MyRoutes.download}/:kind/list/:categoryId`} exact>
              <DownloadListPage route={MyRoutes.download} list={list}></DownloadListPage>
            </Route>
            <Route path={`${MyRoutes.download}/:kind/edit/:categoryId?/:id?`}>
              <DownloadEditPage route={MyRoutes.download} list={list}></DownloadEditPage>
            </Route>
            <Route path={`${MyRoutes.download}/:kind/:learnId`} exact>
              <DownloadDetailPage></DownloadDetailPage>
            </Route>
          </Switch>
        </ListLayer>
      ) : (
        <NotFound></NotFound>
      )}
    </>
  );
}

export default MainPage;
