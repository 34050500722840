import React, { ReactElement } from "react";
import { useUserState } from "system/UserContext";

interface Props {
  children: React.ReactNode;
}
// admin은 최고 권위자로 모든 업무 수행가능함
function AdminComponent({ children }: Props): ReactElement {
  const user = useUserState();
  if (user.role.includes("ADMIN")) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

export default AdminComponent;
