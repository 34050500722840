import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { MyStyles } from "style/theme";
import React, { ReactElement } from "react";
import { CancelButton, SaveButton } from "components/Button";

interface ConfirmDialogProps {
  state: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onClickConfirm: () => void;
}

function ConfirmDialog({ state, title, message, onClose, onClickConfirm }: ConfirmDialogProps): ReactElement {
  const classes = MyStyles();
  return (
    <div>
      <Dialog open={state} maxWidth={"sm"} fullWidth={true} onClose={onClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={classes.preline}>{message}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} color="primary">
            Cancel
          </CancelButton>
          <SaveButton onClick={onClickConfirm} autoFocus>
            Confirm
          </SaveButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
