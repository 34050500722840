import { Button, ButtonProps, createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import React, { ReactElement } from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";

interface Styles {
  variant: string;
  children: React.ReactNode;
  [key: string]: any;
}

interface SizeMapping {
  contained: string;
  [key: string]: any;
}

export interface ButtonStyles extends WithStyles<typeof styles> {
  variant: string;
}

// Like https://github.com/brunobertolini/styled-by
export const styledBy = (property: string, mapping: SizeMapping) => (props: Styles) => mapping[props[property]];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: styledBy("variant", {
        contained: teal[500],
        outlined: "#fff",
      }),
      color: styledBy("variant", {
        contained: theme.palette.getContrastText(teal[500]),
        outlined: teal[500],
      }),
      padding: "6px 16px",
      boxShadow: styledBy("variant", {
        contained:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        outlined: "0px 0px 0px 0px",
      }),
      "&:hover": {
        backgroundColor: styledBy("variant", {
          contained: teal[500],
          outlined: fade(teal[500], 0.04),
        }),
      },
      fontWeight: 500,
      border: styledBy("variant", {
        contained: "0",
        outlined: `1px solid ${teal[500]}`,
      }),
    },
  });

const StyledButton = withStyles(styles)(Button);

function ImportantButton(props: ButtonProps): ReactElement {
  return (
    <StyledButton variant={props.variant || "contained"} {...props}>
      {props.children}
    </StyledButton>
  );
}

export default ImportantButton;
