import { createContext, Dispatch, useContext, useReducer } from "react";

type QtyState = {
  qty: number;
};


type Action = { qty: number };
type QtyDispatch = Dispatch<Action>;

const QtyStateContext = createContext<QtyState | undefined>(undefined);
const QtyDispatchContext = createContext<QtyDispatch | undefined>(undefined);

function QtyReducer(state: QtyState, action: Action): QtyState {
  return {
    qty: action.qty
  };
}

export function QtyContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(QtyReducer, {
    qty: 0
  });

  return (
    <QtyDispatchContext.Provider value={dispatch}>
      <QtyStateContext.Provider value={state}>{children}</QtyStateContext.Provider>
      {state.qty}
    </QtyDispatchContext.Provider>
  );
}

//custom hook
export function useQtyState() {
  const state = useContext(QtyStateContext);
  if (!state) throw new Error("QtyStateProvider not found")
  return state;
}

export function useQtyDispatch() {
  const dispatch = useContext(QtyDispatchContext);
  if (!dispatch) throw new Error("QtyDispatchProvider not found");
  return dispatch;
}
