import { Grid, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { MyStyles } from "style/theme";
import { SearchModel } from "system/types";

interface SearchListBoxProps {
  item: SearchModel;
}

function SearchListBox({ item }: SearchListBoxProps): ReactElement {
  const classes = MyStyles();

  return (
    <Grid container className={classes.csItem}>
      <Grid item xs={12}>
        <Typography variant="overline">{item.category.replaceAll("|", " > ")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link
          to={{
            pathname:
              item.table.toLowerCase() === "cseducation"
                ? `/cseducation/${item.id}`
                : item.table.toLowerCase() === "download"
                ? `/download/${item.category.split("|")[0]}/${item.id}`
                : item.table.toLowerCase() === "csmanual"
                ? `/csmanual/${item.id}`
                : item.table.toLowerCase() === "csvideo"
                ? `/csvideo/${item.id}`
                : item.table.toLowerCase() === "letternotice"
                ? `/letternotice/${item.id}`
                : item.table.toLowerCase() === "csetc"
                ? `/csetc/${item.id}`
                : `/notice/view/${item.id}`,
          }}
        >
          <Typography variant="h5" color="secondary" className={classes.contentTitle}>
            {item.name}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">
          <div
            dangerouslySetInnerHTML={{
              __html: item.content && item.content.length > 250 ? item.content.substr(0, 250) + "..." : item.content,
            }}
          ></div>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SearchListBox;
