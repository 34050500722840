import React, { ReactElement } from "react";
import { useUserState } from "system/UserContext";
import { MyRoutes } from "routes/Routes";
import TabsMenu from "components/custom/TabsMenu";

function UserTab(): ReactElement {
  const user = useUserState();
  const urlHeader = ["profile", "change-password"];
  const title = ["profile", "change password"];

  const onChangeArr = [{ userId: user.id }, undefined];

  return (
    <TabsMenu
      titleHeader={title}
      urlHeader={urlHeader}
      matchPath={`${MyRoutes.user}/:match`}
      onChangeArr={onChangeArr}
    ></TabsMenu>
  );
}

export default UserTab;
