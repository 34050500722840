import { createStyles, fade, ListItem } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = () =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: fade("#c51f1f", 0.04),
      },
    },
    selected: {
      backgroundColor: fade("#c51f1f", 0.12) + "!important",
      "&:hover": {
        backgroundColor: fade("#c51f1f", 0.12),
      },
    },
  });

const ListItemColored = withStyles(styles)(ListItem);

export default ListItemColored;
