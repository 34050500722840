import { createMuiTheme, createStyles, makeStyles, Theme, fade } from "@material-ui/core";
import { blue, blueGrey, grey, red, yellow } from "@material-ui/core/colors";
import { koKR } from "@material-ui/core/locale";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const theme = createMuiTheme(
  {
    typography: {
      h1: {
        fontSize: "3.8rem",
      },
      h2: {
        fontSize: "3.3rem",
      },
      h3: {
        fontSize: "2.5rem",
      },
      h4: {
        fontSize: "2.0rem",
      },
      h5: {
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h6: {
        fontSize: "1.2rem",
      },
    },
    palette: {
      primary: {
        // light: "#7b8589",
        // main: "#b0b3c5",
        // dark: "#bfcbd0",
        // contrastText: "#000",
        light: "#666f73",
        main: "#404b50",
        dark: "#2c3438",
        contrastText: "#fff",
      },
      secondary: {
        light: "#a1494e",
        main: "#8a1c22",
        dark: "#601317",
        contrastText: "#fff",
      },
    },
  },
  koKR
);

export const MyStyles = makeStyles((theme: Theme) =>
  createStyles({
    // header
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "20px",
    },
    navProfile: {
      marginRight: "10px",
    },
    // content
    contentWrapper: {
      minWidth: "1000px",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 220,
    },
    contentShift: {
      minWidth: "1000px",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 0,
    },
    contentBody: {
      width: "1680px",
      "@media (max-width: 1680px)": {
        width: "1440px",
      },
      "@media (max-width: 1440px)": {
        width: "1380px",
      },
      "@media (max-width: 1380px)": {
        width: "1280px",
      },
      "@media (max-width: 1279px)": {
        width: "1152px",
      },
    },
    // footer
    footer: {
      height: "80px",
      width: "100%",
      padding: "10px",
      position: "absolute",
      marginTop: theme.spacing(3),
      textAlign: "center",
      zIndex: -1,
    },
    bodyWrap: {
      minHeight: "100%",
      position: "relative",
      marginTop: "60px",
      padding: "2px",
    },
    progressDiv: {
      top: "50%",
      left: "50%",
      position: "absolute",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    //Divider
    blackHr: {
      backgroundColor: "#000",
    },
    goodsButton: {
      "& button": {
        width: "100%",
        height: "100%",
      },
      height: "60px",
    },
    goods: {
      lineHeight: 1.8,
      "& dt": {
        display: "table-cell",
        padding: "10px 5px",
        width: "25%",
        textAlign: "left",
        verticalAlign: "middle",
        fontWeight: "normal",
        color: grey[700],
        fontSize: "1.0rem",
      },
      "& dd": {
        display: "table-cell",
        textAlign: "left",
        fontSize: "1.03rem",
        verticalAlign: "middle",
        padding: "10px 5px",
      },
      "& dl": {
        margin: 0,
        display: "table",
        width: "100%",
        borderBottom: `1px solid ${grey[500]}`,
      },
      "& dl:first-child": {
        borderTop: `2px solid ${grey[600]}`,
      },
      "& dl:last-child": {
        borderBottom: `2px solid ${grey[600]}`,
      },
      "& .erpcode": {
        color: blue[500],
      },
    },
    goodsPrice: {
      color: "#ff0000",
      fontWeight: 600,

      "& .price": {
        fontSize: "xx-large",
      },
      "& .currency": {
        fontSize: "large",
      },
    },
    goodsQuantity: {
      width: "80px",
    },
    goodsQuantityMobile: {
      width: "50px",
    },
    endOfSaleTr: {
      opacity: 0.3,
    },
    warning: {
      color: "#ff6f00",
      fontSize: "inherit",
      marginRight: "4px",
    },
    shopBox: {
      cursor: "pointer",
      width: "300px",
      height: "200px",
      display: "inline-flex",
      margin: theme.spacing(2),
      textAlign: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#a24642",
    },
    //제품 주문용 div
    productBox: {
      width: "200px",
      height: "200px",
      backgroundColor: "#a24642",
      //backgroundColor: "#f1f1f1",
      color: "#fff",
      display: "inline-flex",
      margin: theme.spacing(2),
      textAlign: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontFamily: "Serif",
      fontSize: "1.5rem",
    },
    //CS자재 주문에서 카테고리 이미지(InBody370 등) 그림 크기
    imgDiv: {
      display: "inline",
      width: "200px",
      height: "300px",
      margin: theme.spacing(2),
      cursor: "pointer",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    materialBox: {
      width: "300px",
      display: "inline-block",
      margin: theme.spacing(1),
    },
    materialBoxMobile: {
      width: "180px",
      fontSize: "11px",
      display: "inline-block",
      margin: theme.spacing(1),
    },
    TopListLayer: {
      width: "100%",
    },
    ListLayer: {
      width: "100%",
      "& .LeftList": {
        width: "200px",
        display: "inline-block",
        verticalAlign: "top",
        "&.LeftList-inner": {},
      },
      "& .content": {
        paddingLeft: "20px",
        width: "calc(100% - 200px)",
        display: "inline-block",
      },
    },
    categoryMenu: {
      width: "100%",
    },

    //emptyCart
    emptyCart: {
      color: grey[700],
      textAlign: "center",
      "& svg": {
        fontSize: "10rem",
        color: blueGrey[100],
      },
    },
    // 미니 버튼. ex) 카테고리 edit 버튼 등
    miniButton: {
      minWidth: "25px",
      height: "15px",
      fontSize: "10px",
    },
    categoryListWrapper: {
      [theme.breakpoints.up("md")]: {
        minHeight: "500px",
      },
    },
    // 카테고리 수정 list
    categoryList: {
      position: "relative",
      overflow: "auto",
    },
    categoryItemText: {
      width: "220px",
    },
    categoryItem: {
      padding: "3px 5px",
      borderTop: "1px solid #a2a2a2",
      borderLeft: "1px solid #a2a2a2",
      borderRight: "1px solid #a2a2a2",
    },
    nestedCategoryItem: {
      height: "30px",
      paddingLeft: theme.spacing(6),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    // Material toggle Button
    toggle: {
      margin: "1px",
      "&:hover": {
        backgroundColor: fade("#c51f1f", 0.12),
      },
    },
    // Cs 교육 리스트 아이템
    csItem: {
      padding: "10px 20px 10px 20px",
      marginBottom: "10px",
      borderBottom: "1px solid #dee2e6",
    },
    csHeader: {
      padding: "10px",
      backgroundColor: "#efefef",
      borderTop: "2px solid #dee2e6",
      borderBottom: "2px solid #dee2e6",
      textAlign: "center",
    },
    contentTitle: {
      textAlign: "left",
      "&:hover": {
        cursor: "pointer",
      },
    },
    // css 직접
    marginTop10: {
      marginTop: "10px",
    },
    marginBottom10: {
      marginBottom: "10px",
    },
    leftMenuItem: {
      color: "black",
    },
    leftMenuSubItem: {
      marginLeft: "15px",
    },
    fileInput: {
      marginTop: "10px",
      width: "100%",
    },
    leftMenuButton: {
      color: "#007bff",
      padding: "6px",
    },
    preline: {
      whiteSpace: "pre-line",
    },
    fileLink: {
      width: "100%",
      display: "inline-block",
      marginTop: "10px",
      marginBottom: "10px",
    },
    btnFileDown: {
      color: "white",
      backgroundColor: "#1976d2",
    },
    marginAuto: {
      margin: "auto",
    },
    txtSearch: {
      width: "400px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    CategoryBox: {
      backgroundColor: "#8a1c22",
    },
    locationSection: {
      height: "27px",
      marginBottom: "20px",
      paddingBottom: "5px",
      "& .title": {
        color: "#aaaaaa",
      },
      "& span.depth": {
        fontFamily: "serif",
        color: "#909090",
        padding: "0px 5px",
      },
    },
    csContent: {
      border: "1px solid lightgrey",
      padding: "10px",
    },
    //uploadFile.tsx
    warningRow: {
      backgroundColor: yellow[300],
      "&:hover": {
        backgroundColor: yellow[500] + " !important",
        color: "#000 !important",
      },
    },
    errorRow: {
      backgroundColor: red[300],
      "&:hover": {
        backgroundColor: "#e35959 !important",
      },
    },
    borderLeft: {
      "&tr, th:first-child": {
        borderRight: "1px solid #dee2e6",
      },
      "&tr,td:first-child": {
        borderRight: "1px solid #dee2e6",
      },
    },
    textFieldMargin: {
      width: "400px",
      "& .MuiTextField-root": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      //nth-child(1) 이 null 이라서.. odd로 찾아야 한다.
      "& .MuiTextField-root:nth-child(odd)": {
        marginLeft: theme.spacing(2),
      },
    },
    //end uploadFile.tsx
    divKeyword: {
      background: "#efefef",
      minHeight: "150px",
      padding: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    divSearchList: {
      marginTop: "50px",
      marginBottom: "50px",
      [theme.breakpoints.up("md")]: {
        padding: "0px 100px",
      },
    },
    paperMain: {
      padding: "20px",
      marginTop: "20px",
    },
  })
);
