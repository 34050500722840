import { Divider, Grid, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import BreadcrumbsCategory from "components/BreadcrumbsCategory";
import { ActionButton, ImportantButton, SaveButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import PDFView from "components/PDFView";
import HtmlContent from "components/RichTextBox/HtmlContent";
import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { DownloadApi, ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";
import { Download, INIT_DOWNLOAD } from "system/types";

interface Params {
  learnId: string;
}

function DownloadDetailPage(): ReactElement {
  let { learnId } = useParams<Params>();
  const downloadId = parseInt(learnId);
  const LoadingDispatch = useLoadingDispatch();
  const history = useHistory();
  const [data, setData] = useState<Download>(INIT_DOWNLOAD);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const classes = MyStyles();
  const user = useUserState();
  const route = MyRoutes.download;
  const message = "Are you sure you want to delete this post?\r\n※ Recovery is not possible after deletion.";

  useEffect(() => {
    LoadingDispatch({ type: "LOADING" });
    DownloadApi.GetDownload(downloadId)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get download." + msg);
      })
      .finally(() => LoadingDispatch({ type: "COMPLETE" }));
  }, [LoadingDispatch, downloadId]);

  const onDelete = () => {
    setDeleteOpen(true);
  };

  const closeDialog = () => {
    setDeleteOpen(false);
  };

  const onDownload = () => {
    LoadingDispatch({ type: "LOADING" });
    fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const blobObjectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobObjectUrl;
        link.download = data.fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobObjectUrl);
      })
      .catch((error) => {
        console.error("Error downloading the blob:", error);
      })
      .finally(() => LoadingDispatch({ type: "COMPLETE" }));

    // download progress
    // fetch(data.file)
    //   .then(async (response) => {
    //     if (!response.body) {
    //       throw new Error("Response body is null");
    //     }

    //     const contentLengthHeader = response.headers && response.headers.get("Content-Length");
    //     const contentLength = contentLengthHeader ? +contentLengthHeader : 0;
    //     let receivedLength = 0;

    //     const readable = response.body.getReader();
    //     const chunks = [];

    //     while (true) {
    //       const { done, value } = await readable.read();

    //       if (done) {
    //         break;
    //       }

    //       if (value) {
    //         receivedLength += value.length;
    //         const progress = (receivedLength / contentLength) * 100;
    //         console.log(`다운로드 진행률: ${progress.toFixed(2)}%`);
    //         chunks.push(value);
    //       }
    //     }

    //     const blob = new Blob(chunks, { type: "application/octet-stream" });

    //     const blobObjectUrl = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = blobObjectUrl;
    //     link.download = data.fileName;
    //     link.style.display = "none";
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //     URL.revokeObjectURL(blobObjectUrl);
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the blob:", error);
    //   });
  };

  const confirmDialog = () => {
    DownloadApi.DeleteDownload(data.id)
      .then(() => {
        history.replace({
          pathname: `${route}/${data.kind}/list/${data.categoryId}`,
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot delete download." + msg);
      });
  };

  const onEdit = () => {
    history.push({
      pathname: `${route}/${data.kind}/edit/${data.categoryId}/${data.id}`,
    });
  };

  if (isNaN(downloadId)) {
    history.replace({
      pathname: "/",
      state: {},
    });
    return <></>;
  } else {
    const categoryStep = (data.categoryStep && data.categoryStep.split("|")) || [];
    const categoryStepId = (data.categoryStepId && data.categoryStepId.split("|")) || [];
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.locationSection}>
          <BreadcrumbsCategory
            route={route}
            kind={data.kind}
            step={categoryStep}
            stepId={categoryStepId}
          ></BreadcrumbsCategory>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{data.title}</Typography>
          <Divider></Divider>
        </Grid>
        {data.file && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <ImportantButton variant="outlined" onClick={onDownload} startIcon={<GetAppIcon />}>
              <FileSize name={data.fileName} size={data.fileSize}></FileSize>
            </ImportantButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <HtmlContent style={{ padding: "15px", backgroundColor: "white" }} data={data.content}></HtmlContent>
        </Grid>
        <Grid item xs={12}>
          {data.pdf && <PDFView pdf={data.pdf} name={data.pdfName}></PDFView>}
        </Grid>
        {user.id === data.writerId && (
          <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between" }}>
            <SaveButton onClick={onDelete}>DELETE</SaveButton>
            <ConfirmDialog
              state={deleteOpen}
              title={"Delete Post"}
              message={message}
              onClose={closeDialog}
              onClickConfirm={confirmDialog}
            ></ConfirmDialog>
            <ActionButton variant="contained" onClick={onEdit}>
              EDIT
            </ActionButton>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default DownloadDetailPage;
