import { Tab, Tabs } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useHistory, useRouteMatch } from "react-router";

export type TabsMenuChangeType = any | null;

function getMatchString(params: { match: string; subMatch?: string }) {
  let str = params.match;
  if (params.subMatch) {
    str += "/" + params.subMatch;
  }
  return str;
}

interface Props {
  matchPath: string;
  titleHeader: string[];
  urlHeader: string[];
  onChangeArr: TabsMenuChangeType[];
}

function TabsMenu({ matchPath, urlHeader, titleHeader, onChangeArr }: Props): ReactElement {
  if (onChangeArr.length !== urlHeader.length && urlHeader.length !== titleHeader.length) {
    //길이가 일치해야 합니다.
    throw new Error("Length of urlHeader have to same as length of onChangeArr");
  }

  const match = useRouteMatch<{ match: string; subMatch?: string }>(matchPath);
  const history = useHistory();
  // const location = useLocation();
  const matchString = getMatchString(match!.params);
  const url = match?.url;
  const value = urlHeader.findIndex((x) => x === matchString);

  const onChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (matchString) {
      const parentUrl = url?.replace(matchString, "");
      const _state = onChangeArr[newValue];
      if (_state !== null) {
        history.push({
          pathname: parentUrl + urlHeader[newValue],
          ..._state,
        });
      } else {
        history.push({
          pathname: parentUrl + urlHeader[newValue],
        });
      }
    }
  };
  return (
    <Tabs value={value === -1 ? 0 : value} indicatorColor="secondary" textColor="secondary" onChange={onChange}>
      {titleHeader.map((x) => (
        <Tab label={x} key={x}></Tab>
      ))}
    </Tabs>
  );
}

export default React.memo(TabsMenu);
