import { Modal } from "@material-ui/core";
import Image, { ImageProps } from "components/Image";
import React, { ReactElement, useState } from "react";

function ImageMagnifier({ width, height, src, fallbackSrc, ...other }: ImageProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const imgRef = React.useRef<Image>(null);
  const onShowDialog = () => {
    //error callback이 떨어지면, 확대할 필요가 없다.
    if (src === imgRef.current?.state.src) {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div role="button">
      <Image
        width={width}
        height={height}
        src={src}
        fallbackSrc={fallbackSrc}
        onClick={onShowDialog}
        ref={imgRef}
      ></Image>

      <Modal
        open={isOpen}
        onClose={onClose}
        //image를 가운데 놓기 위함.
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div style={{ overflow: "auto", maxHeight: "100vh" }}>
          <img alt={other.alt} src={src} width="90%" style={{ maxWidth: "960px" }}></img>
        </div>
      </Modal>
    </div>
  );
}

export default ImageMagnifier;
