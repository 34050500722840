import { useLocation } from "react-router";

function useLocationSearch() {
  const location = useLocation<any>();
  if (location.search) {
    return new URLSearchParams(location.search);
  }
  return undefined;
}

export default useLocationSearch;
