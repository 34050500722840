import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, ReactElement, useState } from "react";
import { useHistory } from "react-router";
import { useUserDispatch } from "system/UserContext";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, UserApi } from "system/ApiService";
import { useLoadingDispatch, useLoadingState } from "system/LoadingContext";
import useLocationStateMatch from "system/useLocationStateMatch";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

interface Props {
  onChange: (type: string) => void;
}

function LoginPage(props: Props): ReactElement {
  const classes = useStyles();
  const dispatch = useUserDispatch();
  const loadingDispatch = useLoadingDispatch();
  const { loading } = useLoadingState();
  const history = useHistory();
  const requestedPath = useLocationStateMatch("requestedPath");
  const [state, setState] = useState({ userId: "IHQ-", userPw: "" });
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["rememberId"]);

  const onChangeText = function (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onKeyDownEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClickSubmit();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRemember(e.target.checked);
    if (e.target.checked) {
      setCookie("rememberId", state.userId, { maxAge: 2592000 });
    } else {
      removeCookie("rememberId");
    }
  };

  useEffect(() => {
    if (cookies.rememberId !== undefined) {
      setState({ ...state, userId: cookies.rememberId });
      setIsRemember(true);
    }
    if (requestedPath && requestedPath !== MyRoutes.login) {
      loadingDispatch({ type: "LOADING" });
      UserApi.Refresh()
        .then((data) => {
          dispatch({ type: "LOGIN", item: data.user });
          UserApi.SetToken(data.token);
          history.push({
            pathname: requestedPath,
            state: undefined,
          });
        })
        .catch(() => {
          //no-matter
        })
        .finally(() => {
          loadingDispatch({ type: "COMPLETE" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClickSubmit() {
    //send axios
    UserApi.Login(state.userId, state.userPw)
      .then((data) => {
        dispatch({ type: "LOGIN", item: data.user });
        UserApi.SetToken(data.token);
        if (requestedPath && requestedPath !== MyRoutes.login) {
          history.push({
            pathname: requestedPath,
            state: undefined,
          });
        } else {
          if (data.user.role === "MANAGER") {
            history.push({ pathname: `${MyRoutes.cartOrder}/received` });
          } else {
            history.push(MyRoutes.root);
          }
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        if (error.response) {
          switch (error.response.status) {
            case 403: //forbidden
            case 404:
              msg = "The id or password you entered is incorrect.";
              break;
          }
        }
        alert(msg);
      });
  }

  if (loading) {
    return <></>;
  } else
    return (
      <div>
        <Typography variant="h5">Global Management</Typography>

        <TextField
          id="userId"
          label="ID"
          name="userId"
          type="text"
          variant="outlined"
          value={state.userId}
          className={classes.top}
          inputProps={{
            title: "id",
          }}
          onChange={onChangeText}
          onKeyDown={onKeyDownEnter}
        ></TextField>
        <TextField
          id="userPw"
          name="userPw"
          label="Password"
          type="password"
          variant="outlined"
          value={state.userPw}
          className={classes.bottom}
          onChange={onChangeText}
          onKeyDown={onKeyDownEnter}
          inputProps={{
            title: "password",
          }}
        ></TextField>
        <Grid container item justify="flex-start">
          <FormControlLabel
            control={<Checkbox checked={isRemember} onChange={handleOnChange} name="rememberId" />}
            label="Remember Id"
          />
        </Grid>
        <Button
          name="button"
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.button}
          onClick={onClickSubmit}
        >
          Login
        </Button>
        <br />
        <br />
        <span className="help-link" onClick={() => props.onChange("password")}>
          <Typography role="button">Can't sign in?</Typography>
        </span>
      </div>
    );
}

export default LoginPage;
