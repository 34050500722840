import {
  Button,
  FormControlLabel,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Selected } from "components/custom/Select3Category";
import SimpleSelect from "components/custom/SimpleSelect";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { IsShopCategory } from "system/Helper";
import { DCategory, INIT_DATA_CATEGORY } from "system/types";

interface Props {
  kind: string;
  categoryId: number;
  isEdit: boolean;
  saveCategory: (category: DCategory) => void;
  deleteCategory: (categoryId: number) => void;
}

function CategoryEditField({
  kind,
  isEdit,
  categoryId: selectedId,
  saveCategory,
  deleteCategory,
}: Props): ReactElement {
  const BTN_DELETE = "delete";
  const BTN_UPSERT = "upsert";

  const [category, setCategory] = useState<DCategory>(INIT_DATA_CATEGORY);
  const [cmbList, setCmbList] = useState<DCategory[]>([]);
  const [selected, setSelected] = useState<Selected>({});

  useMemo(() => {
    CategoryApi.GetCategoriesList(kind)
      .then((res) => {
        setCmbList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [kind]);

  useEffect(() => {
    if (selectedId !== 0 && !isNaN(selectedId)) {
      CategoryApi.GetCategorory(kind, selectedId).then((x) => {
        setCategory(x);
        if (x.depth === 1) {
          setSelected({});
        } else if (x.depth === 2) {
          setSelected({
            first: x.parentId,
          });
        }
      });
    } else if (selectedId === 0) {
      setCategory(INIT_DATA_CATEGORY);
      setSelected({});
    }
  }, [kind, selectedId]);

  function onChangeText(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.name === "isShow") {
      setCategory({
        ...category,
        isShow: !category.isShow,
      });
    } else {
      setCategory({
        ...category,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  }

  function onClickActionButton(event: React.MouseEvent<HTMLButtonElement>) {
    const eventName = event.currentTarget.name;
    if (eventName === BTN_UPSERT) {
      if (isEdit && window.confirm("Are you sure you want to edit?")) {
        saveCategory(category);
      } else if (window.confirm("Created a new category.")) {
        saveCategory(category);
      }
    } else if (eventName === BTN_DELETE) {
      deleteCategory(category.id);
    }
  }

  const onChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    //currentTarget을 사용하면 값이 나오지 않는다.
    const { name, value } = event.target;
    let num: number;

    if (typeof value === "number") {
      num = value;
    } else if (typeof value === "string") {
      num = parseInt(value);
    } else {
      return;
    }

    if (!isNaN(num)) {
      if (name === "1st") {
        setSelected({
          first: num,
          second: undefined,
        });
      } else if (name === "2nd") {
        setSelected({
          ...selected,
          second: num,
        });
      } else {
        throw new Error("Not Implemented Error");
      }
      setCategory({
        ...category,
        parentId: num,
      });
    }
  };

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Category Name</TableCell>
            {IsShopCategory(kind) ? (
              <TableCell>
                <Typography>{category.name}</Typography>
              </TableCell>
            ) : (
              <TableCell>
                <TextField
                  value={category.name}
                  onChange={onChangeText}
                  name="name"
                  style={{ width: "80%" }}
                ></TextField>
              </TableCell>
            )}
          </TableRow>
          {IsShopCategory(kind) ? null : (
            <TableRow>
              <TableCell>Parent Category</TableCell>
              <TableCell>
                <SimpleSelect empty name="1st" label="1st" value={selected.first || ""} onChange={onChangeSelect}>
                  {category.depth !== 1 &&
                    cmbList
                      ?.filter((x) => x.depth === 1)
                      .map((x) => (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                </SimpleSelect>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Open</TableCell>
            <TableCell>
              <FormControlLabel
                control={<Switch checked={category.isShow} onChange={onChangeText} name="isShow" color="secondary" />}
                label="Open"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Order</TableCell>
            <TableCell>
              <TextField
                type="number"
                value={category.order}
                onChange={onChangeText}
                name="order"
                style={{ width: "130px" }}
              ></TextField>
              <br></br>
              <Typography variant="caption">※ Sorts in ascending order according to the Order value.</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {IsShopCategory(kind) ? (
        <div className="text-center mt-2" id="div-btn-action">
          <Button variant="contained" color="primary" name={BTN_UPSERT} onClick={onClickActionButton} size="small">
            Save
          </Button>
        </div>
      ) : (
        <div className="text-center mt-2" id="div-btn-action">
          {isEdit && (
            <Button
              variant="contained"
              color="secondary"
              name={BTN_DELETE}
              size="small"
              onClick={onClickActionButton}
              style={{ marginRight: "5px" }}
            >
              Delete
            </Button>
          )}
          <Button variant="contained" color="primary" name={BTN_UPSERT} onClick={onClickActionButton} size="small">
            {isEdit ? "SAVE" : "ADD"}
          </Button>
        </div>
      )}
    </>
  );
}

export default CategoryEditField;
