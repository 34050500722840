import { ReactElement, useEffect, useState } from "react";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { useHistory } from "react-router";
import { CategoryApi, ItemApi } from "system/ApiService";
import { ItemKind } from "system/types";
import { Paper, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import { useUserState } from "system/UserContext";
import { IsEngineerUser } from "system/Helper";
import FrequentBox from "components/Common/FrequentBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 1000,
    },
  })
);

function ShopPage(): ReactElement {
  const classes = MyStyles();
  const classes2 = useStyles();
  const history = useHistory();
  const [productList, setProductList] = useState<ItemKind[]>([]);
  const [materialList, setMaterialList] = useState<ItemKind[]>([]);
  const user = useUserState();
  const [InBody270, setInBody270] = useState<string>();

  const findInBody270 = () => {
    CategoryApi.GetAllCategoriesERP("MATERIAL")
      .then((x) => {
        setInBody270(
          x.filter((item) => item.name === "InBody")[0].children.filter((x) => x.name === "IBD_InBody270")[0].id
        );
      })
      .catch((err) => {
        console.log("Error! Cannot find inbody270 url.");
      });
  };

  function frequentItem(type: string) {
    ItemApi.GetFrequentItems(user.id, type)
      .then((x) => {
        if (type === "TopProduct") setProductList(x);
        else if (type === "TopMaterial") setMaterialList(x);
      })
      .catch((err) => {
        console.log("Cannot get frequent items.");
      });
  }

  useEffect(() => {
    findInBody270();
    frequentItem("TopMaterial");
    frequentItem("TopProduct");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {IsEngineerUser(user.role) ? null : (
          <div
            className={classes.shopBox}
            style={{
              backgroundImage: `url(/images/ProductPageImg.png)`,
              backgroundSize: "cover",
            }}
            onClick={() => {
              history.push({ pathname: MyRoutes.shopProduct });
            }}
          >
            Products
          </div>
        )}
        <div
          className={classes.shopBox}
          style={{
            backgroundImage: `url(/images/MaterialPageImg.png)`,
            backgroundSize: "cover",
          }}
          onClick={() => {
            history.push({ pathname: `${MyRoutes.shopMaterial}/${InBody270 ? InBody270 : ""}` });
          }}
        >
          Spare Parts
        </div>
      </div>
      {IsEngineerUser(user.role) ? null : (
        <div className={classes.divSearchList}>
          <Paper className={classes.paperMain}>
            <Typography variant="h6" color="secondary">
              <strong>Frequently Purchased Products</strong>
            </Typography>
            <br></br>
            {productList.length !== 0 ? (
              <div className={classes2.root}>
                <GridList className={classes2.gridList} cols={1} style={{ height: 240, flexWrap: "nowrap" }}>
                  {productList.map((x, index) => (
                    <FrequentBox key={index} id={x.id} kind={x.kind}></FrequentBox>
                  ))}
                </GridList>
              </div>
            ) : (
              <div>No Previous Orders</div>
            )}
          </Paper>
        </div>
      )}
      <div className={classes.divSearchList}>
        <Paper className={classes.paperMain}>
          <Typography variant="h6" color="secondary">
            <strong>Frequently Purchased Spare Parts</strong>
          </Typography>
          <br></br>
          {materialList.length !== 0 ? (
            <div className={classes2.root}>
              <GridList className={classes2.gridList} cols={1} style={{ height: 230, flexWrap: "nowrap" }}>
                {materialList.map((x, index) => (
                  <FrequentBox key={index} id={x.id} kind={x.kind}></FrequentBox>
                ))}
              </GridList>
            </div>
          ) : (
            <div>No Previous Orders</div>
          )}
        </Paper>
      </div>
    </>
  );
}

export default ShopPage;
