import { Grid, MenuItem } from "@material-ui/core";
import SimpleSelect from "components/custom/SimpleSelect";
import React, { ReactElement, useEffect, useState } from "react";
import { CategoryApi } from "system/ApiService";
import { DCategory } from "system/types";

export interface Selected {
  first?: number;
  second?: number;
  third?: number;
}
interface Props {
  /**
   * 카테고리 Id
   */
  value?: number;
  kind: string;
  /**
   * empty 값 여부 default = false,
   */
  empty?: boolean;
  maxDepth?: 1 | 2;
  /**
   * @param id 변경된 Id의 값
   * @param index? 바뀐 id의 위치 (1,2,3)
   */
  onChange: (id: number, index?: number) => void;
}

function Select2Category({ value, kind, onChange: onValueChange, maxDepth, empty }: Props): ReactElement {
  const _maxDepth = maxDepth && maxDepth <= 2 ? maxDepth : 2;
  const [list, setList] = useState<DCategory[]>([]);
  const [selected, setSelected] = useState<Selected>({});

  ///카테고리 값 설정!
  useEffect(() => {
    CategoryApi.GetCategoriesList(kind)
      .then((res) => {
        setList(res);
      })
      .catch(() => {
        console.log("Error! select 2 category.");
      });
  }, [kind]);

  //setValue for load
  useEffect(() => {
    const cur = list.find((x) => x.id === value && x.depth === _maxDepth);
    if (cur) {
      let state: Selected = {};
      if (_maxDepth === 2) {
        state.second = cur.id;
        state.first = cur.parentId;
      } else if (_maxDepth === 1) {
        state.first = cur.id;
      }
      setSelected(state);
    }
  }, [_maxDepth, list, value]);

  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    //currentTarget을 사용하면 값이 나오지 않는다.
    const { name, value } = event.target;
    let num: number;

    if (typeof value === "number") {
      num = value;
    } else if (typeof value === "string") {
      num = parseInt(value);
    } else {
      return;
    }

    if (!isNaN(num)) {
      let index = 0;
      if (name === "1st") {
        setSelected({
          first: num,
          second: undefined,
          third: undefined,
        });
        index = 1;
      } else if (name === "2nd") {
        setSelected({
          ...selected,
          second: num,
          third: undefined,
        });
        index = 2;
      } else if (name === "3rd") {
        setSelected({
          ...selected,
          third: num,
        });
        index = 3;
      } else {
        throw new Error("Not Implemented Error");
      }

      onValueChange(num, index);
    }
  };

  //2개일때 grid 반쪽으로 나누기 위함
  const gridDivide = _maxDepth === 2 ? 3 : 4;

  return (
    <Grid container spacing={1}>
      {_maxDepth >= 1 && (
        <Grid item sm={gridDivide}>
          <SimpleSelect empty={empty} name="1st" label="1st" value={selected.first || ""} onChange={onChange}>
            {list
              ?.filter((x) => x.depth === 1 && x.isShow)
              .map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
          </SimpleSelect>
        </Grid>
      )}
      {_maxDepth >= 2 && (
        <Grid item sm={gridDivide}>
          <SimpleSelect empty={empty} name="2nd" label="2nd" value={selected.second || ""} onChange={onChange}>
            {list
              ?.filter((x) => x.depth === 2 && x.parentId === selected.first && x.isShow)
              .map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
          </SimpleSelect>
        </Grid>
      )}
    </Grid>
  );
}

export default Select2Category;
