import React, { ReactElement } from "react";

interface PriceProps {
  price?: number;
  quantity?: number;
  priceUnit?: string;
}

function Price({ price, quantity, priceUnit }: PriceProps): ReactElement {
  const cnt = quantity as number;
  if (price && priceUnit) {
    return (
      <>
        <span className="currency">{priceUnit}</span>
        &nbsp;
        <span className="price">
          {(price * cnt)
            .toFixed(2)
            .toLocaleString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      </>
    );
  } else {
    return <i>No Content</i>;
  }
}

export default Price;
