import React, { ReactElement, useRef } from "react";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-pdfviewer/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { UserApi } from "system/ApiService";

interface PDFViewProps {
  pdf: string;
  name?: string;
}

function PDFView({ pdf, name }: PDFViewProps): ReactElement {
  const pdfViewerRef = useRef<PdfViewerComponent>(null);

  const handleDownloadButtonClick = () => {
    const pdfViewerInstance = pdfViewerRef.current;
    if (pdfViewerInstance) {
      pdfViewerInstance.downloadFileName = name || "Global Management File";
    }
  };

  return (
    <div>
      <PdfViewerComponent
        id="container"
        documentPath={pdf}
        downloadStart={handleDownloadButtonClick}
        serviceUrl={`${window.location.origin}/api/PDF`}
        ajaxRequestSettings={{
          ajaxHeaders: [
            {
              headerName: "Authorization",
              headerValue: `Bearer ${UserApi.GetToken()}`,
            },
          ],
          withCredentials: true,
        }}
        style={{ height: "640px" }}
        ref={pdfViewerRef}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            Annotation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
          ]}
        />
      </PdfViewerComponent>
    </div>
  );
}

export default PDFView;
