import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { CancelButton, SaveButton } from "components/Button";
import StringInput from "components/custom/StringInput";
import { Comment } from "system/types";
import { useUserState } from "system/UserContext";

interface CommentModalProps extends DialogProps {
  commentList: Comment[];
  onUpdate: (comment: Comment) => void;
  onDelete: (id: number) => void;
}

function CommentModal({ commentList, onUpdate: _onUpdate, onDelete: _onDelete, ...dialogProps }: CommentModalProps) {
  const [comment, setComment] = useState<string>("");
  const user = useUserState();
  const onClickCloseModal = () => {
    dialogProps.onClose!({}, "escapeKeyDown");
    setComment("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setComment(value);
  };

  const onUpdate = () => {
    _onUpdate({ id: 0, writer: user.showName, writerId: user.id, comment: comment });
    setComment("");
  };

  // 댓글 삭제 (작성자와 같을 경우)
  const onDelete = (event: React.MouseEvent<SVGSVGElement>) => {
    if (!(event.currentTarget instanceof SVGSVGElement)) {
      return;
    } else {
      const _id = event.currentTarget.dataset.id;

      if (_id) {
        const id = parseInt(_id);
        _onDelete(id);
      }
    }
  };

  return (
    <Dialog {...dialogProps} onClose={onClickCloseModal}>
      <DialogTitle>Write a comment</DialogTitle>
      <DialogContent>
        {commentList.length === 0 ? (
          <>
            No Comments
            <br />
          </>
        ) : (
          <Box style={{ height: "60%" }}>
            {commentList.map((x, idx) => (
              <>
                <Grid container justify="space-between">
                  <div style={{ fontSize: "0.8rem", width: "80%" }}>
                    {x.writer} : {x.comment}
                  </div>
                  {user.id === x.writerId && (
                    <DeleteIcon style={{ width: "10%" }} data-id={x.id} onClick={onDelete}></DeleteIcon>
                  )}
                </Grid>
                <hr />
              </>
            ))}
          </Box>
        )}
        <br />
        <Box style={{ border: "1px solid black", width: "100%" }}>
          <StringInput
            type="edit"
            textFieldProps={{
              multiline: true,
              placeholder: "write here.",
              name: "comment",
              value: comment,
              variant: "outlined",
              onChange: onChange,
              fullWidth: true,
            }}
          ></StringInput>
        </Box>
      </DialogContent>
      <DialogActions>
        <SaveButton disabled={comment.length === 0} onClick={onUpdate}>
          Update
        </SaveButton>
        <CancelButton onClick={onClickCloseModal}>Cancel</CancelButton>
      </DialogActions>
    </Dialog>
  );
}

export default CommentModal;
