import React from "react";
import { Copyright } from "@material-ui/icons";
import { MyStyles, SECONDARY } from "../../style/theme";
import { Container } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MyRoutes } from "routes/Routes";

function Footer() {
   const classes = MyStyles();

   return (
      <div className={classes.footer}>
         <Divider></Divider>
         <Container className="py-2" style={{ fontSize: "10pt", color: SECONDARY, marginTop: "15px" }}>
            Copyright <Copyright fontSize="small" /> {new Date().getFullYear()} InBody CO.,LTD. All right reserved
            <div>
              <Link to={{ pathname: MyRoutes.privacyPolicy }}>Privacy Policy</Link>
            </div>
         </Container>
      </div>
   );
}

export default Footer;
