import { Collapse, Grid, IconButton, List, ListItemText, Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { CSSProperties } from "@material-ui/styles";
import { ActionButton } from "components/Button";
import ListItemColored from "components/ListItemColored";
import React, { ReactElement, ReactNode, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { Category } from "system/types";
import AdminComponent from "./Common/AdminComponent";

type FMakePathName = (
  data: Category,
  depth?: number
) => {
  pathname: string;
  state?: any;
};

interface ListLayerProps {
  list: Category[];
  children: ReactNode;
  route: MyRoutes;
  makePathName: FMakePathName;
  kind: string;
  title?: string;
}

function ListLayer({ list, children, route, makePathName, kind, title }: ListLayerProps): ReactElement {
  const classes = MyStyles();
  const history = useHistory();
  const onClick = () => {
    history.push({
      pathname: `${MyRoutes.category}/edit`,
      search: `?kind=${kind.toUpperCase()}`,
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ position: "sticky", top: "60px", width: "210px", height: "calc(100vh - 50px)" }}>
        <div style={{ height: "100%", overflowX: "auto" }}>
          <Grid container justify="space-between">
            <Grid item>
              {title && (
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{ paddingLeft: "12px", display: "inline", font: "bold" }}
                >
                  {title}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <AdminComponent>
                <ActionButton className={classes.miniButton} size="small" onClick={onClick}>
                  Edit
                </ActionButton>
              </AdminComponent>
            </Grid>
          </Grid>
          {/* category list */}
          <CategoryList kind={kind} data={list} route={route} makePathName={makePathName}></CategoryList>
        </div>
      </div>
      <div className="content" style={{ width: "calc(100% - 210px)", paddingLeft: "20px" }}>
        {children}
      </div>
    </div>
  );
}

export default ListLayer;

//----------카테고리 리스트 시작---------
interface CategoryListProps {
  kind: string;
  data: Category[];
  route: MyRoutes;
  makePathName: FMakePathName;
  depth?: number;
}
function CategoryList({ kind, data, route, depth, makePathName }: CategoryListProps): ReactElement {
  const classes = MyStyles();
  const _depth = depth || 1;
  const textStyle = React.useMemo(() => {
    const padding = 5 * _depth;
    return { paddingLeft: `${padding}px`, fontSize: "0.95rem" };
  }, [_depth]);
  const { pathname } = useLocation();

  const pathNameRegex = React.useMemo(() => {
    const regex = /[^\d]/g;
    return pathname.replace(regex, "");
  }, [pathname]);

  return (
    <List
      dense
      component="div"
      aria-labelledby={`nested-list-subheader(${_depth})`}
      className={classes.categoryMenu}
      style={{ borderBottom: "1px solid #a2a2a2" }}
      disablePadding
    >
      {data
        .filter((x) => x.isShow)
        .map((x, index) => (
          <CategoryItem
            key={`nested-list${x.id}-${index}`}
            depth={_depth}
            selected={x.id + "" === pathNameRegex}
            //selected={false}
            textStyle={textStyle}
            to={makePathName(x, _depth)}
            isOpen={false}
            label={`${x.name}`}
            childLength={x.children ? x.children.length : 0}
            kind={kind}
          >
            {x.children && x.children.length >= 1 && (
              <CategoryList
                kind={kind}
                depth={_depth + 1}
                data={x.children}
                route={route}
                makePathName={makePathName}
              ></CategoryList>
            )}
          </CategoryItem>
        ))}
    </List>
  );
}

interface ListItemProps {
  isExpanded: boolean;
  depth: number;
  textStyle: CSSProperties;
  label: string;
  onClickExpanded?: (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => void;
  onClickListItem: () => void;
  selected: boolean;
  childLength: number;
  kind: string;
}

const MemoListItem = React.memo((props: ListItemProps) => {
  const classes = MyStyles();
  return (
    <>
      {props.kind === "MATERIAL" || props.kind === "PRODUCT" ? (
        <ListItemColored
          className={classes.categoryItem}
          style={
            props.depth === 1
              ? {
                  marginTop: "10px",
                  borderBottom: "1px solid #c51f1f",
                }
              : undefined
          }
          selected={props.selected}
          button
          onClick={props.depth === 1 ? props.onClickExpanded : props.onClickListItem}
        >
          <ListItemText style={props.textStyle}>{props.label}</ListItemText>
          {/* 하위 멤버가 있으면, */}
          {props.depth === 1 && props.childLength !== 0 ? (
            <IconButton size="small">{props.isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          ) : null}
        </ListItemColored>
      ) : (
        <ListItemColored
          className={classes.categoryItem}
          style={
            props.depth === 1
              ? {
                  marginTop: "10px",
                  borderBottom: "1px solid #c51f1f",
                }
              : undefined
          }
          selected={props.selected}
          button
          onClick={props.onClickListItem}
        >
          <ListItemText style={props.textStyle}>{props.label}</ListItemText>
          {/* 하위 멤버가 있으면, */}
          {props.depth === 1 && props.childLength !== 0 ? (
            <IconButton size="small" onClick={props.onClickExpanded}>
              {props.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : null}
        </ListItemColored>
      )}
    </>
  );
});

//----------카테고리 리스트 시작---------
interface CategoryItemProps {
  to: {
    pathname: string;
    search?: string;
    hash?: string;
    state?: any;
  };
  label: string;
  textStyle: CSSProperties;
  children?: ReactNode;
  isOpen?: boolean;
  depth: number;
  selected: boolean;
  childLength: number;
  kind: string;
}
function CategoryItem(props: CategoryItemProps): ReactElement {
  const history = useHistory();
  const [open, setOpen] = React.useState<boolean>(props.isOpen || false);

  const onClickExpanded = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setOpen((prev) => !prev);
    },
    []
  );

  const onClickListItem = useCallback(() => history.push(props.to), [history, props.to]);

  const isExpanded = React.useMemo(() => {
    if (props.children) return open;
    else return false;
  }, [props.children, open]);

  return (
    <>
      <MemoListItem
        depth={props.depth}
        textStyle={props.textStyle}
        label={props.label}
        selected={props.selected}
        onClickListItem={onClickListItem}
        onClickExpanded={onClickExpanded}
        isExpanded={isExpanded}
        childLength={props.childLength}
        kind={props.kind}
      ></MemoListItem>

      {props.children && (
        <Collapse in={open}>
          <List component="div" disablePadding>
            {props.children}
          </List>
        </Collapse>
      )}
    </>
  );
}
