import React, { useState, ReactElement, useEffect, useRef } from "react";
import { ItemDetail } from "system/types";
import {
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { SaveButton } from "components/Button";
import ResponsiveDialog from "components/Dialog/ResponsiveDialog";
import { SimpleRadioGroup, SimpleRadio } from "components/custom/SimpleRadio";
import HelperAlert from "components/Common/HelperAlert";
import { Editor } from "components/RichTextBox";
import DialogTitleWithExit from "components/Dialog/DialogTitleWithExit";
import { GreenCheckIcon, RedNotInterestedIcon } from "components/custom/Icon";

interface ChangeMaterialModalProps extends DialogProps {
  data: ItemDetail;
  onUpdate: (data: ItemDetail, file: File | undefined) => void;
}

export default function ChangeMaterialModal({ data, onUpdate, ...other }: ChangeMaterialModalProps): ReactElement {
  const [state, setState] = useState<ItemDetail>(data);
  const [file, setFile] = useState<File>();
  const EditorRef = useRef<Editor>(null);

  //setting Material
  useEffect(() => {
    setState(data);
  }, [data]);

  const onClickSaveButton = () => {
    if (!window.confirm("Some Changes colud not be saved. Are you continued?\nIf More Help, See Help Section")) {
      return;
    } else {
      onUpdate(
        {
          ...state,
          caution: EditorRef.current?.getContent() || "",
        },
        file
      );
    }
  };

  ///#handle data start ...
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setState({
        ...state,
        image: event.target.files[0]?.name,
      });
    }
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { name } = event.currentTarget;

    if (name === "isShow" || name === "eos") {
      setState({
        ...state,
        [name]: value === "1",
      });
    }
  };

  const onClickDeleteImage = () => {
    if (window.confirm("Are you delete image file?")) {
      setState({
        ...state,
        image: "",
      });
      alert("Click the Save button to save changes.");
    }
  };

  return (
    <ResponsiveDialog {...other}>
      <DialogTitleWithExit id="dialog-with-exit" onClose={other.onClose}>
        Change Spare Parts Info
      </DialogTitleWithExit>
      <DialogContent>
        <HelperAlert>
          <div>End of Sale : If true, the User could not add carts.</div>
          <div>Open : If true, Only Admin users can see it.</div>
          <div>Image : Size should be 500x300 pixel and extension name should be .jpg</div>
          <div>Option : Separate options with '|'. An option check box will appear and the price will be the same.</div>
          <div>Compatible Product : Please write compatible products</div>
          <div>Caution : Preview of Caution has a max length of 100</div>
        </HelperAlert>
        <br></br>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Category</Typography>
            <Typography>{state.categoryName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Code</Typography>
            <Typography>{state.productNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Name</Typography>
            <Typography>{state.productName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>New Image</Typography>
            <input type="file" onChange={onChangeFile} accept=".jpg"></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Existing Image</Typography>
            {/* 기존에 올렸던 파일이 존재하면서, 삭제할 수 있는 이미지 파일이 있다면, */}
            {data.image && data.image === state.image && (
              <>
                <a href={`/images/material/items/${data.image}`} rel="noreferrer" target="_blank">
                  {data.image}
                </a>
                <IconButton onClick={onClickDeleteImage}>
                  <DeleteIcon></DeleteIcon>
                </IconButton>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>isShow</Typography>
            <SimpleRadioGroup name="isShow" value={state.isShow ? "1" : "0"} row onChange={onChangeRadio}>
              <SimpleRadio value="1" label={<GreenCheckIcon />}></SimpleRadio>
              <SimpleRadio value="0" label={<RedNotInterestedIcon />}></SimpleRadio>
            </SimpleRadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>isStopped</Typography>
            {state.stopped ? <GreenCheckIcon /> : <RedNotInterestedIcon />}
          </Grid>

          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              onChange={onChangeText}
              value={state.optionName}
              name="optionName"
              label="Option(Separated by '|')"
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              onChange={onChangeText}
              value={state.compatibleProduct}
              name="compatibleProduct"
              label="Compatible Product(200)"
              fullWidth
              inputProps={{ maxLength: 200 }}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" display="inline">
              Caution&nbsp;
            </Typography>
            <Editor ref={EditorRef} content={state.caution || ""}></Editor>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SaveButton onClick={onClickSaveButton}>SAVE</SaveButton>
      </DialogActions>
    </ResponsiveDialog>
  );
}
