import { Paper, Typography } from "@material-ui/core";
import { ReactElement, useMemo, useState } from "react";
import { MyStyles } from "style/theme";
import { CSEducationApi, CSManualApi, CSVideoApi, ItemApi } from "system/ApiService";
import { CSVideo, SearchModel } from "system/types";
import NoticeList from "./Notice/List";
import SearchListBox from "./Search/SearchListBox";
import SearchMaterialList from "./Search/SearchMaterialList";
import SearchVideoBox from "./Search/SearchVideoBox";
import { IsAgencyUser } from "system/Helper";
import { useUserState } from "system/UserContext";

function MainPage(): ReactElement {
  const classes = MyStyles();
  const user = useUserState();
  const [items, setItems] = useState<SearchModel[]>([]);
  const [manuals, setManuals] = useState<SearchModel[]>([]);
  const [videos, setVideos] = useState<CSVideo[]>([]);
  const [newMaterials, setNewMaterials] = useState<SearchModel[]>([]);
  const [renewalMaterials, setRenewalMaterials] = useState<SearchModel[]>([]);

  useMemo(() => {
    CSEducationApi.GetRecentlyCSEducation()
      .then((res) => {
        let array: SearchModel[] = [];
        res.forEach((x) => {
          let sm: SearchModel = {
            table: "CSEducation",
            id: x.id,
            name: x.title,
            category: x.categoryStep,
            content: x.content,
            file: x.file,
          };
          array.push(sm);
        });
        setItems(array);
      })
      .catch(() => {
        console.log("Error! Cannot get CS education.");
      });
    CSManualApi.GetRecentlyCSManual()
      .then((res) => {
        let array: SearchModel[] = [];
        res.forEach((x) => {
          let sm: SearchModel = {
            table: "CSManual",
            id: x.id,
            name: x.title,
            category: x.categoryStep,
            content: x.content,
            file: x.file,
          };
          array.push(sm);
        });
        setManuals(array);
      })
      .catch(() => {
        console.log("Error! Cannot get CS education.");
      });
    CSVideoApi.GetRecentlyCSVideo()
      .then((res) => {
        setVideos(res);
      })
      .catch(() => {
        console.log("Error! Cannot get CS education.");
      });
    bindMaterial("newMaterial");
    bindMaterial("renewalMaterial");
  }, []);

  function bindMaterial(kind: string) {
    ItemApi.GetRecentlyItems(kind)
      .then((res) => {
        let array: SearchModel[] = [];
        res.forEach((x) => {
          let sm: SearchModel = {
            table: x.kind.charAt(0) + x.kind.slice(1).toLowerCase(),
            id: x.productNumber,
            name: x.productName,
            category: x.categoryName,
            content: x.productNumber + " " + x.caution || "none",
            file: x.image,
          };
          array.push(sm);
        });
        if (kind === "newMaterial") setNewMaterials(array);
        else if (kind === "renewalMaterial") setRenewalMaterials(array);
      })
      .catch((error) => {
        console.log("Cannot bind new items.");
      });
  }

  return (
    <>
      <div className={classes.divSearchList}>
        <Paper className={classes.paperMain}>
          <img src="/images/CS-banner.png" style={{ maxWidth: "85%" }} alt="InBody"></img>
        </Paper>
        <Paper className={classes.paperMain}>
          <Typography variant="h5" color="secondary">
            <strong>Notice</strong>
          </Typography>
          <br></br>
          <NoticeList announcement={true}></NoticeList>
        </Paper>
        <Paper className={classes.paperMain}>
          <Typography variant="h5" color="secondary">
            <strong>New items</strong>
          </Typography>
          <br></br>
          <SearchMaterialList materials={newMaterials} rows={newMaterials.length}></SearchMaterialList>
        </Paper>
        <Paper className={classes.paperMain}>
          <Typography variant="h5" color="secondary">
            <strong>Renewal items</strong>
          </Typography>
          <br></br>
          <SearchMaterialList materials={renewalMaterials} rows={renewalMaterials.length}></SearchMaterialList>
        </Paper>
        {!IsAgencyUser(user.role) && (
          <>
            <Paper className={classes.paperMain}>
              <Typography variant="h5" color="secondary">
                <strong>New training</strong>
              </Typography>
              <br></br>
              {items.map((x, index) => (
                <SearchListBox item={x} key={`${x.id}-${index}`}></SearchListBox>
              ))}
            </Paper>
            <Paper className={classes.paperMain}>
              <Typography variant="h5" color="secondary">
                <strong>New manual</strong>
              </Typography>
              <br></br>
              {manuals.map((x, index) => (
                <SearchListBox item={x} key={`${x.id}-${index}`}></SearchListBox>
              ))}
            </Paper>
            <Paper className={classes.paperMain}>
              <Typography variant="h5" color="secondary">
                <strong>New video</strong>
              </Typography>
              <br></br>
              <SearchVideoBox item={videos} rows={videos.length}></SearchVideoBox>
            </Paper>
          </>
        )}
      </div>
    </>
  );
}

export default MainPage;
