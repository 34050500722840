import { Button, Checkbox, TextField, Typography } from "@material-ui/core";
import Image from "components/Image";
import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { ShopApi } from "system/ApiService";
import { CartItem, CheckUnitPrice, INIT_CHECK_PRICE } from "system/types";
import { useUserState } from "system/UserContext";

interface Props {
  item: CartItem;
  currency?: string;
  setCartItem: (item: CartItem) => void;
  onChangeFlag: (flag: boolean) => void;
}

function CartItemView({ item: x, currency, setCartItem, onChangeFlag }: Props): ReactElement {
  const user = useUserState();
  const classes = MyStyles();
  const [quantity, setQuantity] = useState(x.quantity);
  const [itemremark, setItemRemark] = useState(x.itemRemark);
  const [ERPprice, setERPPrice] = useState<number>(x.unitPrice);
  const subTotal = x.subTotal
    ?.toFixed(2)
    .toLocaleString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const [checkPrice, setCheckPrice] = useState<CheckUnitPrice>(INIT_CHECK_PRICE);

  useEffect(() => {
    setCheckPrice({
      ...checkPrice,
      ItemNumber: x.productNumber,
      Customer: user.custAccount,
      PriceDate: new Date().toISOString().split("T")[0],
      Currency: user.currencyCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const el = event.currentTarget as HTMLInputElement;
    const id = parseInt(el.dataset.id ?? "");
    if (!isNaN(id)) {
      const nextItem = { ...x, checked: checked };
      ShopApi.UpdateCartItem(nextItem)
        .then(() => {
          setCartItem(nextItem);
        })
        .catch(() => {});
    }
  };

  const onChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.currentTarget.value);
    if (!isNaN(val) && val > 0) {
      setQuantity(val);
      onChangeFlag(true);
    } else if (val === 0) {
      alert("You can purchase from 1 or more.");
    }
  };

  const onHandle = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (quantity !== undefined) {
      ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: quantity.toString() })
        .then((res) => {
          setERPPrice(res.unitPrice);
        })
        .catch(() => {});
    }
  };

  const onCancelChangeQuantity = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setQuantity(x.quantity);
    }
  };

  const onQuantityUpdate = () => {
    const nextCartItem = {
      ...x,
      quantity: quantity,
      unitPrice: ERPprice,
      subTotal: ERPprice * quantity,
    };
    ShopApi.UpdateCartItem(nextCartItem)
      .then((res) => {
        setCartItem(nextCartItem);
        onChangeFlag(false);
      })
      .catch((error) => {
        console.log("Error! Cannot update qty in cartitemview.");
      });
  };

  const onChangeItemRemark = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setItemRemark(value);
    onChangeFlag(true);
  };

  const onItemRemarkUpdate = () => {
    const nextCartItem = {
      ...x,
      itemRemark: itemremark,
    };
    ShopApi.UpdateCartItem(nextCartItem)
      .then((res) => {
        setCartItem(nextCartItem);
        onChangeFlag(false);
      })
      .catch((error) => {
        alert("Error! Cannot update remark.");
      });
  };

  return (
    <tr className={x.stopped ? classes.endOfSaleTr : undefined}>
      <td>
        <Checkbox
          inputProps={
            {
              "data-id": x.id,
            } as any
          }
          checked={x.checked}
          onChange={onCheckBoxClick}
        ></Checkbox>
      </td>
      <td>
        {x.kind === "PRODUCT" ? (
          <Image
            width={100}
            height={100}
            paddingHeight={0}
            errorPaddingHeight={0}
            noErrorMsg
            src={`/images/product/${x.image ? x.image : x.productNumber.replace(" ", "") + ".jpg"}`}
            fallbackSrc="/images/NO_Image.png"
          ></Image>
        ) : (
          <Image
            width={100}
            height={100}
            paddingHeight={0}
            errorPaddingHeight={0}
            noErrorMsg
            src={`/images/material/items/${x.image ? x.image : x.productNumber + ".jpg"}`}
            fallbackSrc="/images/NO_Image.png"
          ></Image>
        )}
      </td>
      <td>
        {x.stopped && (
          <>
            <Typography variant="caption">End of Sale</Typography>
            <br />
          </>
        )}
        {x.kind === "PRODUCT" ? (
          <div>
            <Link to={{ pathname: `${MyRoutes.shopProduct}` }}>
              {x.productName}&nbsp;({x.productNumber})
            </Link>
            {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
          </div>
        ) : (
          <div>
            <Link to={{ pathname: `${MyRoutes.shopMaterial}/view/${x.productNumber}` }}>
              {x.productName}&nbsp;({x.productNumber})
            </Link>
            {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
          </div>
        )}
      </td>
      <td>
        {user.currencyCode}{" "}
        {ERPprice.toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td>
        {x.csCase ? (
          <div style={{ textAlign: "center" }}>{quantity}</div>
        ) : (
          <>
            <TextField
              value={quantity}
              size="small"
              style={{ width: "70px" }}
              onChange={onChangeQuantity}
              onKeyUp={onHandle}
              inputProps={{ min: 1, style: { textAlign: "center" } }}
              disabled={x.stopped}
              onKeyDown={onCancelChangeQuantity}
            ></TextField>
            &nbsp;
            <Button onClick={onQuantityUpdate} disabled={x.stopped || quantity === x.quantity}>
              Update
            </Button>
          </>
        )}
      </td>
      <td>
        {currency} {subTotal}
      </td>
      <td>
        <TextField
          multiline
          value={itemremark}
          size="small"
          style={{ width: "120px" }}
          onChange={onChangeItemRemark}
          inputProps={{ maxLength: 50 }}
        ></TextField>
        &nbsp;
        <Button onClick={onItemRemarkUpdate} disabled={itemremark === x.itemRemark}>
          Update
        </Button>
      </td>
    </tr>
  );
}

export default CartItemView;
