import React, { useState, ReactElement, useEffect, useCallback } from "react";
import { Category, Item } from "system/types";
import { useLoadingDispatch } from "system/LoadingContext";
import { CategoryApi, ErrorHandler, ItemApi } from "system/ApiService";
import { Grid, MenuItem } from "@material-ui/core";
import { ActionButton } from "components/Button";
import SimpleSelect from "components/custom/SimpleSelect";
import { ManageProductList } from "./ManageProductList";

function ManageProduct(): ReactElement {
  const [list, setList] = useState<Category[]>([]);
  const [data, setData] = useState<Item[]>([]);
  const loadingDispatch = useLoadingDispatch();
  const [search, setSearch] = React.useState(0);

  const onLoad = useCallback(() => {
    loadingDispatch({ type: "LOADING" });
    CategoryApi.GetAllCategoriesERP("PRODUCT")
      .then((x) => {
        setList(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get category by erp." + msg);
      })
      .finally(() => loadingDispatch({ type: "COMPLETE" }));
  }, [loadingDispatch]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSearch(event.target.value as number);
  };

  const onClick = () => {
    ItemApi.GetItemList(search, "PRODUCT")
      .then((x) => {
        setData(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get product list." + msg);
      });
  };

  return (
    <>
      <Grid container justify="space-between">
        <Grid item spacing={1}>
          <SimpleSelect value={search} onChange={handleChange}>
            {list.map((x, idx) => (
              <MenuItem value={x.id}>{x.name}</MenuItem>
            ))}
          </SimpleSelect>
          <ActionButton style={{ marginTop: "12px" }} onClick={onClick}>
            Search
          </ActionButton>
        </Grid>
      </Grid>
      <br />
      <ManageProductList data={data} onClick={onClick}></ManageProductList>
    </>
  );
}

export default ManageProduct;
