import { Grid, Paper, Typography } from "@material-ui/core";
import { SaveButton } from "components/Button";
import PasswordInput from "components/Common/PasswordInput";
import React, { ReactElement, useState } from "react";
import { ErrorHandler, UserApi } from "system/ApiService";
import { isValidPassword } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";

interface State {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function ChangePassword(): ReactElement {
  const user = useUserState();
  const loadingDispatch = useLoadingDispatch();

  const [state, setState] = useState<State>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState<State>({
    currentPassword: "", //not-used
    newPassword: "",
    confirmPassword: "",
  });

  const onChangeText = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === "confirmPassword") {
      if (value === state.newPassword) {
        setError((prev) => ({ ...prev, confirmPassword: "" }));
      } else {
        setError((prev) => ({
          ...prev,
          confirmPassword: "Password are not matching",
        }));
      }
    } else if (name === "newPassword") {
      //8~256 자 이내 and 소문자,특수문자,숫자
      if (isValidPassword(value)) {
        setError((prev) => ({ ...prev, newPassword: "" }));
      } else {
        setError((prev) => ({ ...prev, newPassword: "Invalid Password" }));
      }
    }
  };

  const onChangeUserPassword = () => {
    loadingDispatch({ type: "LOADING" });
    UserApi.ChangePassword(user.id, state.currentPassword, state.newPassword, state.confirmPassword)
      .then(() => {
        alert("success to change password");
        setState({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        loadingDispatch({ type: "COMPLETE" });
      });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Paper style={{ maxWidth: "550px", padding: "20px" }} variant="outlined">
        <Typography style={{ paddingBottom: "15px" }}>
          Must have at least three different characters (letters, numbers, symbols (!,@@,#,$,%,^,&,*,-))
          <br />
          Do not use sequential characters(3333333, 12345678, abcdefg) or common words.
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordInput
              name="currentPassword"
              label="Current Password"
              value={state.currentPassword}
              variant="outlined"
              onChange={onChangeText}
              fullWidth={true}
            ></PasswordInput>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              name="newPassword"
              label="New Password"
              value={state.newPassword}
              variant="outlined"
              onChange={onChangeText}
              fullWidth={true}
              error={error.newPassword !== ""}
              helperText={error.newPassword}
              onBlur={onBlurInput}
            ></PasswordInput>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              value={state.confirmPassword}
              variant="outlined"
              onChange={onChangeText}
              fullWidth={true}
              error={error.confirmPassword !== ""}
              helperText={error.confirmPassword}
              onBlur={onBlurInput}
            ></PasswordInput>
          </Grid>
          <Grid item xs={12}>
            <div className="text-center">
              <SaveButton onClick={onChangeUserPassword}>Change</SaveButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default ChangePassword;
