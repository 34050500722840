import React, { useState, useEffect, ReactElement } from "react";
import MaterialDetailPage from "page/Material/MaterialDetailPage";
import MaterialListPage from "page/Material/MaterialListPage";
import { Switch, Route } from "react-router";
import { MyRoutes } from "routes/Routes";
import { CategoryApi, ErrorHandler } from "system/ApiService";
import { Category } from "system/types";
import { useLocation } from "react-router-dom";
import { useLoadingDispatch } from "system/LoadingContext";
import ListLayer from "components/ListLayer";

function MaterialPage(): ReactElement {
  const location = useLocation<any>();
  const [list, setList] = useState<Category[]>([]);
  const loadingDispatch = useLoadingDispatch();

  useEffect(() => {
    loadingDispatch({ type: "LOADING" });
    CategoryApi.GetAllCategoriesERP("MATERIAL")
      .then((x) => {
        setList(x);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get category by erp." + msg);
      })
      .finally(() => loadingDispatch({ type: "COMPLETE" }));
  }, [loadingDispatch]);

  return (
    <ListLayer
      kind="MATERIAL"
      list={list.filter((x) => x.isShow)}
      route={MyRoutes.shopMaterial}
      makePathName={(data: Category) => {
        return {
          ...location,
          pathname: `${MyRoutes.shopMaterial}/${data.id}`,
          search: "?page=1",
          state: { row: 12, page: 1 },
        };
      }}
    >
      <Switch>
        <Route path={MyRoutes.shopMaterial} exact></Route>
        <Route path={`${MyRoutes.shopMaterial}/view/:materialCode`}>
          <MaterialDetailPage></MaterialDetailPage>
        </Route>
        <Route path={`${MyRoutes.shopMaterial}/:categoryId?`} exact>
          <MaterialListPage></MaterialListPage>
        </Route>
      </Switch>
    </ListLayer>
  );
}

export default MaterialPage;
