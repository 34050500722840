import { Button, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CSSummaryCard from "components/Common/CSSummaryCard";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { DownloadApi } from "system/ApiService";
import { IsAdminUser } from "system/Helper";
import { useUserState } from "system/UserContext";
import { Category, DownloadItem, GetInitSearchResult, LocationDataState, SearchResult } from "system/types";
interface Params {
  categoryId: string;
  kind: string;
}

interface DownloadListPageParams {
  route: MyRoutes;
  list: Category[];
}
interface LocationState<T> extends LocationDataState<T> {
  isParent: boolean;
  depth: number;
}

function DownloadListPage({ route, list }: DownloadListPageParams): ReactElement {
  const classes = MyStyles();
  let { kind, categoryId } = useParams<Params>();
  const id = isNaN(parseInt(categoryId)) ? 0 : parseInt(categoryId);
  const user = useUserState();
  const isAdmin = IsAdminUser(user.role);
  const [lang, setLang] = useState<string>("En");
  const history = useHistory();
  const location = useLocation<LocationState<{}>>();
  const [data, setData] = React.useState<SearchResult<DownloadItem>>(GetInitSearchResult<DownloadItem>([]));
  const [page, setPage] = useState<number>(data.page);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  };
  useEffect(() => {
    DownloadApi.GetDownloadListByCategoryId(id, 10, page, lang)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        console.log("Error! Cannot get download category.");
      });
  }, [id, lang, page]);

  const onEdit = () => {
    history.push({
      pathname: `${route}/${kind}/edit/${categoryId}`,
    });
  };

  const onChange = (lang: string) => {
    setLang(lang);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          variant={lang === "Kr" ? "outlined" : "contained"}
          color="primary"
          onClick={() => onChange("En")}
          style={{ marginRight: "8px" }}
        >
          English
        </Button>
        <Button
          variant={lang === "En" ? "outlined" : "contained"}
          color="primary"
          onClick={() => onChange("Kr")}
          style={{ marginRight: "8px" }}
        >
          Korean
        </Button>
      </Grid>
      <Grid item xs={6} className="text-right">
        {isAdmin && (
          <Button onClick={onEdit} variant="contained" color="secondary">
            Write
          </Button>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.csHeader}>
        <Grid item xs={8}>
          <Typography variant="h6" align="center">
            Title
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Date
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Views
          </Typography>
        </Grid>
      </Grid>
      {data.list.length === 0 && (
        <Grid item xs={12} className="text-center mt-3">
          <Typography variant="subtitle1">No Posts found</Typography>
        </Grid>
      )}
      {data.list.map((x, index) => (
        <CSSummaryCard key={index} item={x} route={route} kind={kind}></CSSummaryCard>
      ))}
      <Grid item xs={12} className="d-flex justify-content-center">
        <Pagination
          color="primary"
          count={data.totalPage}
          defaultValue={1}
          page={page}
          showFirstButton
          showLastButton
          onChange={onPageChange}
        ></Pagination>
      </Grid>
    </Grid>
  );
}

export default DownloadListPage;
