import React, { useState } from "react";
import MaterialTable from "material-table";
import { TABLEICONS } from "system/Constants";
import { INIT_ITEM_INFO, Item, ItemDetail, ObjType } from "system/types";
import ImageMagnifier from "components/ImageMagnifier";
import { ActionButton } from "components/Button";
import { ErrorHandler, ItemApi } from "system/ApiService";
import InsertProductModal from "./InsertProductModal";

interface ManageProductListProps {
  data: Item[];
  onClick: () => void;
}

export function ManageProductList({ data, onClick }: ManageProductListProps) {
  const [state, setState] = useState<ItemDetail>(INIT_ITEM_INFO);
  const [itemModal, setItemModal] = useState(false);
  const closeItemModal = () => setItemModal(false);
  const openItemModal = (productNumber: any) => {
    ItemApi.GetItemByErpcode(productNumber, "PRODUCT")
      .then((res) => {
        setState(res);
        setItemModal(true);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get product detail." + msg);
        setState(INIT_ITEM_INFO);
      });
  };

  const onUpdate = (data: ItemDetail, file: File | undefined) => {
    //데이터 저장
    const formData = new FormData();
    if (file && data.image) {
      formData.append("imageFile", file);
    }

    const obj: ObjType = { ...data };
    for (const key of Object.keys(data)) {
      const value = obj[key];
      if (value === undefined) continue;
      else if (Array.isArray(value)) {
        const ary = value.filter((x) => x);
        for (const i in ary) {
          formData.append(`${key}.Index`, i);
          formData.append(`${key}[${i}]`, ary[i]);
        }
      } else {
        formData.append(key, value);
      }
    }

    ItemApi.Upsert(data.productNumber, formData)
      .then((res) => {
        closeItemModal();
        alert("Success to Change");
        onClick();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot update item." + msg);
      });
  };

  return (
    <div style={{ minWidth: "1000px" }}>
      <MaterialTable
        icons={TABLEICONS}
        title="Products Manage"
        columns={[
          { title: "ErpCode", field: "productNumber", cellStyle: { width: "15%" } },
          { title: "Name", field: "productName", cellStyle: { width: "25%" } },
          {
            title: "Img",
            field: "image",
            lookup: { no: "No Img" },
            cellStyle: { width: "15%" },
            render: (rowData) => {
              return (
                <ImageMagnifier
                  height={80}
                  src={`/images/material/items/${
                    rowData.image !== "no" ? rowData.image : rowData.productNumber + ".jpg"
                  }`}
                  alt={rowData.productName}
                  fallbackSrc="/images/NO_Image.png"
                ></ImageMagnifier>
              );
            },
          },
          {
            title: "Stopped",
            field: "stopped",
            lookup: { false: "opened", true: "stopped" },
            cellStyle: { width: "15%" },
            render: (rowData) => {
              return <>{rowData.stopped === false ? <div>opened</div> : <div>stopped</div>}</>;
            },
          },
          {
            title: "isShow",
            field: "isShow",
            lookup: { false: "hide", true: "show" },
            cellStyle: { width: "15%" },
            render: (rowData) => {
              return <>{rowData.isShow === false ? <div>hide</div> : <div>show</div>}</>;
            },
          },
          {
            title: "Detail",
            field: "",
            cellStyle: { width: "15%" },
            render: (rowData) => {
              return (
                <div>
                  <ActionButton onClick={() => openItemModal(rowData.productNumber)}>Edit</ActionButton>
                </div>
              );
            },
          },
        ]}
        data={data || []}
        options={{
          filtering: true,
          pageSize: 10,
        }}
      />
      <InsertProductModal
        maxWidth="sm"
        fullWidth
        open={itemModal}
        onClose={closeItemModal}
        data={state}
        onUpdate={onUpdate}
      ></InsertProductModal>
    </div>
  );
}
