import HtmlContent from "components/RichTextBox/HtmlContent";

interface Props {
  data: string;
}

const MaterialCautionView = ({ data }: Props) => {
  return (
    <div>
      <div className="py-2 px-3">
        <HtmlContent style={{ padding: "15px", backgroundColor: "white" }} data={data}></HtmlContent>
      </div>
    </div>
  );
};

export default MaterialCautionView;
