import LoginPage from "page/Login/LoginPage";
import { Typography } from "@material-ui/core";
import React, { ReactElement, useCallback } from "react";
import "style/login.css";
import { useHistory, useLocation } from "react-router";
import ResetPassword from "page/Login/ResetPassword";

export function MainPage(): ReactElement {
  const location = useLocation();
  const { hash } = location;
  const history = useHistory();

  const onChange = useCallback(
    (type: string) => {
      history.push({
        ...location,
        hash: "#" + type,
      });
    },
    [history, location]
  );

  const GetPage = () => {
    if (hash === "#forgot-email") {
      return (
        <div>
          <Typography variant="h5">Contact us</Typography>
          <div className="text-right">
            <Typography variant="body2" role="button" onClick={() => onChange("signin")}>
              Back to sign-in
            </Typography>
          </div>
          <div style={{ paddingTop: "10px" }}></div>
          <Typography variant="body1">Please contact us</Typography>
          <Typography variant="body1">
            <a href="mailto:hqcs@inbody.com">hqcs@inbody.com</a>
          </Typography>
        </div>
      );
    } else if (hash === "#password") {
      return <ResetPassword onChange={onChange}></ResetPassword>;
    } else {
      //signin
      return (
        <div className="login-inner-div">
          <LoginPage onChange={onChange}></LoginPage>
        </div>
      );
    }
  };

  const getPadding = () => {
    if (hash === "#forgot-email") {
      return "80px 30px";
    } else if (hash === "#password") {
      return "80px 30px";
    } else {
      return undefined;
    }
  };

  return (
    <div className="wrapper-login-div">
      <div className="login-div" style={{ padding: getPadding() }}>
        <img src="/images/main_logo.png" width="200px" alt="InBody"></img>
        <hr />
        {GetPage()}
      </div>
    </div>
  );
}

export default MainPage;
