import { Container } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import React, { ReactElement, ReactNode } from "react";

interface PageProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

function Page({ children, className, style }: PageProps): ReactElement {
  return (
    <Container className={className} style={style}>
      <>{children}</>
    </Container>
  );
}

export default Page;
