import React, { ReactElement } from "react";
import ProductListPage from "page/Product/ProductListPage";
import { Switch, Route } from "react-router";
import { MyRoutes } from "routes/Routes";

function ProductPage(): ReactElement {
  return (
    <Switch>
      <Route path={MyRoutes.shopProduct} exact>
        <ProductListPage></ProductListPage>
      </Route>
    </Switch>
  );
}

export default ProductPage;
