import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { GreyButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import Editor from "components/RichTextBox/Editor";
import Select2Category from "components/custom/Select2Category";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { CSEtcApi, ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useToastDispatch } from "system/ToastContext";
import { CSEtc, INIT_CSETC } from "system/types";

function CSEtcEditPage(): ReactElement {
  const history = useHistory();
  const ref = React.useRef<Editor>(null);
  const ToastDispatch = useToastDispatch();
  const { id, categoryId } = useParams<{ id: string; categoryId: string }>();
  const classes = MyStyles();
  const Loading = useLoadingDispatch();

  const [file, setFile] = useState<File>();
  const [pdf, setPdf] = useState<File>();
  const [pdfDeleteOpen, setPdfDeleteOpen] = useState<boolean>(false);
  const [fileDeleteOpen, setFileDeleteOpen] = useState<boolean>(false);

  const [csEtc, setCsEtc] = useState<CSEtc>(INIT_CSETC);
  const csetcId = isNaN(parseInt(id)) ? 0 : parseInt(id);
  const isEdit = csetcId !== 0 ? true : false;

  useEffect(() => {
    if (isEdit) {
      Loading({ type: "LOADING" });
      CSEtcApi.GetCSEtc(csetcId)
        .then((res) => {
          setCsEtc(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  }, [Loading, csetcId, isEdit]);

  function confirmError(title: string, content: string | null | undefined) {
    let errorMsg = undefined;
    if (csEtc.categoryId === 0) {
      errorMsg = "Please select full Category";
    } else if (!title) {
      errorMsg = "There is no title";
    } else if (!content) {
      errorMsg = "There is no contents to save";
    }
    return errorMsg;
  }

  const onChange = (event: any) => {
    setCsEtc({
      ...csEtc,
      [event.target.name]: event.target.value,
    });
  };

  function setFormData() {
    const formData = new FormData();

    if (file !== undefined && csEtc.file !== undefined) {
      formData.append("formFile", file);
    }
    if (pdf !== undefined && csEtc.pdf !== undefined) {
      formData.append("formPDF", pdf);
    }
    formData.append("title", csEtc.title);
    formData.append("id", csEtc.id + "");
    formData.append("kind", "csetc");
    formData.append("categoryId", csEtc.categoryId + "");
    formData.append("file", csEtc.file || "");
    formData.append("fileName", csEtc.fileName || "");
    formData.append("pdf", csEtc.pdf || "");
    formData.append("pdfName", csEtc.pdfName || "");
    return formData;
  }

  const onSave = () => {
    let errorMsg: string | undefined = undefined;
    const title = csEtc.title;
    const value: string | null | undefined = ref.current?.getContent();

    if (ref.current) {
      errorMsg = confirmError(title, value);
    } else {
      errorMsg = "Somthing wrong. Please Reload this page.";
    }

    if (errorMsg) {
      ToastDispatch({ type: "error", message: errorMsg });
    } else if (value) {
      // 파일 업로드
      const formData = setFormData();
      formData.append("content", value);

      Loading({ type: "LOADING" });
      CSEtcApi.UpsertCSEtc(formData)
        .then((res) => {
          history.replace({
            pathname: `${MyRoutes.csEtc}/${res.id}`,
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const saveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setCsEtc({
        ...csEtc,
        file: event.target.files[0].name,
      });
    }
  };

  const savePDF = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setPdf(event.target.files[0]);
      setCsEtc({
        ...csEtc,
        pdf: event.target.files[0].name,
      });
    }
  };

  const onPdfDelete = () => {
    setPdfDeleteOpen(true);
  };

  const onFileDelete = () => {
    setFileDeleteOpen(true);
  };

  const closeDialog = () => {
    setPdfDeleteOpen(false);
    setFileDeleteOpen(false);
  };

  const confirmDialogPDF = () => {
    setCsEtc({ ...csEtc, pdf: "", pdfName: "", pdfSize: 0 });
  };

  const confirmDialogFile = () => {
    setCsEtc({ ...csEtc, file: "", fileName: "", fileSize: 0 });
  };

  const onView = () => {
    if (isEdit) {
      history.push({
        pathname: `${MyRoutes.csEtc}/${csEtc.id}`,
      });
    } else {
      history.push({
        pathname: `${MyRoutes.csEtc}/list/${categoryId}`,
      });
    }
  };

  const onChangeCategory = (id: number, index?: number) => {
    if (index === 2) {
      setCsEtc({
        ...csEtc,
        categoryId: id,
      });
    } else {
      setCsEtc({
        ...csEtc,
        categoryId: 0,
      });
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6">Category</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10} style={{ paddingBottom: "20px" }}>
        <Select2Category onChange={onChangeCategory} kind={"csetc"} value={csEtc.categoryId}></Select2Category>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6" className="text-left">
          Title
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <TextField value={csEtc.title} name="title" onChange={onChange} fullWidth></TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.marginTop10}>
        <Editor content={csEtc.content} ref={ref}></Editor>
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          PDF
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {csEtc.pdf && csEtc.pdfName ? (
          <>
            <FileSize name={csEtc.pdfName} size={csEtc.pdfSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onPdfDelete} size="small">
              PDF DELETE
            </GreyButton>
            <ConfirmDialog
              state={pdfDeleteOpen}
              title={"DELETE PDF FILE"}
              message={"Click the save button to save the changes."}
              onClose={closeDialog}
              onClickConfirm={confirmDialogPDF}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={savePDF} className={classes.fileInput} accept=".pdf"></input>
        )}
      </Grid>

      {/* 파일 첨부 */}
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          File
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {csEtc.file && csEtc.fileName ? (
          <>
            <FileSize name={csEtc.fileName} size={csEtc.fileSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onFileDelete} size="small">
              FILE DELETE
            </GreyButton>
            <ConfirmDialog
              state={fileDeleteOpen}
              title={"DELETE FILE"}
              message={"Click the save button to save the changes."}
              onClose={closeDialog}
              onClickConfirm={confirmDialogFile}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={saveFile} className={classes.fileInput}></input>
        )}
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onView}>
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onSave} variant="contained" color="secondary">
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CSEtcEditPage;
