import React, { useRef, ReactElement, useState, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useToastDispatch } from "system/ToastContext";
import { ErrorHandler, FileApi, NoticeApi } from "system/ApiService";
import { useParams } from "react-router";
import { INIT_NOTICE, Notice, ObjType } from "system/types";
import { Editor } from "components/RichTextBox";
import { useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import fileDownload from "js-file-download";

function NoticeEdit(): ReactElement {
  const history = useHistory();
  const ref = useRef<Editor>(null);
  const ToastDispatch = useToastDispatch();
  const { id } = useParams<{ id: string }>();

  const [notice, setNotice] = useState<Notice>({ ...INIT_NOTICE });
  const noticeId = isNaN(parseInt(id)) ? 0 : parseInt(id);
  const [file, setFile] = useState<File>();
  const [chk, setChk] = useState<boolean>(false);

  useEffect(() => {
    if (noticeId !== 0) {
      NoticeApi.GetNotice(noticeId)
        .then((res) => {
          setNotice(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot get notice." + msg);
        });
    }
  }, [noticeId]);

  function confirmError(title: string, content: string | null | undefined) {
    let errorMsg = undefined;

    if (!title || title === "") {
      errorMsg = "There is no title";
    }
    if (!content || content === null) {
      errorMsg = "There is no data to save.";
    }

    return errorMsg;
  }

  function onSave() {
    let errorMsg: string | undefined = undefined;
    const title = notice.title;
    const value: string | null | undefined = ref.current?.getContent();

    if (ref.current) {
      errorMsg = confirmError(title, value);
    } else {
      errorMsg = "Something wrong. Please Reload this page.";
    }

    if (errorMsg) {
      ToastDispatch({ type: "error", message: errorMsg });
    } else if (value) {
      const form = new FormData();
      const obj: ObjType = { ...notice, content: value };
      const keys = Object.keys(obj);
      for (let key of keys) {
        form.append(key, obj[key]);
      }

      let _chk = chk;
      if (file) form.append("file", file);
      //파일이 변경되었으면서, 파일삭제를 누르지 않은 경우
      if (notice.fileName && file && !_chk) {
        _chk = true;
      }

      if (_chk) {
        form.append("chk", _chk + "");
      }

      NoticeApi.UpsertNotice(form)
        .then((res) => {
          history.replace({
            pathname: `${MyRoutes.notice}/view/${res.id}`,
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot update notice." + msg);
        });
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      if (notice.fileName) setChk(true);
    } else if (event.target.name === "chk-del") {
      setChk(event.target.checked);
    } else {
      setNotice({
        ...notice,
        title: event.currentTarget.value,
      });
    }
  }

  function onClickDownloadFile(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (notice.fileName) {
      FileApi.DownloadFile(notice.fileName, "application/octet-stream")
        .then((res) => fileDownload(res, notice.fileName!))
        .catch((error) => {
          alert("파일이 없습니다.");
        });
    }
  }

  return (
    <>
      <Grid container spacing={0} className="mb-2">
        <Grid item xs={12} sm={12} md={1}>
          <Typography variant="h5" className="text-center">
            Title:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={11}>
          <TextField value={notice.title} onChange={onChange} fullWidth className="large"></TextField>
        </Grid>
      </Grid>
      <Editor content={notice.content} ref={ref}></Editor>
      <br />
      <input type="file" onChange={onChange}></input>
      <br />
      {notice.fileName && (
        <>
          <label>
            <input type="checkbox" name="chk-del" checked={chk} onChange={onChange}></input> Delete File
          </label>
          &nbsp;
          <button onClick={onClickDownloadFile} aria-label={`download-file-${notice.fileName}`}>
            {notice.fileName}
          </button>
          <Typography variant="body2">* Only one file can be uploaded.</Typography>
        </>
      )}
      <br />
      <div className="text-end">
        <Button onClick={onSave} variant="contained" color="secondary">
          SAVE
        </Button>
      </div>
    </>
  );
}

export default NoticeEdit;
