import { Box, Grid, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { getDateUsingNumber } from "system/Helper";
import { CSVideoItem } from "system/types";

interface VideoCardProps {
  item: CSVideoItem;
  mobile?: boolean;
}

function VideoCard({ item, mobile }: VideoCardProps): ReactElement {
  const classes = MyStyles();
  const history = useHistory();

  return (
    <Box onClick={() => history.push(`${MyRoutes.csVideo}/${item.id}`)} className={classes.contentTitle}>
      <Grid
        container
        spacing={2}
        style={{
          width: "400px",
          display: "inline-block",
          margin: "5px",
          border: "1px solid lightgrey",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
            overflow: "hidden",
            paddingTop: "56.25%",
            position: "relative",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={item.thumbnail}
            alt={`Thumbnail of ${item.title}`}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const target = e.target as HTMLImageElement;
              target.src = "/images/NO_Image.png";
            }}
          />
        </div>
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary" className={classes.contentTitle}>
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">{getDateUsingNumber(item.updated)}</Typography>
          <Typography variant="subtitle2" align="right">
            <VisibilityIcon /> {item.views}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VideoCard;
