import React, { ReactElement, useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { SaveButton } from "components/Button";
import { Warranty } from "system/types";
import { ErrorHandler, WarrantyApi } from "system/ApiService";

export interface WarrantyCheck extends Warranty {
  checked?: boolean;
}

function WarrantyPage(): ReactElement {
  const [data, setData] = useState<WarrantyCheck[]>([]);

  const onLoad = React.useCallback(() => {
    WarrantyApi.GetWarrantyList()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("Cannot get warranty list." + msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const el = event.currentTarget as HTMLInputElement;
    const id = el.dataset.serial;
    if (id) {
      setData(
        data.map((x) => {
          return x.serialNum === id ? { ...x, checked: checked } : x;
        })
      );
    }
  };

  const addCart = () => {
    WarrantyApi.AddWarrantyCart(data.filter((x) => x.checked))
      .then((res) => {
        alert("Successfully added to cart!");
        onLoad();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("Error" + msg);
      });
  };

  return (
    <>
      <table className={"warrantyTable"}>
        <thead className="text-center">
          <tr>
            <th></th>
            <th>Reported Date</th>
            <th>Case ID</th>
            <th>Model</th>
            <th>SN</th>
            <th>Customer</th>
            <th>Item Code</th>
            <th>Item</th>
            <th>Quantity</th>
            <th>Claim status</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {data.length === 0 ? (
            <tr>
              <td colSpan={12}>There is No data.</td>
            </tr>
          ) : (
            data.map((x, index) => (
              <tr key={index}>
                <td>
                  <Checkbox
                    inputProps={
                      {
                        "data-serial": x.serialNum,
                      } as any
                    }
                    checked={x.checked}
                    onChange={onCheckBoxClick}
                    disabled={x.salesId !== "" ? true : x.status}
                  ></Checkbox>
                </td>
                <td>{x.reportedDate.split("T")[0]}</td>
                <td>{x.caseId}</td>
                <td>{x.model}</td>
                <td>{x.serialNum}</td>
                <td>{x.customer.split("(")[1].split(")")}</td>
                <td>{x.itemId}</td>
                <td>{x.itemName}</td>
                <td>{x.qty}</td>
                <td>{x.salesId !== "" ? "Used" : x.status ? "Used" : "Available"}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="text-center mt-2">
        <SaveButton onClick={addCart} disabled={data.filter((x) => x.status === false).length === 0}>
          Warranty Claim
        </SaveButton>
      </div>
    </>
  );
}

export default WarrantyPage;
