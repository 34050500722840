import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { GreyButton } from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import FileSize from "components/FileSize";
import Editor from "components/RichTextBox/Editor";
import Select2Category from "components/custom/Select2Category";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { LetterNoticeApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useToastDispatch } from "system/ToastContext";
import { INIT_LETTERNOTICE, LetterNotice } from "system/types";

function LetterEditPage(): ReactElement {
  const history = useHistory();
  const ref = React.useRef<Editor>(null);
  const ToastDispatch = useToastDispatch();
  const { id, categoryId } = useParams<{ id: string; categoryId: string }>();
  const classes = MyStyles();
  const Loading = useLoadingDispatch();

  const [file, setFile] = useState<File>();
  const [pdf, setPdf] = useState<File>();
  const [pdfDeleteOpen, setPdfDeleteOpen] = useState<boolean>(false);
  const [fileDeleteOpen, setFileDeleteOpen] = useState<boolean>(false);

  const [letterNotice, setLetterNotice] = useState<LetterNotice>(INIT_LETTERNOTICE);
  const letterNoticeId = isNaN(parseInt(id)) ? 0 : parseInt(id);
  const isEdit = letterNoticeId !== 0 ? true : false;

  useEffect(() => {
    if (isEdit) {
      Loading({ type: "LOADING" });
      LetterNoticeApi.GetLetterNotice(letterNoticeId)
        .then((res) => {
          setLetterNotice(res);
        })
        .catch(() => {
          console.log("Error! Cannot get letternotice.");
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  }, [letterNoticeId, isEdit, Loading]);

  function confirmError(title: string, content: string | null | undefined) {
    let errorMsg = undefined;
    if (letterNotice.categoryId === 0) {
      errorMsg = "Please select full Category";
    } else if (!title) {
      errorMsg = "There is no title";
    } else if (!content) {
      errorMsg = "There is no contents to save";
    }
    return errorMsg;
  }

  const onChange = (event: any) => {
    setLetterNotice({
      ...letterNotice,
      [event.target.name]: event.target.value,
    });
  };

  function setFormData() {
    const formData = new FormData();

    if (file !== undefined && letterNotice.file !== undefined) {
      formData.append("formFile", file);
    }
    // 뷰용 PDF 업로드
    if (pdf !== undefined && letterNotice.pdf !== undefined) {
      formData.append("formPDF", pdf);
    }
    formData.append("title", letterNotice.title);
    formData.append("id", letterNotice.id + "");
    formData.append("kind", "letternotice");
    formData.append("categoryId", letterNotice.categoryId + "");
    formData.append("file", letterNotice.file || "");
    formData.append("fileName", letterNotice.fileName || "");
    formData.append("pdf", letterNotice.pdf || "");
    formData.append("pdfName", letterNotice.pdfName || "");
    formData.append("lang", letterNotice.lang);
    return formData;
  }

  const onSave = () => {
    let errorMsg: string | undefined = undefined;
    const title = letterNotice.title;
    const value: string | null | undefined = ref.current?.getContent();

    if (ref.current) {
      errorMsg = confirmError(title, value);
    } else {
      errorMsg = "Somthing wrong. Please Reload this page.";
    }

    if (errorMsg) {
      ToastDispatch({ type: "error", message: errorMsg });
    } else if (value) {
      // 파일 업로드
      const formData = setFormData();
      formData.append("content", value);

      Loading({ type: "LOADING" });
      LetterNoticeApi.UpsertLetterNotice(formData)
        .then((res) => {
          history.replace({
            pathname: `${MyRoutes.letterNotice}/${res.id}`,
          });
        })
        .catch(() => {
          console.log("Error! Cannot save letternotice.");
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const saveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setLetterNotice({
        ...letterNotice,
        file: event.target.files[0].name,
      });
    }
  };

  const savePDF = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setPdf(event.target.files[0]);
      setLetterNotice({
        ...letterNotice,
        pdf: event.target.files[0].name,
      });
    }
  };

  const onPdfDelete = () => {
    setPdfDeleteOpen(true);
  };

  const onFileDelete = () => {
    setFileDeleteOpen(true);
  };

  const closeDialog = () => {
    setPdfDeleteOpen(false);
    setFileDeleteOpen(false);
  };

  const confirmDialogPDF = () => {
    setLetterNotice({ ...letterNotice, pdf: "", pdfName: "", pdfSize: 0 });
  };

  const confirmDialogFile = () => {
    setLetterNotice({ ...letterNotice, file: "", fileName: "", fileSize: 0 });
  };

  const onView = () => {
    if (isEdit) {
      history.push({
        pathname: `${MyRoutes.letterNotice}/${letterNotice.id}`,
      });
    } else {
      history.push({
        pathname: `${MyRoutes.letterNotice}/list/${categoryId}`,
      });
    }
  };

  const onChangeCategory = (id: number, index?: number) => {
    if (index === 2) {
      setLetterNotice({
        ...letterNotice,
        categoryId: id,
      });
    } else {
      setLetterNotice({
        ...letterNotice,
        categoryId: 0,
      });
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6">Category</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10} style={{ paddingBottom: "20px" }}>
        <Select2Category
          onChange={onChangeCategory}
          kind={"letternotice"}
          value={letterNotice.categoryId}
        ></Select2Category>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6" className="text-left">
          Title
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <TextField value={letterNotice.title} name="title" onChange={onChange} fullWidth></TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography variant="h6" className="text-left">
          Language
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={10} style={{ paddingBottom: "20px" }}>
        <FormControl style={{ minWidth: 130 }}>
          <InputLabel>{"Language"}</InputLabel>
          <Select name="lang" value={letterNotice.lang} onChange={onChange}>
            <MenuItem value="Kr">Korean</MenuItem>
            <MenuItem value="En">English</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.marginTop10}>
        <Editor content={letterNotice.content} ref={ref}></Editor>
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          PDF
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {letterNotice.pdf && letterNotice.pdfName ? (
          <>
            <FileSize name={letterNotice.pdfName} size={letterNotice.pdfSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onPdfDelete} size="small">
              PDF DELETE
            </GreyButton>
            <ConfirmDialog
              state={pdfDeleteOpen}
              title={"DELETE PDF FILE"}
              message={"Are you sure to delete PDF file?"}
              onClose={closeDialog}
              onClickConfirm={confirmDialogPDF}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={savePDF} className={classes.fileInput} accept=".pdf"></input>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.marginTop10}>
        <Typography variant="h6" className="text-left">
          File
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {letterNotice.file && letterNotice.fileName ? (
          <>
            <FileSize name={letterNotice.fileName} size={letterNotice.fileSize}></FileSize>
            &nbsp;
            <GreyButton variant="outlined" onClick={onFileDelete} size="small">
              FILE DELETE
            </GreyButton>
            <ConfirmDialog
              state={fileDeleteOpen}
              title={"DELETE FILE"}
              message={"Are you sure to delete file?"}
              onClose={closeDialog}
              onClickConfirm={confirmDialogFile}
            ></ConfirmDialog>
          </>
        ) : (
          <input type="file" onChange={saveFile} className={classes.fileInput}></input>
        )}
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onView}>
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onSave} variant="contained" color="secondary">
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LetterEditPage;
