import React from "react";
import { TreeItem, TreeItemProps, TreeView } from "@material-ui/lab";
import { Category } from "system/types";
import { MinusSquare, PlusSquare, CloseSquare, GreenCheckIcon, RedNotInterestedIcon } from "components/custom/Icon";
import { fade, withStyles, Theme, createStyles } from "@material-ui/core/styles";

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      "& .close": {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: TreeItemProps) => <TreeItem {...props} />);

interface CategoryTreeViewProps {
  data: Category[];
  onClickCategoryItem?: (selected: Category, parnetId?: string) => void;
}

export default function CategoryTreeView(props: CategoryTreeViewProps) {
  return (
    <TreeView defaultCollapseIcon={<MinusSquare />} defaultExpandIcon={<PlusSquare />} defaultEndIcon={<CloseSquare />}>
      {props.data.map((x) => (
        <CategoryTreeItem parentId={x.id} onClick={props.onClickCategoryItem} key={x.id} data={x}></CategoryTreeItem>
      ))}
    </TreeView>
  );
}

interface CategoryTreeItemProps {
  data: Category;
  parentId: string;
  onClick?: (selected: Category, parnetId?: string) => void;
}

const CategoryTreeItem = ({ data, parentId, onClick }: CategoryTreeItemProps) => {
  function onClickItem() {
    if (onClick) {
      onClick(data, parentId);
    }
  }
  const isshow = data.isShow ? <GreenCheckIcon /> : <RedNotInterestedIcon />;
  return (
    <StyledTreeItem
      nodeId={data.id}
      label={
        <>
          {data.name} {isshow}
        </>
      }
      onClick={onClick ? onClickItem : undefined}
    >
      {data.children &&
        data.children.length >= 1 &&
        data.children.map((x) => <CategoryTreeItem parentId={x.id} onClick={onClick} key={x.id} data={x} />)}
    </StyledTreeItem>
  );
};
